import { trackEvent } from '@phntms/next-gtm';
import { useQueryStates } from 'nuqs';

import { targetAllocationModalParsers } from '@endaoment-frontend/routes';
import { type UUID } from '@endaoment-frontend/types';

export const useTargetAllocationModal = () => {
  const [{ isTargetAllocationModalOpen, targetAllocationFundId }, setQueryState] =
    useQueryStates(targetAllocationModalParsers);

  const closeTargetAllocationModal = () => {
    setQueryState({ isTargetAllocationModalOpen: null, targetAllocationFundId: null });

    trackEvent({
      event: 'ta_close_modal',
    });
  };

  return {
    isTargetAllocationModalOpen,
    targetAllocationFundId: targetAllocationFundId ?? undefined,
    closeTargetAllocationModal,
  };
};

export const useOpenTargetAllocationModal = () => {
  const [, setQueryState] = useQueryStates(targetAllocationModalParsers);

  const openTargetAllocationModal = (fundId: UUID) => {
    setQueryState({ isTargetAllocationModalOpen: true, targetAllocationFundId: fundId });

    trackEvent({
      event: 'ta_open_modal',
    });
  };

  return openTargetAllocationModal;
};
