import { Box, Flex } from '@chakra-ui/react';
import type { MutationStatus } from '@tanstack/react-query';
import type { ValueContainerProps } from 'chakra-react-select';
import { AsyncCreatableSelect, components as selectComponents } from 'chakra-react-select';
import { Form, Formik, useField } from 'formik';
import type { StaticImageData } from 'next/image';
import Image from 'next/image';
import { match } from 'ts-pattern';

import { GetSupportedBrokers } from '@endaoment-frontend/api';
import { useIsMobile } from '@endaoment-frontend/hooks';
import type { BrokerageInfoFormData, DonationRecipient, SupportedBroker } from '@endaoment-frontend/types';
import { brokerageInfoFormSchema } from '@endaoment-frontend/types';
import { ErrorDisplay, FormInput, ProceedButton, validateWithZod } from '@endaoment-frontend/ui/forms';
import { SearchIcon } from '@endaoment-frontend/ui/icons';

import { RecommendButton } from '../common/RecommendButton';
import { useIsFundCollaborator } from '../useIsFundCollaborator';

import ameripriseIcon from './brokerage-icons/ameriprise.png';
import ameritradeIcon from './brokerage-icons/ameritrade.png';
import etradeIcon from './brokerage-icons/etrade.png';
import fidelityIcon from './brokerage-icons/fidelity.png';
import jpmorganIcon from './brokerage-icons/jpmorgan.png';
import lplIcon from './brokerage-icons/lpl.png';
import merrillIcon from './brokerage-icons/merrill.png';
import morganstanleyIcon from './brokerage-icons/morganstanley.png';
import schwabIcon from './brokerage-icons/schwab.png';
import tiaaIcon from './brokerage-icons/tiaa.png';
import troweIcon from './brokerage-icons/trowe.png';
import wellsfargoIcon from './brokerage-icons/wellsfargo.png';
import styles from './StockDonation.module.scss';

export const BrokerageInfo = ({
  onSubmit,
  onRecommend,
  initialValues,
  recipient,
  recommendStatus,
}: {
  onSubmit: (t: BrokerageInfoFormData) => void;
  onRecommend: (t: BrokerageInfoFormData) => void;
  initialValues: BrokerageInfoFormData;
  recipient: DonationRecipient;
  recommendStatus: MutationStatus;
}) => {
  const isFundCollaborator = useIsFundCollaborator(recipient.type === 'fund' ? recipient.id : undefined);
  const handleSubmission = (values: BrokerageInfoFormData) => {
    match(isFundCollaborator)
      .with(true, () => onRecommend(values))
      .with(false, () => onSubmit(values))
      .with(undefined, () => {
        console.error('Unable to process form submission, still determining if user is an collaborator.');
        return;
      })
      .exhaustive();
  };

  const { isMobile } = useIsMobile({ defaultState: true });

  return (
    <Formik
      initialValues={initialValues}
      validate={validateWithZod(brokerageInfoFormSchema)}
      onSubmit={handleSubmission}>
      {({ handleSubmit }) => (
        <Form className={styles['form']}>
          <div>
            <label htmlFor='brokerage' className={styles['label']}>
              Choose Brokerage
            </label>
            <BrokerageSearch name='brokerage' autoFocus={!isMobile} />
          </div>
          <FormInput
            name='brokerageAccountNumber'
            label='Brokerage Account Number'
            data-testid='brokerageAccountNumber'
          />
          <FormInput name='brokerageContactName' label='Brokerage Contact Name' data-testid='brokerageContactName' />
          <FormInput name='brokerageEmail' label='Brokerage Email' data-testid='brokerageEmail' />
          <FormInput name='brokeragePhone' label='Brokerage Phone' data-testid='brokeragePhone' />
          {isFundCollaborator ? (
            <RecommendButton onRecommend={handleSubmit} recommendStatus={recommendStatus} type='submit' />
          ) : (
            <ProceedButton type='submit' onClick={handleSubmit} />
          )}
        </Form>
      )}
    </Formik>
  );
};

const brokerageIconMap: { [key: string]: StaticImageData | string } = {
  ameriprise: ameripriseIcon,
  fidelity: fidelityIcon,
  schwab: schwabIcon,
  etrade: etradeIcon,
  jpmorgan: jpmorganIcon,
  lpl: lplIcon,
  merrill: merrillIcon,
  morganstanley: morganstanleyIcon,
  ameritrade: ameritradeIcon,
  trowe: troweIcon,
  tiaa: tiaaIcon,
  wellsfargo: wellsfargoIcon,
};

export const BrokerageIcon = ({ brokerageName }: { brokerageName: string }) => {
  if (!brokerageIconMap[brokerageName]) return <></>;
  return (
    <Image
      src={brokerageIconMap[brokerageName]}
      alt={brokerageName}
      width={24}
      height={24}
      className={styles['brokerage-icon']}
    />
  );
};

const BrokerageSearchValueContainer = ({ children, ...props }: ValueContainerProps<SupportedBroker>) => {
  const value = props.getValue();
  return (
    <>
      {value.length === 0 && <SearchIcon className={styles['search-icon']} />}
      <selectComponents.ValueContainer {...props}>{children}</selectComponents.ValueContainer>
    </>
  );
};
const BrokerageSearch = ({ name, autoFocus }: { name: string; autoFocus?: boolean }) => {
  const [{ value }, { error }, { setValue, setTouched }] = useField<
    (SupportedBroker & { isCustom?: boolean }) | undefined
  >(name);
  const loadOptions = async () => GetSupportedBrokers.fetchFromDefaultClient([]);

  return (
    <>
      <AsyncCreatableSelect
        value={value}
        onChange={v => {
          void setTouched(true);
          if (v) {
            void setValue(v, true);
          } else {
            void setValue(undefined, true);
          }
        }}
        onCreateOption={v => setValue({ label: v, name: 'other' }, true)}
        isSearchable
        defaultOptions
        backspaceRemovesValue
        blurInputOnSelect
        isMulti={false}
        isClearable
        loadOptions={loadOptions}
        filterOption={(option, search) => option.label.toLowerCase().includes(search.toLowerCase())}
        components={{
          ClearIndicator: () => null,
          DropdownIndicator: () => null,
          LoadingIndicator: () => null,
          IndicatorsContainer: () => null,
          ValueContainer: BrokerageSearchValueContainer,
        }}
        placeholder='Brokerage Search'
        classNames={{
          placeholder: () => styles['search-placeholder'],
          control: () => styles['search-control'],
          valueContainer: () => styles['search-value-container'],
          menuPortal: () => styles['search-menu-portal'],
        }}
        formatOptionLabel={option => (
          <Flex gap='0' alignItems='center' px='1rem' className={styles['broker-option']}>
            <Box className={styles['broker-info']}>
              <BrokerageIcon brokerageName={option.name} />
              {option.label}
            </Box>
            {brokerageIconMap[option.name] ? <span>Supported</span> : <span>Custom</span>}
          </Flex>
        )}
        selectedOptionColorScheme='green'
        menuPortalTarget={document.body}
        isOptionSelected={(option, value) => option.name === value[0]?.name}
        className={styles['brokerage-search']}
        autoFocus={autoFocus}
      />
      <ErrorDisplay error={error} />
      {value?.name === 'other' && (
        <span className={styles['warning']}>
          Using a custom brokerage might result in additional time required to process donation.
        </span>
      )}
    </>
  );
};
