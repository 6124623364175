import { useState } from 'react';
import { useAsync } from 'react-use';
import { match } from 'ts-pattern';
import { useChainId, useReadContract } from 'wagmi';

import { registryAbi } from '@endaoment-frontend/abis';
import { GetFund, GetOrg } from '@endaoment-frontend/api';
import { defaults } from '@endaoment-frontend/config';
import { getContractAddressForChain, getOrgDeploymentForChain } from '@endaoment-frontend/multichain';
import type { Address, DonationRecipient } from '@endaoment-frontend/types';
import { roundDown } from '@endaoment-frontend/utils';

type UseTransferFeeArgs = {
  sender?: Address | null;
  recipient: DonationRecipient;
  chainId: number;
  transferAmount: bigint;
  enabled?: boolean;
};

export const calculateFee = (feeBps: number, transferAmount: bigint): bigint => {
  const feePercent = feeBps / 10000;
  return BigInt(roundDown(feePercent * Number(transferAmount), 0));
};

const getReceiverAddressForTransferFee = ({
  recipient,
  chainId,
}: {
  recipient: DonationRecipient;
  chainId: number;
}): Promise<Address | undefined> =>
  match(recipient)
    .with({ type: 'fund' }, async ({ id }) => {
      const fund = await GetFund.fetchFromDefaultClient([id]);
      if (typeof fund.v2ContractAddress !== 'string') return undefined;
      return fund.v2ContractAddress;
    })
    .with({ type: 'org' }, async ({ einOrId }) => {
      const org = await GetOrg.fetchFromDefaultClient([einOrId]);
      const currentDeployment = getOrgDeploymentForChain(org.deployments, chainId);
      if (!currentDeployment) return undefined;
      return currentDeployment.contractAddress;
    })
    .exhaustive();

export const useTransferFee = ({
  sender,
  recipient,
  chainId,
  transferAmount,
  enabled: parentEnabled = true,
}: UseTransferFeeArgs) => {
  const currentChainId = useChainId();
  const [receiverAddress, setReceiverAddress] = useState<Address>();
  useAsync(async () => {
    setReceiverAddress(
      await getReceiverAddressForTransferFee({
        recipient,
        chainId,
      }),
    );
  }, [recipient, chainId]);
  const { data, isPending } = useReadContract({
    abi: registryAbi,
    address: getContractAddressForChain(currentChainId, 'registry'),
    args: [sender as Address, receiverAddress as Address],
    functionName: 'getTransferFee',
    query: {
      enabled: parentEnabled && !!sender && !!receiverAddress,
      select: feeBps => calculateFee(feeBps, transferAmount),
    },
  });

  if (!data) return { data: calculateFee(defaults.fees.transferBps, transferAmount), isPending: false } as const;

  return { data, isPending } as const;
};
