import { TokenAmount } from '@endaoment-frontend/ui/shared';
import { formatCurrency, formatUsdc } from '@endaoment-frontend/utils';

import { AddressPill } from '../common/ActivityPills';
import type { ActivitySubject, MembershipRewardActivity } from '../types';

import { ActivityDescription } from './ActivityDescription';

export const RewardActivity = ({
  activity,
  subject,
}: {
  activity: MembershipRewardActivity;
  subject?: ActivitySubject;
}) => {
  const descriptorWithBasis = `${activity.dollarBasis ? `${formatCurrency(formatUsdc(activity.dollarBasis))} ` : ''}${
    activity.descriptor
  }`;

  return (
    <ActivityDescription>
      {!subject || subject === 'user' ? (
        'You '
      ) : (
        <AddressPill transactor={activity.transactor} chainId={activity.chainId} />
      )}
      earned
      <TokenAmount amount={activity.rewardAmount} size='small' />
      for
      <em>{descriptorWithBasis}</em>
      {!!activity.cadence && ` for a ${activity.cadence}`}
    </ActivityDescription>
  );
};
