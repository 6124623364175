import { RequestHandler } from '@endaoment-frontend/data-fetching';
import {
  adminGrantSchema,
  arraySchemaInvalidsFiltered,
  bigIntSchema,
  grantSchema,
  type Address,
  type AdminGrant,
  type Grant,
  type UUID,
} from '@endaoment-frontend/types';

export const GetTotalGrants = new RequestHandler(
  'GetTotalGrants',
  fetch => async (): Promise<bigint> => {
    try {
      const res = await fetch('/v1/transfers/grants/total');
      return bigIntSchema.parse(res);
    } catch {
      return 0n;
    }
  },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/transfers/grants/total`,
    }),
  },
);

export const GetFundGrants = new RequestHandler(
  'GetFundGrants',
  fetch =>
    async (id: UUID): Promise<Array<Grant>> => {
      const res = await fetch(`/v1/transfers/grants/fund/${id}`);
      return arraySchemaInvalidsFiltered(grantSchema).parse(res);
    },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/transfers/grants/fund/:id`,
    }),
  },
);

type RegisterGrantArgs = {
  recommender: string;
  purpose: string;
  specialInstructions?: string;
  shareMyEmail: boolean;
  subprojectId?: UUID;
  recommendationId?: UUID;
};
type RegisterGrantBody = {
  transactionHash: Address;
  chainId: number;
  grantInput: {
    recommender?: string;
    purpose: string;
    specialInstructions?: string;
    subprojectId?: UUID;
    shareMyEmail: boolean;
  };
  recommendationIds?: Array<UUID>;
};
/**
 * Callback for when a grant has finished processing on the blockchain
 */
export const RegisterGrant = new RequestHandler(
  'RegisterGrant',
  fetch =>
    async (transactionHash: Address, chainId: number, grantArgs: RegisterGrantArgs): Promise<void> => {
      const { recommendationId, ...grantInput } = grantArgs;
      await fetch('/v1/transfers', {
        method: 'POST',
        body: {
          transactionHash,
          chainId,
          grantInput,
          recommendationIds: recommendationId ? [recommendationId] : [],
        } satisfies RegisterGrantBody,
      });
    },
  { isUserSpecificRequest: true },
);

export const GetAdminGrants = new RequestHandler(
  'GetAdminGrants',
  fetch =>
    async (
      count?: number,
      offset?: number,
      fundSearchTerm?: string,
      orgSearchTerm?: string,
    ): Promise<Array<AdminGrant>> => {
      const res = await fetch('/v1/transfers/all', {
        params: {
          count,
          offset,
          fundSearchTerm,
          orgSearchTerm,
        },
      });
      return arraySchemaInvalidsFiltered(adminGrantSchema).parse(res);
    },
);

export const ApproveGrant = new RequestHandler('ApproveGrant', fetch => async (id: UUID): Promise<Grant> => {
  const res = await fetch(`/v1/transfers/${id}/approve`, {
    method: 'PUT',
  });
  return grantSchema.parse(res);
});
