import clsx from 'clsx';
import Marquee from 'react-fast-marquee';

import { GetFeaturedActivity, MatchedActivityDescription } from '@endaoment-frontend/activities';

import styles from './RecentActivity.module.scss';

export const RecentActivity = () => {
  const { data: recentActivity } = GetFeaturedActivity.useQuery([]);

  return (
    <div className={styles['container']}>
      <div>
        <span className={styles['label']}>Recent Activity</span>
        <Marquee pauseOnHover className={styles['marquee']} gradient={false} speed={40}>
          {recentActivity?.map((activity, i) => (
            <div key={`slide-${i}`} className={clsx(styles['marquee__item'])}>
              <MatchedActivityDescription subject='featured' activity={activity} />
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};
