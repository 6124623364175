import { Flex } from '@chakra-ui/react';
import clsx from 'clsx';
import Link from 'next/link';
import { type ComponentProps } from 'react';
import { match, P } from 'ts-pattern';

import { GetFundPositions, GetPortfolio } from '@endaoment-frontend/api';
import { routes } from '@endaoment-frontend/routes';
import type { EntityPosition, EntityPositionSummary, FundDetails, UUID } from '@endaoment-frontend/types';
import {
  AllocationIcon,
  ArrowIcon,
  GearIcon,
  InfoIcon,
  InTransitIcon,
  TargetAllocationIcon,
} from '@endaoment-frontend/ui/icons';
import { Button, cardClassNames, EmptyStateBlock, Pill, Tooltip } from '@endaoment-frontend/ui/shared';
import { MiniLoadingDetails, MiniPortfolioDetails } from '@endaoment-frontend/ui/smart';
import { convertUsdcToNumber, formatCurrency, formatNumber, formatUsdc } from '@endaoment-frontend/utils';

import { computeContainsDeviatedPositions, computeDeviationFromTarget } from './computeValues';
import { GrantableBalanceCard, OtherBalanceCard } from './GrantableBalanceCard';
import { RebalanceButton } from './RebalanceButton';
import { CalculateEntityRebalance, GetTargetAllocations } from './requests';
import { TargetAllocationOverview } from './TargetAllocationOverview';
import styles from './TargetAllocationSection.module.scss';
import type { TargetAllocation } from './types';
import { useOpenTargetAllocationModal } from './useTargetAllocationModal';

export const TargetAllocationSectionContent = ({
  fund,
  positionSummary,
  hideEmptyStateBlock = false,
  onRouteToPositions,
}: {
  fund: Pick<FundDetails, 'id' | 'investedUsdc' | 'usdcBalance'>;
  positionSummary: EntityPositionSummary;
  hideEmptyStateBlock?: boolean;
  onRouteToPositions?: string | ((fundId: UUID) => void);
}) => {
  const { data: targetAllocations } = GetTargetAllocations.useQuery(['fund', fund.id]);
  const hasTargetAllocations = targetAllocations && targetAllocations.length > 0;
  const { data: rebalanceOps } = CalculateEntityRebalance.useQuery(['fund', fund.id, false], {
    enabled: hasTargetAllocations,
  });

  const openModal = useOpenTargetAllocationModal();

  const hasPositions = positionSummary.positions.length > 0;

  const setTargetAllocationButton = (
    <Button
      onClick={() => openModal(fund.id)}
      filled
      variation='allocation'
      size='medium'
      className={styles['allocation-button']}>
      <TargetAllocationIcon color='currentColor' />
      Set a Target Allocation
    </Button>
  );
  const viewPositionsButtonRoutingProps = match(onRouteToPositions)
    .with(P.string, href => ({ as: Link, href }))
    .with(P.not(P.nullish), fn => ({ onClick: () => fn(fund.id) }))
    .otherwise(() => ({ as: Link, href: routes.app.portfolios({ view: 'positions' }) }));
  const viewPositionsButton = (
    <Button
      {...viewPositionsButtonRoutingProps}
      variation='portfolio'
      filled
      float={false}
      size='medium'
      className={styles['positions-button']}>
      Go To Positions ({positionSummary.positions.length})
      <ArrowIcon color='currentColor' />
    </Button>
  );

  if (!hasTargetAllocations) {
    if (!hasPositions) {
      return hideEmptyStateBlock ? (
        <div className={styles['allocation-buttons']}>{setTargetAllocationButton}</div>
      ) : (
        <EmptyStateBlock
          variation='allocation'
          title='Maximize your impact!'
          description='There is no Target Allocation on this fund. Set a target allocation to start allocating to available onchain, traditional, and impact-oriented portfolios.'
          vertical
          spread
          className={styles['empty']}>
          {setTargetAllocationButton}
        </EmptyStateBlock>
      );
    }
    return hideEmptyStateBlock ? (
      <div className={styles['allocation-buttons']}>
        {setTargetAllocationButton}
        {viewPositionsButton}
      </div>
    ) : (
      <EmptyStateBlock
        variation='allocation'
        title='Maximize your impact!'
        description='Assign a target allocation to match your positions to a set mix of portfolios.'
        vertical
        spread
        className={styles['empty']}>
        <div className={styles['allocation-buttons']}>
          {setTargetAllocationButton}
          {viewPositionsButton}
        </div>
      </EmptyStateBlock>
    );
  }

  const allocationPortfolioIds = targetAllocations.map(t => t.portfolioId);
  const sumOfOtherPositions = positionSummary.positions
    .filter(p => !allocationPortfolioIds.includes(p.portfolio.id))
    .reduce((acc, v) => acc + v.currentMarketValue, 0n);

  const grantablePercentage = targetAllocations.reduce((acc, v) => acc - v.percentage, 1);

  const { isWithinTarget: isGrantableBalanceWithinTarget } = computeDeviationFromTarget(fund.usdcBalance, fund, {
    percentage: grantablePercentage ?? 0,
  });

  /* Assess if there are any positions that are individually outside of target but there are no rebalance ops for the fund */
  const hasUnbalanceableDeviatedPositions =
    !rebalanceOps?.length &&
    (!isGrantableBalanceWithinTarget ||
      computeContainsDeviatedPositions(fund, positionSummary.positions, targetAllocations));

  return (
    <div className={styles['allocations__container']}>
      <div className={styles['allocations__edit-line']}>
        <Button onClick={() => openModal(fund.id)} size='small' variation='fund' faded float={false}>
          <GearIcon color='currentColor' width={24} />
          Edit
        </Button>
      </div>
      <TargetAllocationOverview fundId={fund.id} />
      <div className={styles.allocations}>
        {targetAllocations.map((allocation, index) => {
          const currentPosition = positionSummary?.positions.find(p => p.portfolio.id === allocation.portfolioId);
          return (
            <TargetAllocationItem
              allocation={allocation}
              currentPosition={currentPosition}
              fund={fund}
              key={allocation.portfolioId}
              index={index}
              noRebalanceOps={!rebalanceOps?.length}
              hidePercent={fund.usdcBalance === 0n}
            />
          );
        })}
        <div className={clsx(styles['allocation__listing'], styles['allocation__listing--grantable'])}>
          <GrantableBalanceCard fund={fund} grantablePercentage={grantablePercentage} />
        </div>
        {sumOfOtherPositions > 0 && (
          <div className={clsx(styles['allocation__listing'], styles['allocation__listing--other'])}>
            <OtherBalanceCard balance={sumOfOtherPositions} />
          </div>
        )}
      </div>
      <div className={styles['allocation-button__container']}>
        <RebalanceButton fundId={fund.id} hasUnbalanceableDeviatedPositions={hasUnbalanceableDeviatedPositions} />
      </div>
    </div>
  );
};
export const TargetAllocationSectionContentFromFund = ({
  fund,
  hideEmptyStateBlock = false,
}: Pick<ComponentProps<typeof TargetAllocationSectionContent>, 'fund' | 'hideEmptyStateBlock'>) => {
  const { data: positionsSummary } = GetFundPositions.useQuery([fund.id, 'fast']);

  if (!positionsSummary) return <></>;

  return (
    <TargetAllocationSectionContent
      hideEmptyStateBlock={hideEmptyStateBlock}
      fund={fund}
      positionSummary={positionsSummary}
    />
  );
};

export const TargetAllocationItem = ({
  allocation,
  currentPosition,
  fund,
  hidePercent = false,
  index,
  noRebalanceOps = false,
}: {
  allocation: TargetAllocation;
  currentPosition?: EntityPosition;
  fund: Pick<FundDetails, 'investedUsdc' | 'usdcBalance'>;
  hidePercent?: boolean;
  index: number;
  noRebalanceOps?: boolean;
}) => {
  const portfolioQuery = GetPortfolio.useQuery([allocation.portfolioId], {
    enabled: !currentPosition,
  });
  const portfolio = currentPosition ? currentPosition.portfolio : portfolioQuery.data;

  const isMoneyInTransit = currentPosition
    ? currentPosition?.inTransitBuyUsdcAmount + currentPosition?.inTransitSellUsdcAmount
    : 0n;

  const { deviationPercentFromTarget, deviationAmountFromTarget, isWithinTarget, currentPositionPercent } =
    computeDeviationFromTarget(currentPosition?.currentMarketValue, fund, allocation);

  const indicatorClass = match({
    isWithinTarget,
    noRebalanceOps,
    isMoneyInTransit,
  })
    .returnType<string | undefined>()
    .with({ isWithinTarget: true }, () => styles['within-target'])
    .with({ isMoneyInTransit: P.not(0n) }, () => styles['in-transit'])
    .with({ isWithinTarget: false }, () => styles['off-target'])
    .otherwise(() => undefined);

  const currentDeviation = (
    <div>
      <b className={indicatorClass}>
        {deviationPercentFromTarget >= 0 ? '-' : '+'}
        {formatCurrency(formatUsdc(deviationAmountFromTarget), { compact: true }).replace('-', '')}
      </b>{' '}
      (
      <b className={indicatorClass}>
        {deviationPercentFromTarget >= 0 ? '-' : '+'}
        {formatNumber(Math.abs(deviationPercentFromTarget) * 100, {
          digits: 3,
          fractionDigits: 2,
          stripZeros: true,
        })}
        %
      </b>
      )
    </div>
  );

  return (
    <div className={styles['allocation__listing']} data-index={index}>
      <div className={clsx(cardClassNames.base, cardClassNames.removePadding, styles['allocation'])}>
        <Link href={routes.app.portfolio({ id: allocation.portfolioId })}>
          {portfolio ? <MiniPortfolioDetails portfolio={portfolio} padding={false} /> : <MiniLoadingDetails />}
        </Link>
        <hr />
        <div>
          <span>
            <div>
              <AllocationIcon color='currentColor' width={18} height={18} />
              <b>{formatCurrency(formatUsdc(currentPosition?.currentMarketValue), { compact: true })}</b> position
            </div>
            {isMoneyInTransit > 0 && (
              <div>
                <InTransitIcon color='currentColor' width={18} height={18} />
                <b>{formatCurrency(formatUsdc(isMoneyInTransit), { compact: true })}</b> in transit
              </div>
            )}
          </span>
          {!hidePercent && (
            <Tooltip
              innerClassName={styles['difference-tooltip']}
              content={
                <>
                  {noRebalanceOps ? (
                    <div>
                      This position does not warrant a rebalance. A minimum of 5% and{' '}
                      {portfolio ? formatCurrency(Math.max(100, convertUsdcToNumber(portfolio.minDeposit))) : '$100'}{' '}
                      from target is required. Current deviation: {currentDeviation}.
                    </div>
                  ) : isMoneyInTransit > 0 ? (
                    'This position has portfolio trades in transit, difference from target might be affected upon resolution. Current deviation: '
                  ) : (
                    <div>Difference from target: {currentDeviation}.</div>
                  )}
                </>
              }>
              <Flex alignItems='center' flexDir='row'>
                <Flex alignItems={['stretch', 'center']} flexDir={['column', 'row']}>
                  <Pill size='tiny' className={styles['current-pill']}>
                    Current: {formatNumber(currentPositionPercent * 100, { digits: 3, fractionDigits: 1 })}%
                    {!!(noRebalanceOps || isMoneyInTransit > 0) && (
                      <InfoIcon color='currentColor' width={14} height={14} />
                    )}
                  </Pill>
                  <Pill size='tiny' className={styles['target-pill']}>
                    Target: {formatNumber(allocation.percentage * 100, { digits: 3, fractionDigits: 1 })}%
                  </Pill>
                </Flex>
                <div className={clsx(indicatorClass, styles['status'])} />
              </Flex>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};
