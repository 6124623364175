import { addBreadcrumb, captureException, startSpan } from '@sentry/nextjs';
import type { Config } from '@wagmi/core';
import { waitForTransactionReceipt } from '@wagmi/core';
import { useSetAtom } from 'jotai';
import { useMount } from 'react-use';
import { TransactionNotFoundError, TransactionReceiptNotFoundError, WaitForTransactionReceiptTimeoutError } from 'viem';
import { useConfig } from 'wagmi';

import { defaults } from '@endaoment-frontend/config';
import type { Address, BlockchainTransactionStatus } from '@endaoment-frontend/types';
import { delay } from '@endaoment-frontend/utils';

import { changeStatusAtom, replaceTransactionAtom } from './transactionListAtoms';
import { useTransactionList, type StoredTransaction } from './useTransactionList';

const transactionTypeToRequiredConfirmations: Record<StoredTransaction['type'], number> = {
  BATCH_DEPLOY_ORG: defaults.confirmations.orgDeployment,
  CREATE_DONATION: defaults.confirmations.donation,
  CREATE_GRANT: defaults.confirmations.transfer,
  DEPLOY_ORG: defaults.confirmations.orgDeployment,
  EDIT_POSITION: defaults.confirmations.portfolioTrade,
  DEPLOY_FUND: defaults.confirmations.fundDeployment,
  REBALANCE_FUND: defaults.confirmations.portfolioTrade,
};

export const TransactionListProvider = () => {
  const { pendingTransactions } = useTransactionList();

  return (
    <>
      {pendingTransactions.map(v => (
        <TransactionWaiter key={v.hash} transaction={v} />
      ))}
    </>
  );
};

/**
 * Waits till transaction is confirmed and updates status with final value
 *
 * Exported only for testing purposes, should not be used outside of this file
 */
export const updateTransactionWithCompletion = async (
  {
    wagmiConfig,
    transaction,
    onChangeStatus,
    onReplaceTransaction,
  }: {
    wagmiConfig: Config;
    transaction: StoredTransaction;
    onChangeStatus: (arg: { hash: Address; status: BlockchainTransactionStatus }) => void;
    onReplaceTransaction: (arg: { oldHash: Address; newHash: Address }) => void;
  },
  attemptCount = 1,
): Promise<void> => {
  try {
    addBreadcrumb({
      message: `Waiting for transaction ${transaction.hash} to be confirmed on chain ${transaction.chainId}`,
      level: 'info',
    });

    const confirmations = transactionTypeToRequiredConfirmations[transaction.type];
    const receipt = await waitForTransactionReceipt(wagmiConfig, {
      hash: transaction.hash,
      chainId: transaction.chainId,
      confirmations,
      onReplaced: ({ replacedTransaction, transaction }) => {
        // Replace the transaction in the list with the one that replaced it
        onReplaceTransaction({ oldHash: replacedTransaction.hash, newHash: transaction.hash });
      },
      timeout: (confirmations || 2) * 60_000,
    });

    if (receipt.transactionHash !== transaction.hash) {
      // Replace the transaction in the list with the one that replaced it
      onReplaceTransaction({ oldHash: transaction.hash, newHash: receipt.transactionHash });
    }
    onChangeStatus({
      hash: receipt.transactionHash,
      status: receipt.status === 'success' ? 'success' : 'error',
    });
  } catch (e) {
    if (!(e instanceof Error)) throw e;

    // Try to wait for the transaction to get on the mempool if it's not there
    if (e instanceof TransactionNotFoundError || e instanceof TransactionReceiptNotFoundError) {
      // Give up on the 3rd attempt
      if (attemptCount > 2) {
        captureException(e, {
          tags: {
            'transaction-not-found': true,
          },
          level: 'warning',
          extra: { transaction },
        });
        return;
      }

      // Try again in 10 seconds
      await delay(10_000);
      addBreadcrumb({
        message: `Transaction ${transaction.hash} not found, retry attempt ${attemptCount}`,
        level: 'warning',
      });
      return updateTransactionWithCompletion(
        { wagmiConfig, transaction, onChangeStatus, onReplaceTransaction },
        attemptCount + 1,
      );
    }
    if (e instanceof WaitForTransactionReceiptTimeoutError) {
      captureException(e, {
        level: 'warning',
        extra: { transaction },
      });
      return;
    }

    // Default to transaction failure for any other error
    captureException(e, {
      tags: {
        'transaction-failure': true,
      },
      level: 'warning',
      extra: { transaction },
    });
    onChangeStatus({ hash: transaction.hash, status: 'error' });
  }
};
/**
 * Add atoms to update function and call it on mount
 */
const TransactionWaiter = ({ transaction }: { transaction: StoredTransaction }) => {
  const onChangeStatus = useSetAtom(changeStatusAtom);
  const onReplaceTransaction = useSetAtom(replaceTransactionAtom);
  const wagmiConfig = useConfig();

  useMount(() => {
    void startSpan(
      {
        name: `Wait for transaction ${transaction.hash}`,
      },
      () =>
        updateTransactionWithCompletion({
          wagmiConfig,
          transaction,
          onChangeStatus,
          onReplaceTransaction,
        }),
    );
  });

  return <></>;
};
