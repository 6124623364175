import clsx from 'clsx';
import { useEffect } from 'react';

import { GetUserIdentity } from '@endaoment-frontend/api';
import { useAuth } from '@endaoment-frontend/authentication';
import { type UserIdentityInfo } from '@endaoment-frontend/types';
import { Checkbox } from '@endaoment-frontend/ui/forms';

import styles from './TaxReceiptButton.module.scss';

type TaxReceiptButtonProps = {
  includeTaxReceipt: boolean;
  receiptEmail: string | undefined;
  onClick: (newState: boolean) => void;
  className?: string;
};

export const TaxReceiptButton = ({ includeTaxReceipt, receiptEmail, onClick, className }: TaxReceiptButtonProps) => {
  return (
    <Checkbox
      checked={includeTaxReceipt}
      onChange={onClick}
      className={clsx(styles['tax-receipt-button'], className)}
      wrapperClassName={styles['wrapper']}
      label={
        includeTaxReceipt ? (
          <>
            Send a Tax Receipt&nbsp;
            {!!receiptEmail && (
              <>
                to&nbsp;<b>{receiptEmail}</b>
              </>
            )}
          </>
        ) : (
          <>Send me a tax receipt</>
        )
      }
    />
  );
};

export const useInitializeTaxReceipt = (
  fn: (userIdentity: UserIdentityInfo | null | undefined) => void,
  opts: { isCollaborator: boolean },
) => {
  const { isSignedIn } = useAuth();
  const result = GetUserIdentity.useQuery([{ fallbackToEmpty: false }], {
    enabled: !!isSignedIn && !opts.isCollaborator,
    retry: 3,
  });

  useEffect(() => {
    if (!result.isSuccess) return;
    fn(result.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.isSuccess]);

  return result;
};
