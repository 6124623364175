import { Flex } from '@chakra-ui/react';
import { skipToken } from '@tanstack/react-query';
import clsx from 'clsx';
import { useState } from 'react';
import { P, match } from 'ts-pattern';

import { GetFundsWithPortfolioPermissions, GetPortfolio, GetUserFunds } from '@endaoment-frontend/api';
import { useOpenFundWizard } from '@endaoment-frontend/fund-wizard';
import { calculateGrantableBalance } from '@endaoment-frontend/funds';
import type { FundListing, PortfolioFinancial, UUID } from '@endaoment-frontend/types';
import { Input } from '@endaoment-frontend/ui/forms';
import { Button, Card, Loader } from '@endaoment-frontend/ui/shared';

import wizardStyles from '../PortfolioWizard.module.scss';
import { FundDetailsCardWithBar } from '../common/FundDetailsCardWithBar';
import { RemovablePortfolioDetails } from '../common/RemovableDetails';

export const getSelectableFunds = <
  MinimalFund extends Pick<
    FundListing,
    | 'chainId'
    | 'id'
    | 'inTransitBuyUsdcAmount'
    | 'inTransitSellUsdcAmount'
    | 'investedUsdc'
    | 'processingTransfersTotalUsdc'
    | 'usdcBalance'
  >,
>(
  userFunds: Array<MinimalFund> | undefined,
  portfolio: Pick<PortfolioFinancial, 'chainId' | 'isExclusive'> | 'unselected' | undefined,
  allowedFundIds: Array<UUID> | undefined,
): Array<MinimalFund> => {
  if (!userFunds || !portfolio) return [];

  // If the portfolio is exclusive, we need to ensure that only funds that are allowed are shown
  if (portfolio !== 'unselected' && portfolio.isExclusive && !allowedFundIds) return [];

  return userFunds.filter(fund => {
    // Filter out funds with no money in them
    if (calculateGrantableBalance(fund) <= 0n) return false;

    // Exit early if no portfolio is selected
    if (portfolio === 'unselected') return true;

    // Filter out funds that don't match the portfolio chain
    if (fund.chainId !== portfolio.chainId) return false;

    // Filter out funds that are not allowed for exclusive portfolios
    if (portfolio.isExclusive && !allowedFundIds?.includes(fund.id)) return false;

    return true;
  });
};
const filterFundsBySearch = <MinimalFund extends Pick<FundListing, 'description' | 'name'>>(
  search: string,
  funds: Array<MinimalFund>,
): Array<MinimalFund> => {
  if (!search || search === '') return funds;

  return funds.filter(fund => {
    // Filter out funds that don't match the search
    return (
      search === '' ||
      fund.name.toLowerCase().includes(search.toLowerCase()) ||
      fund.description.toLowerCase().includes(search.toLowerCase())
    );
  });
};

export const FundStep = ({
  portfolioId,
  onClose,
  onClearPortfolioSelection,
  onFundSelect,
}: {
  portfolioId?: UUID;
  onClose: () => void;
  onClearPortfolioSelection: () => void;
  onFundSelect: (fundId: UUID) => void;
}) => {
  const { data: portfolio } = GetPortfolio.useQuery(portfolioId ? [portfolioId] : skipToken, {
    enabled: !!portfolioId,
  });
  const portfolioDisplay = portfolioId ? (
    <>
      <Card noPadding noShadow>
        <RemovablePortfolioDetails portfolioId={portfolioId} onRemove={onClearPortfolioSelection} />
      </Card>
      <hr />
    </>
  ) : (
    <></>
  );

  const openFundWizard = useOpenFundWizard();

  const { data: userFunds } = GetUserFunds.useQuery([]);
  const { data: allowedFundIds } = GetFundsWithPortfolioPermissions.useQuery(portfolio ? [portfolio.id] : skipToken, {
    enabled: !!portfolio && portfolio.isExclusive,
  });
  const [search, setSearch] = useState('');
  const selectableFunds = getSelectableFunds(userFunds, portfolioId ? portfolio : 'unselected', allowedFundIds);
  const filteredFunds = filterFundsBySearch(search, selectableFunds);

  const handleFundSelect = (fundId: UUID) => () => {
    // Do not allow selecting while portfolio is loading
    if (portfolioId && !portfolio) throw new Error(`Portfolio with ID ${portfolioId} not found in cache`);

    const selectedFund = userFunds?.find(fund => fund.id === fundId);
    if (!selectedFund) throw new Error(`Fund with ID ${fundId} not found in user funds`);

    // Do not allow selecting funds that are on a different chain than the portfolio
    if (portfolio && selectedFund.chainId !== portfolio.chainId)
      throw new Error(`Fund with ID ${fundId} is on a different chain than the portfolio`);

    onFundSelect(fundId);
  };

  const fundsDisplay = match({
    isSearchable: !!selectableFunds && selectableFunds.length > 3,
    filteredFunds,
    selectableFunds,
    userFunds,
  })
    .with({ isSearchable: true, filteredFunds: P.not(P.nullish) }, ({ filteredFunds }) => (
      <>
        <h4>Which fund would you like to allocate from?</h4>
        <div className={wizardStyles['search-container__outer']}>
          <Input
            value={search}
            onChange={v => setSearch(v.currentTarget.value)}
            placeholder='Filter Funds'
            className={wizardStyles['search-container__filter']}
          />
          <div className={clsx(wizardStyles['search-container'], wizardStyles['search-container--with-filter'])}>
            {filteredFunds.map(({ id }) => (
              <FundDetailsCardWithBar key={id} fundId={id} onClick={handleFundSelect(id)} selectable />
            ))}
            {filteredFunds.length === 0 && <h4>No results</h4>}
          </div>
        </div>
      </>
    ))
    .with({ isSearchable: false, selectableFunds: [P.any, ...P.array()] }, ({ selectableFunds }) => (
      <>
        <h4>Which fund would you like to allocate from?</h4>
        <div className={wizardStyles['search-container__outer']}>
          <div className={clsx(wizardStyles['search-container'])}>
            {selectableFunds.map(({ id }) => (
              <FundDetailsCardWithBar key={id} fundId={id} onClick={handleFundSelect(id)} selectable />
            ))}
          </div>
        </div>
      </>
    ))
    .with({ selectableFunds: [], userFunds: P.not(P.nullish) }, ({ userFunds }) => (
      <div className={wizardStyles['search-container__outer']}>
        <div className={clsx(wizardStyles['search-container'])}>
          <div className={wizardStyles['warning']}>
            <h4>{userFunds.length > 0 ? 'You have no funds with a balance' : 'You have no funds yet!'}</h4>
            <p>In order to proceed please choose one of the following:</p>
            <Flex alignItems='center' justifyContent='center' gap={4} marginTop={4}>
              <Button
                size='small'
                filled
                variation='portfolio'
                onClick={() => {
                  onClose();
                  openFundWizard({
                    initialMode: 'create',
                  });
                }}>
                Create New Fund
              </Button>
              {!!portfolioId && (
                <Button size='small' filled variation='portfolio' onClick={onClearPortfolioSelection}>
                  Clear Portfolio Selection
                </Button>
              )}
            </Flex>
          </div>
        </div>
      </div>
    ))
    .otherwise(() => <Loader />);
  return (
    <>
      {portfolioDisplay}
      {fundsDisplay}
    </>
  );
};
