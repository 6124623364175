import { Form, Formik } from 'formik';
import { z } from 'zod';

import { STABLECOIN_DECIMALS } from '@endaoment-frontend/constants';
import { useIsMobile } from '@endaoment-frontend/hooks';
import type { FundListing } from '@endaoment-frontend/types';
import { BigNumberInput, ProceedButton, validateWithZod } from '@endaoment-frontend/ui/forms';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';
import { formatCurrency } from '@endaoment-frontend/utils';

import styles from './MigrateFund.module.scss';

const DAF_MIGRATION_MINIMUM_AMOUNT_DOLLARS = 25_00n as const;
const amountInputFormSchema = z.object({
  amount: z.bigint().gte(DAF_MIGRATION_MINIMUM_AMOUNT_DOLLARS, {
    message: `Amount must be equal or greater than ${formatCurrency(
      Number(DAF_MIGRATION_MINIMUM_AMOUNT_DOLLARS) / 1e2,
    )}`,
  }),
});

export const MigrateFundAmount = ({
  initialValue,
  fund,
  onSubmit,
  onRemove,
}: {
  initialValue: bigint;
  fund: FundListing;
  onSubmit: (amount: bigint) => void;
  onRemove: () => void;
}) => {
  const { isMobile } = useIsMobile({ defaultState: true });

  return (
    <>
      <EntityCardWithLabel label='Migrating to' entity={{ type: 'fund', id: fund.id }} onRemove={onRemove} />

      <h4 className={styles['step-header']}>How much will you be migrating?</h4>

      <Formik
        initialValues={{ amount: initialValue }}
        onSubmit={v => onSubmit(v.amount)}
        validate={validateWithZod(amountInputFormSchema)}>
        {({ errors, values, setFieldValue, setFieldTouched }) => (
          <Form className={styles['amount-form']}>
            <BigNumberInput
              name='amount'
              units={STABLECOIN_DECIMALS}
              value={values.amount}
              onChange={v => setFieldValue('amount', v, true)}
              onBlur={() => setFieldTouched('amount', true)}
              error={errors.amount}
              placeholder='0.00'
              isDollars
              autoFocus={!isMobile}
            />

            <ProceedButton type='submit' />
          </Form>
        )}
      </Formik>
    </>
  );
};
