import { getAccessToken, type PrivyEvents, type User } from '@privy-io/react-auth';
import { signMessage, type Config } from '@wagmi/core';

import { addressSchema, type LoginDTO } from '@endaoment-frontend/types';
import { makeValidLoginSignature } from '@endaoment-frontend/utils';

import { isAdministrativeAccount } from './admin';
import { GetPrivyWalletAddressForUser } from './useAuth';
import { isUserWalletSocial } from './useAuthType';

type PrivyLoginCompleteArgs = Parameters<NonNullable<PrivyEvents['login']['onComplete']>>;
type LoginMethod = NonNullable<PrivyLoginCompleteArgs[3]>;

const getEmailForSocialLoginMethod = (user: User, loginMethod: LoginMethod | null): string | undefined => {
  if (!user.wallet) throw new Error('User has no wallet');
  if (!loginMethod) {
    // If we don't have a login method, simply run down the list of social methods and return the first email we find
    return getFirstAvailablePrivyEmail(user);
  }
  if (loginMethod === 'siwe') throw new Error(`${loginMethod} is not a social method`);
  if (!isUserWalletSocial(user.wallet)) throw new Error('User is not a social wallet');

  const loginMethodToEmail = new Map<string, string | undefined>([
    ['email', user.email?.address],
    ['sms', user.email?.address ?? undefined],
    ['google', user.google?.email],
    ['apple', user.apple?.email],
    ['discord', user.discord?.email ?? undefined],
    ['github', user.github?.email ?? undefined],
    ['linkedin', user.linkedin?.email ?? undefined],
    ['spotify', user.spotify?.email ?? undefined],

    // Methods without any email possible
    ['farcaster', undefined],
    ['passkey', undefined],
    ['twitter', undefined],
    ['tiktok', undefined],
    ['instagram', undefined],
    ['guest', undefined],
    ['telegram', undefined],
    ['custom', undefined],

    // This should never happen
    ['siwe', undefined],
    // ['siws', undefined],
  ] satisfies Array<[LoginMethod, string | undefined]>);
  return loginMethodToEmail.get(loginMethod);
};
const getFirstAvailablePrivyEmail = (user: User): string | undefined => {
  return (
    user.email?.address ??
    user.google?.email ??
    user.apple?.email ??
    user.discord?.email ??
    user.github?.email ??
    user.linkedin?.email ??
    user.farcaster?.url ??
    user.spotify?.email ??
    undefined
  );
};

type LoginDtoFromPrivyLoginArgs = {
  wagmiConfig: Config;
  user: User;
  loginMethod: LoginMethod | null;
};

export const getLoginDtoFromPrivyLogin = async ({
  wagmiConfig,
  user,
  loginMethod,
}: LoginDtoFromPrivyLoginArgs): Promise<LoginDTO> => {
  if (!user.wallet || !user.wallet.address) throw new Error('Logged a user in without a wallet');

  // This can be either a Smart Account or an EOA depending on the authentication method
  const walletAddressToUse = await GetPrivyWalletAddressForUser.fetchFromDefaultClient([user]);
  if (!walletAddressToUse) throw new Error('No wallet address found');

  // Get token to send to the BE
  const privyToken = await getAccessToken();
  if (!privyToken) throw new Error('No privy token found');

  const isSocialLogin = loginMethod ? loginMethod !== 'siwe' : user.wallet.walletClientType === 'privy';

  if (isSocialLogin) {
    // The BE needs to be informed the embedded address of the user separately
    const embeddedAddress = addressSchema.parse(user.wallet.address);
    // The wallet address being used to sign for social users is the smart account address
    const zerodevWalletAddress = walletAddressToUse;

    console.info(`Attempting social login for wallet ${embeddedAddress} with smart account ${zerodevWalletAddress}`);
    return {
      type: 'social',
      activeWalletAddress: embeddedAddress,
      smartAccountAddress: zerodevWalletAddress,
      privyToken,
      email: getEmailForSocialLoginMethod(user, loginMethod),
    };
  }

  if (isAdministrativeAccount(walletAddressToUse)) {
    // Admins must sign to prove their identity
    const siweMessage = makeValidLoginSignature(walletAddressToUse);
    console.warn(`Attempting to login as admin wallet (${walletAddressToUse})`);
    const signature = await signMessage(wagmiConfig, {
      account: walletAddressToUse,
      message: siweMessage.message,
    });

    return {
      type: 'admin',
      privyToken,
      activeWalletAddress: walletAddressToUse,
      signature,
      signatureDateUtc: siweMessage.date,
    };
  }

  // Default case of wallet login
  console.info(`Attempting wallet login for wallet ${walletAddressToUse}`);
  return {
    type: 'wallet',
    activeWalletAddress: walletAddressToUse,
    privyToken,
  };
};
