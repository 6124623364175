import type { GenericToken, TokenBalance } from '@endaoment-frontend/types';

export const sortTokensByPopularity = (t1: GenericToken, t2: GenericToken) => t2.popularity - t1.popularity;

export const sortTokens = (
  t1: GenericToken,
  t2: GenericToken,
  pinnedTokenIds: Array<number>,
  tokenBalances?: Array<TokenBalance>,
) => {
  // 1. Pinned tokens
  const isPinned1 = pinnedTokenIds.includes(t1.id);
  const isPinned2 = pinnedTokenIds.includes(t2.id);
  if (isPinned1 && !isPinned2) return -1;
  if (!isPinned1 && isPinned2) return 1;

  // 2. If token balance exists, sort by larger balance
  if (tokenBalances) {
    const balance1 = tokenBalances.find(tb => tb.token.id === t1.id)?.balance ?? 0n;
    const balance2 = tokenBalances.find(tb => tb.token.id === t2.id)?.balance ?? 0n;

    if (balance1 > 0n && balance2 > 0n) return balance2 - balance1 > 0 ? 1 : -1;
    if (balance1 > 0n) return -1;
    if (balance2 > 0n) return 1;
  }

  // 3. Sort by popularity
  if (t1.popularity !== t2.popularity) return sortTokensByPopularity(t1, t2);

  // 4. Sort alphabetically
  return t1.name.localeCompare(t2.name);
};
