import { GridItem, Skeleton } from '@chakra-ui/react';
import { skipToken } from '@tanstack/react-query';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useRef, useState } from 'react';
import { useInterval } from 'react-use';

import { GetImpactFundDistributionRoundSummary } from '@endaoment-frontend/api';
import { TIME_ONE_MINUTE_IN_SECONDS } from '@endaoment-frontend/constants';
import type { FundDistributionRoundSummary, UUID } from '@endaoment-frontend/types';
import { Pill, cardClassNames } from '@endaoment-frontend/ui/shared';
import {
  formatCurrency,
  formatUsdc,
  getImpactRoundStatus,
  getPartsForTimeTillTimestamp,
} from '@endaoment-frontend/utils';

import styles from './DistributionCard.module.scss';

type DistributionCardWithSummaryProps = {
  roundSummary?: FundDistributionRoundSummary;
  isExpanded?: boolean;
  className?: string;
};

const getUsedTime = (
  roundSummary: Pick<FundDistributionRoundSummary, 'roundEndTimestampUtc' | 'roundStartTimestampUtc'> | undefined,
) => {
  const now = Date.now();
  if (!roundSummary) return now;
  return roundSummary.roundStartTimestampUtc > now
    ? roundSummary.roundStartTimestampUtc
    : roundSummary.roundEndTimestampUtc;
};
const DistributionCardWithSummary = ({
  roundSummary,
  isExpanded = false,
  className,
}: DistributionCardWithSummaryProps) => {
  const usedTime = getUsedTime(roundSummary);
  const [timeTo, setTimeTo] = useState(() => getPartsForTimeTillTimestamp(usedTime));
  const hasRoundSummaryRef = useRef(!!roundSummary);
  if (!!roundSummary && !hasRoundSummaryRef.current) {
    hasRoundSummaryRef.current = true;
    setTimeTo(getPartsForTimeTillTimestamp(usedTime));
  }

  useInterval(() => {
    setTimeTo(getPartsForTimeTillTimestamp(usedTime));
  }, TIME_ONE_MINUTE_IN_SECONDS * 1000);

  const [isHovered, setIsHovered] = useState(false);

  const distributionText = (
    <p className={styles['distribution-text']}>
      <b>{formatCurrency(formatUsdc(roundSummary?.totalDistributionUsdc), { compact: true, fraction: 1 })}</b>
      <AnimatePresence>
        {!!(isExpanded || isHovered) && (
          <motion.span
            initial='initial'
            animate='hovered'
            exit='initial'
            layout
            variants={{
              hovered: {
                width: 'auto',
                opacity: 1,
              },
              initial: {
                width: 0,
                opacity: 0,
              },
            }}
            transition={{ ease: 'anticipate' }}>
            across&nbsp;
            <b>
              {(roundSummary?.recipientCount || 0).toLocaleString()}&nbsp;
              {roundSummary?.recipientCount === 1 ? 'Org' : 'Orgs'}
            </b>
          </motion.span>
        )}
      </AnimatePresence>
    </p>
  );

  return (
    <motion.div
      className={clsx(cardClassNames.base, styles['container'], className)}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}>
      <div className={styles['title']}>
        <Skeleton isLoaded={!!roundSummary}>
          <h4>Next Distribution</h4>
        </Skeleton>
        {roundSummary ? (
          <RoundStatus roundSummary={roundSummary} full={false} />
        ) : (
          <Skeleton h='1.25rem' w='3rem' borderRadius='1rem' />
        )}
      </div>
      <div className={styles['always-visible']} data-expanded={isExpanded}>
        <Skeleton isLoaded={!!roundSummary}>{distributionText}</Skeleton>
        <Skeleton isLoaded={!!roundSummary}>
          <p title={`${timeTo.days} days ${timeTo.hours} hrs ${timeTo.minutes} min`} className={styles['time-text']}>
            <b>{timeTo.days}</b>&nbsp;days&nbsp;
            <b>{timeTo.hours}</b>&nbsp;hrs&nbsp;
            <b>{timeTo.minutes}</b>&nbsp;min
          </p>
        </Skeleton>
      </div>
    </motion.div>
  );
};

export const RoundStatus = ({
  roundSummary,
  full = true,
  className,
}: {
  roundSummary?: Pick<
    FundDistributionRoundSummary,
    'distributedTimestampUtc' | 'roundEndTimestampUtc' | 'roundStartTimestampUtc'
  >;
  full?: boolean;
  className?: string;
}) => {
  if (!roundSummary) return <></>;

  const status = getImpactRoundStatus(roundSummary);

  return (
    <GridItem area='status' className={clsx(styles['status'], className)}>
      {!!full && <p>Round Status</p>}
      <Pill
        data-status={status}
        className={clsx(styles['status-pill'], styles[`status-pill--${status.split(' ')[0].toLowerCase()}`])}>
        {status.toUpperCase()}
      </Pill>
    </GridItem>
  );
};

type DistributionCardProps = { fundId: UUID | undefined; isExpanded?: boolean; className?: string };

export const DistributionCard = ({ fundId, isExpanded, className }: DistributionCardProps) => {
  const { data: roundSummary } = GetImpactFundDistributionRoundSummary.useQuery(
    fundId ? [fundId, 'current'] : skipToken,
    {
      enabled: !!fundId,
    },
  );

  return <DistributionCardWithSummary roundSummary={roundSummary} isExpanded={isExpanded} className={className} />;
};
