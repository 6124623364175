import { useMemo } from 'react';
import { useChainId } from 'wagmi';

import type { OrgDetails } from '@endaoment-frontend/types';

export const getOrgDeploymentForChain = (deployments: OrgDetails['deployments'], chainId: number) => {
  return deployments.find(d => d.chainId === chainId);
};

export const isOrgDeployedOnChain = (deployments: OrgDetails['deployments'], chainId: number) => {
  return !!getOrgDeploymentForChain(deployments, chainId);
};

export const useCurrentChainOrgDeployment = (deployments?: OrgDetails['deployments']) => {
  const currentChainId = useChainId();

  return useMemo(() => {
    if (!deployments) return { currentDeployment: undefined, error: 'No deployments found' };

    return { currentDeployment: getOrgDeploymentForChain(deployments, currentChainId), error: undefined };
  }, [deployments, currentChainId]);
};
