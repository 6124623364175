import { Fade } from '@chakra-ui/react';
import { Fragment } from 'react';

import type { FundListing, OrgListing } from '@endaoment-frontend/types';
import { ArrowIcon } from '@endaoment-frontend/ui/icons';
import { Loader } from '@endaoment-frontend/ui/shared';
import { MiniFundDetails, MiniOrgDetails } from '@endaoment-frontend/ui/smart';

import styles from './SearchResults.module.scss';

export type SearchSelection = { type: 'fund'; fund: FundListing } | { type: 'none' } | { type: 'org'; org: OrgListing };

type SearchResultsProps = {
  data?: {
    orgs: Array<OrgListing>;
    funds: Array<FundListing>;
  };
  mode: 'fund' | 'org';
  isPending?: boolean;
  onSelect: (selection: SearchSelection) => void;
};

export const SearchResults = ({ data, mode, isPending = false, onSelect }: SearchResultsProps) => {
  if (isPending) return <Loader size='l' className={styles['loader']} />;

  const noResultsUi = (
    <span onClick={() => onSelect({ type: 'none' })} className={styles['no-results']}>
      No Results found, try Enhanced Search <ArrowIcon />
    </span>
  );
  if (!data) return noResultsUi;

  return (
    <>
      <Fade in={mode === 'org'} unmountOnExit delay={0.2}>
        {data.orgs.length === 0 && noResultsUi}
        {data.orgs.map((org, i) => {
          const identifier = org.ein ?? org.id;
          return (
            <Fragment key={identifier}>
              <MiniOrgDetails
                className={styles['search-result']}
                onClick={() => onSelect({ type: 'org', org })}
                org={org}
                extraDetails
              />
              {i !== data.orgs.length - 1 && <hr className={styles['separator']} key={`separator-org-${i}`} />}
            </Fragment>
          );
        })}
      </Fade>
      <Fade in={mode === 'fund'} unmountOnExit delay={0.2}>
        {data.funds.length === 0 && <div className={styles['no-results']}>No Results</div>}
        {data.funds.map((fund, i) => (
          <Fragment key={fund.id}>
            <MiniFundDetails
              className={styles['search-result']}
              onClick={() => onSelect({ type: 'fund', fund })}
              fund={fund}
              extraDetails
            />
            {i !== data.funds.length - 1 && <hr className={styles['separator']} key={`separator-fund-${i}`} />}
          </Fragment>
        ))}
      </Fade>
    </>
  );
};
