import { match, P } from 'ts-pattern';

import { isAdministrativeAccount } from '@endaoment-frontend/authentication';
import { CardIcon, StockIcon } from '@endaoment-frontend/ui/icons';
import { capitalize, formatCurrency, formatUsdc } from '@endaoment-frontend/utils';

import { AddressPill, EntityLabelPill, MoneyPill, TokenAmountPill } from '../common/ActivityPills';
import type { ActivitySubject, DonationPledgeActivity } from '../types';

import { ActivityDescription } from './ActivityDescription';
import styles from './DonationPledgeActivity.module.scss';

export const DonationPledgeActivityDescription = ({
  activity,
  subject,
}: {
  activity: DonationPledgeActivity;
  subject?: ActivitySubject;
}) => {
  const { transactor, to, chainId, type } = activity;
  const shouldShowTransactor: boolean = !!transactor && !isAdministrativeAccount(transactor, 'accountant') && !!chainId;

  const conjugation = match(activity.outcome)
    .returnType<string>()
    .with('Success', () => 'ed')
    .with('Failure', () => 'e')
    .otherwise(() => 'ing');
  const actionVerb = match({ activity, subject })
    .with({ activity: { type: 'migration_donation_pledge' } }, () => `migrat${conjugation}`)
    .with({ subject: P.not('user') }, () => `receiv${conjugation}`)
    .otherwise(() => `donat${conjugation}`);
  const action = activity.outcome === 'Failure' ? `failed to ${actionVerb}` : `${actionVerb}`;
  const viaText = match(type)
    .with('fiat_donation_pledge', () => (
      <>
        {'via '}
        <b className={styles['donation-type']} data-donation-type='cash'>
          <CardIcon />
          Cash Donation
        </b>
      </>
    ))
    .with('stock_donation_pledge', () => (
      <>
        {'via '}
        <b className={styles['donation-type']} data-donation-type='stock'>
          <StockIcon />
          Brokerage
        </b>
      </>
    ))
    .otherwise(() => <></>);

  return (
    <ActivityDescription>
      {match({ activity, subject, shouldShowTransactor })
        .with(
          {
            subject: P.not('user'),
            shouldShowTransactor: true,
            activity: {
              transactor: P.not(P.nullish),
              chainId: P.not(P.nullish),
            },
          },
          ({ activity }) => (
            <>
              <AddressPill transactor={activity.transactor} chainId={activity.chainId} />
              <em>{action}</em>
            </>
          ),
        )
        .otherwise(() => (
          <em>{capitalize(action)}</em>
        ))}
      {type === 'nec_donation_pledge' && <TokenAmountPill token={activity.token} amount={activity.amount} />}

      {/* TODO: Different activities are getting different logic for usdcAmount/net amounts/fee amounts - these should follow a standard - coordinate w/ BE  */}
      {!!activity.usdcAmount && <MoneyPill amount={formatCurrency(formatUsdc(activity.usdcAmount))} />}

      {viaText}
      {(subject === 'featured' || subject === 'user') && (
        <>
          <span>to</span>
          <EntityLabelPill entityLabel={to} />
        </>
      )}
    </ActivityDescription>
  );
};
