import clsx from 'clsx';

import { ENDAOMENT_EIN } from '@endaoment-frontend/constants';
import { routes } from '@endaoment-frontend/routes';
import type { FundListing } from '@endaoment-frontend/types';
import { CopyIcon } from '@endaoment-frontend/ui/icons';
import { Button, Card, CopyTooltip, Loader, Pill } from '@endaoment-frontend/ui/shared';
import { formatCurrency, formatUsdc } from '@endaoment-frontend/utils';

import styles from './MigrateFund.module.scss';

export const MigrateFundInstructions = ({
  fund,
  amount,
  processing = false,
  onInitiate,
}: {
  fund: FundListing;
  amount: bigint;
  processing?: boolean;
  onInitiate?: () => void;
}) => {
  const instruction = `For further credit to ${fund.name} advised by ${fund.advisor.firstName} ${fund.advisor.lastName} (ID: ${fund.id}).`;

  return (
    <div className={styles['migration-instructions']}>
      <div>
        <h4 className={styles['label']}>Step 1</h4>
        <p>
          From your external DAF begin a <b>{formatCurrency(formatUsdc(amount))}</b> grant to:
        </p>
        <Card className={clsx(styles['business-card'], styles['copy-card'])}>
          <div>
            <h4>Endaoment</h4>
            <span>
              EIN: <b>{ENDAOMENT_EIN}</b>
              <CopyTooltip displayText='Copy Endaoment EIN' copyText={ENDAOMENT_EIN}>
                <CopyIcon />
              </CopyTooltip>
            </span>
          </div>
          <p>
            40 Bernal Heights Blvd
            <br />
            San Francisco, CA 94110
          </p>
        </Card>
      </div>

      <div>
        <h4 className={styles['label']}>Step 2</h4>
        <p>
          Include the following context in the <b>special instructions</b>:
        </p>
        <Card className={styles['copy-card']}>
          <span>{instruction}</span>
          <CopyTooltip displayText='Copy Special Instruction' copyText={instruction}>
            <CopyIcon />
          </CopyTooltip>
        </Card>
      </div>

      <div>
        <h4 className={styles['label']}>Step 3</h4>
        <p>Recommend your grant.</p>
        <Pill className={styles['notice']}>
          <span>
            {'Should your current DAF provider require more information, direct them '}
            <a href={routes.docs.aboutDafMigrations()} target='_blank' rel='noreferrer'>
              here
            </a>
            .
          </span>
        </Pill>
      </div>

      {!!onInitiate && (
        <>
          <Button
            onClick={onInitiate}
            disabled={processing}
            variation='purple'
            filled
            className={styles['initiate-button']}>
            {processing ? (
              <>
                Processing <Loader />
              </>
            ) : (
              'Initiate Migration'
            )}
          </Button>

          <p className={styles['notice']}>
            By initiating this grant you agree to Endaoment’s{' '}
            <a href={routes.docs.privacyPolicy()} target='_blank' rel='noreferrer'>
              privacy policy
            </a>
            {' and '}
            <a href={routes.docs.termsAndConditions()} target='_blank' rel='noreferrer'>
              terms and conditions
            </a>
          </p>
        </>
      )}
    </div>
  );
};
