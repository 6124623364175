import { Fireworks as FireworksObject } from 'fireworks-js';
import { atom, useAtom } from 'jotai';
import { useCallback, useRef } from 'react';
import { useMount } from 'react-use';

import { useIsMounted } from '@endaoment-frontend/hooks';

import styles from './ParticlesProvider.module.scss';

type ParticlesConfig = {
  isShowing: boolean;
};

const particlesConfigAtom = atom<ParticlesConfig>({ isShowing: false });

export const ParticlesProvider = () => {
  const innerRef = useRef<HTMLCanvasElement>(null);
  const objRef = useRef<FireworksObject>();

  const [config] = useAtom(particlesConfigAtom);

  const isMounted = useIsMounted();

  const lastConfig = useRef(config);
  if (lastConfig.current !== config) {
    if (objRef.current) {
      if (config.isShowing) {
        objRef.current.updateOptions({});
        objRef.current.start();
      } else {
        void objRef.current.waitStop();
      }
    }
    lastConfig.current = config;
  }

  useMount(() => {
    if (!objRef.current) {
      if (!innerRef.current) return;
      objRef.current = new FireworksObject(innerRef.current, {
        // Add additional options
      });
    }
    return () => {
      if (!objRef.current) return;
      objRef.current.stop();
    };
  });

  if (!isMounted) return <></>;

  return (
    <canvas
      ref={innerRef}
      style={{
        zIndex: 9999,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        position: 'fixed',
        pointerEvents: 'none',
      }}
      className={styles['particles-canvas']}
    />
  );
};

export const useShowFireworks = () => {
  const [, setConfig] = useAtom(particlesConfigAtom);
  const showFireworks = useCallback(
    (timeMs = 3000) => {
      setConfig({ isShowing: true });
      setTimeout(() => setConfig({ isShowing: false }), timeMs);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return showFireworks;
};
