import { Text } from '@chakra-ui/react';
import Link from 'next/link';
import { useCallback, useEffect, useRef, useState } from 'react';
import SignaturePad from 'signature_pad';

import { ProceedButton } from '@endaoment-frontend/ui/forms';
import { Pill } from '@endaoment-frontend/ui/shared';

import styles from './StockDonationSignature.module.scss';

export const StockDonationSignature = ({
  initialSignature = '',
  onSubmit,
}: {
  initialSignature?: string;
  onSubmit: (signature: string) => void;
}) => {
  const [signature, setSignature] = useState(initialSignature);

  return (
    <>
      <Pill variation='orange' className={styles.disclaimer}>
        {`By signing my donation request electronically I hereby consent to contracting electronically and agree that such
        signature will be legally equivalent to a manual paper signature. I hereby consent to having the donation
        request information I provided through this process compiled together with this signature into the
        broker-specific required forms. I acknowledge that I will later receive a record of this form and have the
        ability to maintain my own records of the same, whether in digital or hard-copy form. If you have any questions
        please reach out to `}
        <Link href='mailto:admin@endaoment.org' target='_blank' rel='noreferrer'>
          admin@endaoment.org
        </Link>
      </Pill>
      <Text as='h6' textAlign='center' margin={4} mt={6}>
        Please provide your signature
      </Text>
      <SignatureInput value={signature} onChange={setSignature} />
      <Text textAlign='center' className={styles['signature-label']}>
        You can draw in the above box with your mouse or finger.
      </Text>
      <ProceedButton
        onClick={() => {
          if (!signature) return;
          onSubmit(signature);
        }}
      />
    </>
  );
};

const SignatureInput = ({ value, onChange }: { value: string; onChange: (sig: string) => void }) => {
  const [sigPad, setSigPad] = useState<SignaturePad>();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const handleChange = useCallback(() => {
    if (!sigPad) return;

    onChange(sigPad.isEmpty() ? '' : sigPad.toDataURL());
  }, [sigPad, onChange]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas === null) return;

    const newSigPad = new SignaturePad(canvas);
    // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect
    void setSigPad(newSigPad);
    newSigPad.on();

    /** When zoomed out to less than 100%, for some very strange reason,
     *  some browsers report devicePixelRatio as less than 1
     *  and only part of the canvas is cleared then.
     */
    const ratio = Math.max(window.devicePixelRatio ?? 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    canvas.getContext('2d')!.scale(ratio, ratio);
    newSigPad.clear();

    return () => {
      newSigPad.off();
    };
  }, []);

  useEffect(() => {
    if (!sigPad) return;

    sigPad.clear();
    // No need to redraw if the value is empty
    if (!value) return;

    // Redraw according to current value
    void sigPad.fromDataURL(value);
  }, [sigPad, value]);

  useEffect(() => {
    if (!sigPad) return;

    sigPad.addEventListener('endStroke', handleChange, { passive: true });

    return () => {
      sigPad.removeEventListener('endStroke', handleChange);
    };
  }, [sigPad, handleChange]);

  // WARNING: Do not attempt to alter the width/height of the canvas or add a wrapper div
  // This will break the signature pad
  return (
    <div className={styles['signature-input']}>
      <canvas ref={canvasRef} data-testid='signature-canvas' />
      <button
        type='button'
        className={styles['signature-clear-button']}
        onClick={() => {
          if (!sigPad) return;
          sigPad.clear();
          handleChange();
        }}>
        Clear
      </button>
    </div>
  );
};
