import { useMemo } from 'react';

import type { UUID } from '@endaoment-frontend/types';
import { uuidSchema } from '@endaoment-frontend/types';

const makeIdempotencyKey = (): UUID => {
  return uuidSchema.parse(crypto.randomUUID());
};

export const useIdempotencyKey = () => {
  return useMemo(makeIdempotencyKey, []);
};
