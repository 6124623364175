import { Form, Formik } from 'formik';

import { GetUserIdentity } from '@endaoment-frontend/api';
import { useAuth } from '@endaoment-frontend/authentication';
import { useIsMounted } from '@endaoment-frontend/hooks';
import type { CreateFundAdvisorInput, PhysicalAddress } from '@endaoment-frontend/types';
import { createFundAdvisorInputSchema } from '@endaoment-frontend/types';
import {
  CountryFormInput,
  FormInput,
  ProceedButton,
  StateFormInput,
  ZipFormInput,
  validateWithZod,
} from '@endaoment-frontend/ui/forms';
import { Loader } from '@endaoment-frontend/ui/shared';

import wizardStlyles from '../FundWizard.module.scss';

import styles from './CreateFund.module.scss';

export const CreateFundAdvisor = ({
  createButtonText,
  isCreating = false,
  onSubmit,
}: {
  createButtonText?: string;
  isCreating?: boolean;
  onSubmit: (advisor: CreateFundAdvisorInput) => void;
}) => {
  const { isSignedIn } = useAuth();
  const isMounted = useIsMounted();

  const { data: userInfo, isPending: isPendingIdentity } = GetUserIdentity.useQuery([], {
    staleTime: Infinity,
    enabled: isSignedIn,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const initialValues: CreateFundAdvisorInput = {
    firstName: '',
    lastName: '',
    email: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip: '',
      country: 'USA',
    } as PhysicalAddress,
    ...userInfo,
  };

  const isPendingForm = (isSignedIn && isPendingIdentity) || !isMounted;

  return (
    <div>
      <h4 className={styles['step-header']}>Tell us about yourself</h4>
      {isPendingForm ? (
        <Loader />
      ) : (
        <Formik
          validate={validateWithZod(createFundAdvisorInputSchema)}
          validateOnBlur
          initialValues={initialValues}
          onSubmit={onSubmit}>
          <Form className={styles['advisor-form']}>
            <div className={styles['split-row']}>
              <FormInput name='firstName' label='First Name' placeholder='Your first name' />
              <FormInput name='lastName' label='Last Name' placeholder='Your last name' />
            </div>

            <hr />

            <FormInput name='email' label='Email' placeholder='you@email.com' />
            <FormInput name='address.line1' label='Address' placeholder='Your address' />
            <FormInput name='address.line2' label='Address Line 2' placeholder='' />
            <CountryFormInput countryFieldName='address.country' stateFieldName='address.state' />

            <div className={styles['split-row']}>
              <StateFormInput name='address.state' countryFieldName='address.country' />
              <ZipFormInput name='address.zip' countryFieldName='address.country' />
            </div>
            <FormInput name='address.city' label='City' placeholder='Your city' />

            <ProceedButton
              type='submit'
              disabled={isCreating}
              text={isCreating ? 'Creating Fund...' : createButtonText}
              className={wizardStlyles['floating-submit']}
            />
          </Form>
        </Formik>
      )}
    </div>
  );
};
