import { useEffect, useState } from 'react';

export const useScrollCallback = (callback: (y: number) => void) => {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const wrappedCallback = () => {
      callback(window.scrollY);
    };

    window.addEventListener('scroll', wrappedCallback, { passive: true });
    return () => {
      window.removeEventListener('scroll', wrappedCallback);
    };
  }, [callback]);
};

export enum ScrollDirection {
  Up = 'up',
  Down = 'down',
}

export const useScroll = (minMove = 0) => {
  const [lastY, setLastY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(ScrollDirection.Down);

  useScrollCallback(currY => {
    const yDiff = currY - lastY;

    if (Math.abs(yDiff) > minMove) {
      setScrollDirection(yDiff > 0 ? ScrollDirection.Down : ScrollDirection.Up);
      setLastY(currY);
    }
  });

  return {
    scrollDirection,
    y: lastY,
  };
};
