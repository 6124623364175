import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import { AsyncSelect, components as selectComponents, type ValueContainerProps } from 'chakra-react-select';
import clsx from 'clsx';
import { FieldArray, Form, Formik, useField } from 'formik';
import type { ComponentProps } from 'react';
import { z } from 'zod';

import { GetStockTickerPrice, GetStockTickers } from '@endaoment-frontend/api';
import { useIsMobile } from '@endaoment-frontend/hooks';
import type { StockLot, StockTicker } from '@endaoment-frontend/types';
import { employeeStockPlanSchema, stockTickerSchema } from '@endaoment-frontend/types';
import { ErrorDisplay, FormInput, FormSelect, ProceedButton, validateWithZod } from '@endaoment-frontend/ui/forms';
import { PlusIcon, SearchIcon } from '@endaoment-frontend/ui/icons';
import { Button } from '@endaoment-frontend/ui/shared';
import { formatCurrency } from '@endaoment-frontend/utils';

import styles from './StockDonation.module.scss';

const TODAY = new Date().toISOString().split('T')[0];
const EMPLOYEE_STOCK_PLAN_OPTIONS: ComponentProps<typeof FormSelect>['options'] = [
  { display: 'None', value: 'None' },
  ...employeeStockPlanSchema.options.map(option => ({
    display: option === 'ESPP' ? 'Employee Purchase Plan' : option,
    value: option,
  })),
];
const MIN_DONATION_AMOUNT = 500;

const validateStockDetailsForm = async (values: { ticker: StockTicker; shares: number; lots: Array<StockLot> }) => {
  let tickerPrice = 0;

  if (values.ticker) {
    // Needed to validate that the total stock value being donated is greater than MIN_DONATION_AMOUNT
    tickerPrice = await GetStockTickerPrice.fetchFromDefaultClient([values.ticker.ticker]);
  }

  const stockDetailsFormSchema = z.object({
    ticker: stockTickerSchema,
    shares: z
      .number({ coerce: true })
      .int({ message: 'Shares must be a whole number' })
      .refine(v => v * tickerPrice >= MIN_DONATION_AMOUNT, {
        message: `Total donation must be greater than $${MIN_DONATION_AMOUNT}`,
      })
      .and(z.number({ coerce: true }).gt(0, { message: 'Shares must be greater than 0' })),
    lots: z.array(
      z.object({
        numberOfShares: z
          .number({ coerce: true })
          .int({
            message: 'Shares must be a whole number',
          })
          .gt(0, { message: 'Shares must be greater than 0' }),
        purchasePrice: z.number({ coerce: true }).gt(0, { message: 'Price must be greater than 0' }),
        purchaseDate: z
          .string()
          .date()
          .refine(
            v => {
              const date = new Date(v);
              return date < new Date() && date >= new Date('1900-01-01');
            },
            { message: 'Inform a valid date' },
          ),
        lotId: z
          .string()
          .regex(/^[a-z\d]*$/i, { message: 'Invalid Lot ID' })
          .optional(),
        employeeStockPlan: employeeStockPlanSchema
          .or(z.literal('None'))
          // TODO: Not sure why `transform` isn't working as expected -- `None` is not being transformed to `undefined`
          // .transform(v => (v === 'None' ? undefined : v))
          .optional(),
      }),
    ),
  });

  return validateWithZod(stockDetailsFormSchema)(values);
};

export const StockDonationDetails = ({
  ticker,
  shares,
  lots,
  onSubmit,
}: {
  ticker?: StockTicker;
  shares?: number;
  lots: Array<StockLot>;
  onSubmit: (values: { ticker: StockTicker; shares: number; lots: Array<StockLot> }) => void;
}) => {
  const { isMobile } = useIsMobile({ defaultState: true });
  return (
    <Formik
      initialValues={{ ticker: ticker ?? (undefined as unknown as StockTicker), shares: shares ?? 0, lots }}
      validate={validateStockDetailsForm}
      onSubmit={values => {
        const v = {
          ...values,
          lots: values.lots.map(lot => ({
            ...lot,
            employeeStockPlan:
              (lot.employeeStockPlan as typeof lot.employeeStockPlan | 'None') === 'None'
                ? undefined
                : lot.employeeStockPlan,
          })),
        };

        onSubmit(v);
      }}>
      {({ values, handleSubmit, setValues }) => {
        const hasLots = values.lots.length > 0;

        if (hasLots) {
          // If there are lots, we need to set the shares to the sum of all lots
          const totalShares = values.lots.reduce((acc, lot) => acc + lot.numberOfShares, 0);
          if (values.shares !== totalShares) void setValues({ ...values, shares: totalShares });
        }

        return (
          <Form className={clsx(styles['form'], styles['form--extra-bottom-space'])}>
            <Flex
              flexDir={['column', 'row']}
              alignItems={['stretch', 'flex-start']}
              gap='1rem'
              width='100%'
              paddingX='1px'>
              <VStack flex='0 0 50%' alignItems='flex-start' gap={0}>
                <label htmlFor='ticker' className={styles['label']}>
                  Stock Ticker
                </label>
                <StockTickerSearch name='ticker' autoFocus={!isMobile} />
              </VStack>

              <VStack flex='0 0 calc(50% - 0.5rem)' alignItems='stretch' gap={0}>
                <label htmlFor='shares' className={styles['label']}>
                  {hasLots ? 'Sum of Lot Shares' : 'Number of Shares'}
                </label>
                <FormInput
                  name='shares'
                  type='number'
                  min='0'
                  disabled={!values.ticker || hasLots}
                  className={styles['share-input']}
                  step={1}
                />
                <SelectedStockPriceDisplay ticker={values.ticker} shares={values.shares} />
              </VStack>
            </Flex>

            <FieldArray name='lots' validateOnChange>
              {(
                // eslint-disable-next-line @typescript-eslint/unbound-method
                { push, remove, name },
              ) => (
                <>
                  {(values.lots || []).map((_, index) => (
                    <StockLotForm key={index} name={name} index={index} onRemove={remove} />
                  ))}

                  <Button
                    onClick={() => push({ numberOfShares: '', purchasePrice: '', purchaseDate: TODAY })}
                    className={styles['lot__add']}>
                    {hasLots ? 'Add Another Lot' : 'Add Specific Stock Lots'}
                    <PlusIcon />
                  </Button>
                </>
              )}
            </FieldArray>

            <ProceedButton type='submit' onClick={handleSubmit} />
          </Form>
        );
      }}
    </Formik>
  );
};

const StockLotForm = ({
  name,
  index,
  onRemove,
}: {
  name: string;
  index: number;
  onRemove: (index: number) => void;
}) => (
  <VStack className={styles['lot']} alignItems='stretch' data-testid='stock-lot'>
    <HStack alignItems='center' justifyContent='space-between' className={styles['lot__header']}>
      <span>Lot #{index + 1}</span>
      <Button onClick={() => onRemove(index)} size='tiny' variation='faded' float={false}>
        Remove Lot
      </Button>
    </HStack>
    <Flex flexDir={['column', 'row']} alignItems={['stretch', 'flex-start']} className={styles['lot__line']}>
      <FormInput name={`${name}.${index}.purchaseDate`} label='Purchase Date' type='date' max={TODAY} required />
      <FormInput
        name={`${name}.${index}.numberOfShares`}
        label='Number of Shares'
        type='number'
        min='0'
        required
        placeholder='Shares'
      />
    </Flex>
    <Flex flexDir={['column', 'row']} alignItems={['stretch', 'flex-start']} className={styles['lot__line']}>
      <FormInput
        name={`${name}.${index}.purchasePrice`}
        label='Purchase Price'
        type='number'
        required
        min='0'
        leftElements='$'
        placeholder='Price'
      />
      <FormInput name={`${name}.${index}.lotId`} type='text' pattern='[a-z\d]*' label='Lot ID' placeholder='Optional' />
    </Flex>
    <FormSelect
      name={`${name}.${index}.employeeStockPlan`}
      label='Employee Stock Plan'
      options={EMPLOYEE_STOCK_PLAN_OPTIONS}
      required
    />
  </VStack>
);

const StockTickerSearchValueContainer = ({ children, ...props }: ValueContainerProps<StockTicker>) => {
  const value = props.getValue();
  return (
    <>
      {value.length === 0 && <SearchIcon className={styles['search-icon']} />}
      <selectComponents.ValueContainer {...props}>{children}</selectComponents.ValueContainer>
    </>
  );
};

const StockTickerSearch = ({ name, autoFocus }: { name: string; autoFocus?: boolean }) => {
  const [{ value }, { error }, { setValue, setTouched }] = useField<StockTicker | undefined>(name);
  const { isMobile } = useIsMobile();

  const loadOptions = async (search: string) => GetStockTickers.fetchFromDefaultClient([search]);

  return (
    <>
      <AsyncSelect
        value={value}
        onChange={v => {
          void setTouched(true);
          void setValue(v ? v : undefined, true);
        }}
        isSearchable
        defaultOptions
        backspaceRemovesValue
        blurInputOnSelect
        isMulti={false}
        isClearable
        isRequired={false}
        components={{
          ClearIndicator: () => null,
          DropdownIndicator: () => null,
          LoadingIndicator: () => null,
          IndicatorsContainer: () => null,
          ValueContainer: StockTickerSearchValueContainer,
        }}
        classNames={{
          placeholder: () => styles['search-placeholder'],
          control: () => styles['search-control'],
          valueContainer: () => styles['search-value-container'],
          menuPortal: () => styles['search-menu-portal'],
        }}
        placeholder='Ticker Search'
        loadOptions={loadOptions}
        formatOptionLabel={option => (
          <Box className={styles['ticker-option']}>
            <span className={styles['ticker']}>{option.ticker}</span>
            <span>{option.name}</span>
          </Box>
        )}
        selectedOptionColorScheme='green'
        menuPortalTarget={document.body}
        menuPlacement={isMobile ? 'top' : 'auto'}
        isOptionSelected={(option, value) => option.ticker === value[0]?.ticker}
        className={styles['stock-search']}
        autoFocus={autoFocus}
      />
      <ErrorDisplay error={error} />
    </>
  );
};

const SelectedStockPriceDisplay = ({ ticker, shares }: { ticker?: StockTicker; shares: number }) => {
  const { data: currentTickerPrice } = GetStockTickerPrice.useQuery([ticker?.ticker], {
    enabled: !!ticker,
  });

  const displayValue =
    currentTickerPrice && currentTickerPrice * shares > 0 ? formatCurrency(currentTickerPrice * shares) : '';

  return (
    <div className={styles['price-estimation']}>
      {!!currentTickerPrice && `Share Price: ≈ ${formatCurrency(currentTickerPrice)}`}
      {!!displayValue && (
        <>
          <br />
          Total: ≈ {displayValue}
        </>
      )}
    </div>
  );
};
