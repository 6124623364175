import { getAccessToken, useModalStatus, usePrivy } from '@privy-io/react-auth';

import { WhoAmI } from '@endaoment-frontend/api';

import { useAuth } from './useAuth';
import { useSignOut } from './useSignOut';

export const useWalletModal = () => {
  const { isSignedIn, isSignedInOnNdao, isSignedInOnPrivy } = useAuth();
  const { login } = usePrivy();
  const signOut = useSignOut();
  const { isOpen } = useModalStatus();
  const showWallet = async (shouldDisconnectIfSignedIn = false) => {
    if (shouldDisconnectIfSignedIn && isSignedIn) {
      await signOut();
      return;
    }

    console.info(`Opening wallet modal with user signed ${isSignedIn ? 'in' : 'out'}. `, {
      isSignedInOnPrivy,
      isSignedInOnNdao,
    });

    if (!isSignedIn) {
      if (isSignedInOnPrivy) {
        console.info('Attempting to sync Privy to NDAO');
        const user = await WhoAmI.executeAndSave([]);
        if (!user) {
          console.error('Failed to sync Privy to NDAO. Signing out.');
          await signOut();
        }
      } else if (isSignedInOnNdao) {
        const privyAccessToken = await getAccessToken();
        if (!privyAccessToken) {
          console.error('Failed to sync NDAO to Privy. Signing out.');
          await signOut();
        }
      }
    }

    login();
  };

  return {
    showWallet,
    isWalletModalOpen: isOpen,
  } as const;
};
