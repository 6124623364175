import clsx from 'clsx';
import { useState } from 'react';

import type { FundListing } from '@endaoment-frontend/types';
import { ProceedButton, Input } from '@endaoment-frontend/ui/forms';
import { Pill } from '@endaoment-frontend/ui/shared';
import { MiniFundDetails } from '@endaoment-frontend/ui/smart';

import styles from './MigrateFund.module.scss';

export const MigrateFundDestination = ({
  funds = [],
  onSelect,
  onCreate,
}: {
  funds?: Array<FundListing>;
  onSelect: (fund: FundListing) => void;
  onCreate: () => void;
}) => {
  const [filter, setFilter] = useState('');

  const hasFunds = funds.length > 0;
  const filteredFunds = funds.filter(fund => fund.name.toLowerCase().includes(filter));
  return (
    <>
      <h4 className={styles['step-header']}>Which fund do you want to migrate to?</h4>
      {!hasFunds ? (
        <Pill variation='orange' className={clsx(styles['empty'], styles['notice'])}>
          You don't have an Endaoment fund yet!
          <br />
          Create a new fund to begin a migration.
        </Pill>
      ) : (
        <>
          {funds.length > 3 && (
            <Input
              value={filter}
              onChange={e => setFilter(e.target.value.toLowerCase())}
              placeholder='Filter funds...'
              className={styles['filter']}
            />
          )}
          <div className={styles['fund-list']}>
            {filteredFunds.map(fund => (
              <MiniFundDetails
                key={fund.id}
                fund={fund}
                onClick={() => onSelect(fund)}
                className={styles['fund-option']}
              />
            ))}
          </div>
          {filteredFunds.length === 0 && <p className={styles['empty']}>No funds match your filter</p>}
        </>
      )}

      <div className={styles['proceed']}>
        {hasFunds && filteredFunds.length > 0 ? 'or' : ''}
        <ProceedButton text='Start a New Fund' onClick={onCreate} />
      </div>
    </>
  );
};
