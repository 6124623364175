import clsx from 'clsx';

import { GetFund } from '@endaoment-frontend/api';
import type { UUID } from '@endaoment-frontend/types';
import { cardClassNames } from '@endaoment-frontend/ui/shared';
import { FundAllocationBar, MiniFundDetailsWithQuery } from '@endaoment-frontend/ui/smart';

import wizardStyles from '../PortfolioWizard.module.scss';

export const FundDetailsCardWithBar = ({
  fundId,
  selectable,
  onClick,
}: {
  fundId: UUID;
  selectable?: boolean;
  onClick?: () => void;
}) => {
  const { data: fund } = GetFund.useQuery([fundId]);
  const invested = fund ? fund.investedUsdc : 0n;

  const Component = onClick ? 'button' : 'div';

  return (
    <Component
      onClick={onClick}
      className={clsx(
        cardClassNames.base,
        cardClassNames.removePadding,
        cardClassNames.removeShadow,
        wizardStyles['extended-card'],
        selectable && wizardStyles['selectable'],
      )}>
      <MiniFundDetailsWithQuery fundId={fundId} extraDetails showBalance />
      {fund && invested > 0n ? <FundAllocationBar fund={fund} fundId={fund.id} isAccurate /> : <></>}
    </Component>
  );
};
