import { setUser } from '@sentry/nextjs';
import { z } from 'zod';

import { defaultQueryClient, RequestHandler } from '@endaoment-frontend/data-fetching';
import type { LoginDTO } from '@endaoment-frontend/types';
import { addressSchema, uuidSchema } from '@endaoment-frontend/types';

/**
 * Signs in a user with the provided credentials.
 * If an admin is signing in, the signature and signatureDateUtc fields must be provided.
 * If a social login is being used, the smartAccountAddress
 */
export const SignIn = new RequestHandler(
  'SignIn',
  fetch =>
    async (body: LoginDTO): Promise<boolean> => {
      try {
        await fetch('/v2/auth/signin', {
          method: 'POST',
          body: body satisfies LoginDTO,
        });

        if (body.type === 'social') {
          setUser({ username: body.smartAccountAddress });
        } else {
          setUser({ username: body.activeWalletAddress });
        }
        return true;
      } catch (e) {
        console.warn(e);
        return false;
      }
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v2/auth/signin` }),
  },
);

export const SignOut = new RequestHandler(
  'SignOut',
  fetch => async (): Promise<boolean> => {
    try {
      await fetch('/v1/auth/signout', {
        method: 'POST',
      });
      setUser(null);
      WhoAmI.setData(defaultQueryClient, [], () => null);
      return true;
    } catch (e) {
      console.warn(e);
      return false;
    }
  },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/auth/signout` }),
  },
);

const authInfoSchema = z.object({
  userId: uuidSchema,
  wallet: addressSchema,
  iat: z.number(),
  exp: z.number(),
  hasInformedReferralSource: z.boolean(),
});
export type AuthInfo = z.infer<typeof authInfoSchema>;
export const WhoAmI = new RequestHandler(
  'WhoAmI',
  fetch => async (): Promise<AuthInfo | null> => {
    try {
      const res = await fetch(`/v1/auth/whoami`, {
        cache: 'no-cache',
      });
      return authInfoSchema.parse(res);
    } catch {
      return null;
    }
  },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/auth/whoami` }),
  },
);
