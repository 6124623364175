import { RequestHandler } from '@endaoment-frontend/data-fetching';
import type { UUID } from '@endaoment-frontend/types';
import { arraySchemaInvalidsFiltered } from '@endaoment-frontend/types';

import type { Activity } from './types';
import { activitySchema } from './types';

/**
 * Fetch all featured activity
 */
export const GetFeaturedActivity = new RequestHandler(
  'GetFeaturedActivity',
  fetch => async (): Promise<Array<Activity>> => {
    const res = await fetch(`/v1/activity/featured`);
    return arraySchemaInvalidsFiltered(activitySchema).parse(res);
  },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/activity/featured`,
    }),
  },
);

/**
 * Fetch all activity pertaining to the current user
 */
export const GetUserActivity = new RequestHandler(
  'GetUserActivity',
  fetch => async (): Promise<Array<Activity>> => {
    const res = await fetch(`/v1/activity/mine`);
    return arraySchemaInvalidsFiltered(activitySchema).parse(res);
  },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/activity/mine`,
    }),
  },
);

/**
 * Fetch recent activity associated with a Fund
 */
export const GetFundActivity = new RequestHandler(
  'GetFundActivity',
  fetch =>
    async (id: UUID): Promise<Array<Activity>> => {
      const res = await fetch(`/v1/activity/fund/${id}`);
      return arraySchemaInvalidsFiltered(activitySchema).parse(res);
    },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/activity/fund/:id` }),
  },
);

/**
 * Fetch recent activity associated with a Org
 */
export const GetOrgActivity = new RequestHandler(
  'GetOrgActivity',
  fetch =>
    async (id: UUID): Promise<Array<Activity>> => {
      const res = await fetch(`/v1/activity/org/${id}`);
      return arraySchemaInvalidsFiltered(activitySchema).parse(res);
    },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/activity/org/:id` }),
  },
);
