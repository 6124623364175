import { match, P } from 'ts-pattern';
import { useChainId } from 'wagmi';

import { GetFund } from '@endaoment-frontend/api';
import { defaults } from '@endaoment-frontend/config';
import { getTransactionLink } from '@endaoment-frontend/multichain';
import { routes } from '@endaoment-frontend/routes';
import type { DonationRecipient, Grant, GrantInstructionInput, UUID } from '@endaoment-frontend/types';
import { QuestionIcon, RoutingIcon, ShareIcon2 } from '@endaoment-frontend/ui/icons';
import { Button, QuestionPopover } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';
import { formatBasisPointsToPercent, formatCurrency, formatDate, formatUsdc } from '@endaoment-frontend/utils';

import wizardStyles from '../DonationWizard.module.scss';

import { useTransferFee } from './useTransferFee';

export const ViewGrant = ({
  originFundId,
  destination,
  grantInstructions,
  grantAmount = 0n,
  grantResult,
}: {
  originFundId: UUID;
  destination: DonationRecipient;
  grantInstructions?: Omit<GrantInstructionInput, 'shareMyEmail'>;
  grantAmount?: bigint;
  grantResult: Grant;
}) => {
  const currentChainId = useChainId();
  const { data: fund } = GetFund.useQuery([originFundId], { enabled: !!originFundId });
  const { data: grantFeeToOrg } = useTransferFee({
    sender: fund?.v2ContractAddress,
    recipient: destination,
    chainId: fund?.chainId ?? currentChainId,
    transferAmount: grantAmount,
    enabled: !!fund,
  });

  const grantFee = grantResult.fee ?? (destination.type === 'org' ? grantFeeToOrg : 0n);
  const estimatedProceeds = grantResult.netAmount ?? grantAmount - grantFee;

  return (
    <>
      <EntityCardWithLabel
        label='Granting from'
        entity={{ type: 'fund', id: originFundId }}
        fundCardOptions={{ showBalance: false }}
      />
      <EntityCardWithLabel label='Granting to' entity={destination} />
      <hr />
      <div className={wizardStyles['donation-info']}>
        <div>
          <h4>Grant</h4>
          <h4>{formatCurrency(formatUsdc(grantAmount))}</h4>
        </div>
        <div>
          <h4>{grantResult.asyncStatus !== 'Liquidated' && 'Estimated '}Proceeds</h4>
          <h4>{formatCurrency(formatUsdc(estimatedProceeds))}</h4>
        </div>
        <div>
          <QuestionPopover
            content={
              <>
                {`A maximum fee of ${formatBasisPointsToPercent(defaults.fees.transferBps)} is being applied. `}
                <a href={routes.docs.feeSchedule()} target='_blank'>
                  Read More
                </a>
              </>
            }>
            <h6 className={wizardStyles['question-tooltip']}>
              Endaoment Fee
              <QuestionIcon color='currentColor' width={15} height={16} />
            </h6>
          </QuestionPopover>
          <h6>{formatCurrency(formatUsdc(grantFee))}</h6>
        </div>
      </div>
      <hr />
      {!!grantInstructions && (
        <div className={wizardStyles['instruction-info']}>
          <label>Recommender</label>
          <p>{grantInstructions.recommender}</p>
          {grantInstructions.specialInstructions ? (
            <>
              <label>Restricted Grant Instructions</label>
              <p>{grantInstructions.specialInstructions}</p>
            </>
          ) : (
            <>
              <label>Grant Purpose</label>
              <p>{grantInstructions.purpose}</p>
            </>
          )}
        </div>
      )}
      <hr />
      <div className={wizardStyles['transaction-status-section']} data-status={grantResult.asyncStatus}>
        {match(grantResult.asyncStatus)
          .with(P.union('Liquidated', null, undefined), () => (
            <div>
              Grant completed:&nbsp;
              <span>{formatDate(grantResult.updatedAtUtc, { includeTime: true, dateStyle: 'short' })}</span>
            </div>
          ))
          .with('Rejected', () => (
            <div>
              <b>This Grant was rejected, funds have been clawed back to your DAF</b>
            </div>
          ))
          .with('PendingLiquidation', () => (
            <div>
              <div>
                <RoutingIcon />
                Processing...
              </div>
              <p>
                We are in the process of executing your grant, you will receive an email once it is complete. You can
                close the wizard and this grant will process in the background.
              </p>
            </div>
          ))
          .exhaustive()}
        {!!grantResult.transactionHash && (
          <Button
            as='a'
            href={getTransactionLink(grantResult.transactionHash, grantResult.chainId)}
            size='small'
            float={false}
            filled
            target='_blank'>
            View Transaction <ShareIcon2 color='#627EEA' />
          </Button>
        )}
      </div>
    </>
  );
};
