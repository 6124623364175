import { GetUserCollaboratedFunds } from '@endaoment-frontend/api';
import { useAuth } from '@endaoment-frontend/authentication';
import { isFetchError } from '@endaoment-frontend/data-fetching';
import type { FundListing, UUID } from '@endaoment-frontend/types';

const isFundCollaborator = (fundId: UUID | undefined, collaboratedFunds: Array<FundListing> | undefined) => {
  // if fundId is undefined, we don't have a fund to check
  if (!fundId) return false;
  // if collaboratedFunds is undefined, we are still loading
  if (!collaboratedFunds) return undefined;
  // short-circuit if collaboratedFunds is empty
  if (collaboratedFunds.length === 0) return false;

  const fundForId = collaboratedFunds.find(fund => fund.id === fundId);
  const isFundCollaborator = !!fundForId;

  return isFundCollaborator;
};

export const useIsFundCollaborator = (fundId?: UUID) => {
  const { isSignedIn } = useAuth();
  const { data: collaboratedFunds, error } = GetUserCollaboratedFunds.useQuery([], {
    enabled: !!fundId && isSignedIn,
  });

  if (!isSignedIn || (isFetchError(error) && error.statusCode === 401)) return false;
  return isFundCollaborator(fundId, collaboratedFunds);
};
