import { VStack } from '@chakra-ui/react';
import { trackEvent } from '@phntms/next-gtm';
import type { ReactNode, Ref } from 'react';
import { forwardRef, useEffect, useReducer, useRef } from 'react';
import { useAsync } from 'react-use';
import { match, P } from 'ts-pattern';
import { useChainId, useConfig } from 'wagmi';

import { GetFund, GetUserFunds } from '@endaoment-frontend/api';
import { useAuth, useAuthType, useWalletModal } from '@endaoment-frontend/authentication';
import { defaults } from '@endaoment-frontend/config';
import { ensureUserChain, getNativeTokenForChain, isSupportedChain } from '@endaoment-frontend/multichain';
import {
  BankIcon,
  BitcoinIcon,
  ComplexIcon,
  NFTIcon,
  StarIcon,
  StockIcon,
  WalletIcon,
} from '@endaoment-frontend/ui/icons';
import { ActionButton, Loader, StepModal } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel, useTypeformModal } from '@endaoment-frontend/ui/smart';

import { DestinationSearch } from './DestinationSearch';
import styles from './DonationWizard.module.scss';
import { BrokerageDonationFlow } from './brokerage-donation-flow';
import { CreditDonationFlow } from './credit-donation-flow';
import { ErcDonationFlow } from './erc-donation-flow';
import { GrantFlow } from './grant-flow';
import { OtcDonationFlow } from './otc-donation-flow';
import { useDonationWizardState } from './useDonationWizardState';
import { useIsFundCollaborator } from './useIsFundCollaborator';

type WizardStep = 'crypto-type' | 'destination' | 'subform' | 'type';

const useChainNativeTokenOrFallback = () => {
  const chainId = useChainId();
  return getNativeTokenForChain(isSupportedChain(chainId) ? chainId : defaults.network.defaultChainId);
};
const DonationWizardStepsWithRef = (
  {
    onClose,
    onReset,
    step,
    onStepChange,
    state,
    setters,
  }: {
    onClose: () => void;
    onReset: () => void;
    step: WizardStep;
    onStepChange: (newStep: WizardStep) => void;
    state: ReturnType<typeof useDonationWizardState>['state'];
    setters: ReturnType<typeof useDonationWizardState>['setters'];
  },
  ref?: Ref<HTMLDivElement>,
) => {
  const { isSignedIn } = useAuth();
  const { authType } = useAuthType();
  const { showWallet } = useWalletModal();
  const nativeToken = useChainNativeTokenOrFallback();
  const { openTypeform: openComplexDonationForm } = useTypeformModal({ id: 'Pti8o8iS' });
  const { openTypeform: openNFTDonationForm } = useTypeformModal({ id: 'BE4L04bI' });

  const { setMode, setRecipient } = setters;
  const { data: funds } = GetUserFunds.useQuery([], {
    enabled: isSignedIn,
  });

  const userHasFunds = !isSignedIn || funds?.length === 0;
  const defaultIsOrgMode = state.recipient?.type === 'org' || state.mode === 'grant' ? true : userHasFunds;

  const isFundCollaborator = useIsFundCollaborator(state.recipient?.type === 'fund' ? state.recipient.id : undefined);
  const selectCryptoTypeActionButton = (
    <ActionButton
      onClick={() => {
        onStepChange('crypto-type');
      }}
      color='purple'
      text='Crypto or Digital Assets'
      subtext='Donate any crypto asset with sufficiently liquid markets'>
      <WalletIcon color='currentColor' />
    </ActionButton>
  );
  const selectGrantActionButton = (!state.recipient || state.recipient?.type !== 'fund') && (
    <ActionButton
      onClick={() => {
        if (!isSignedIn) {
          void showWallet();
          return;
        }
        trackEvent({
          event: 'dw_start_grant',
        });
        setMode('grant');
        onStepChange('subform');
      }}
      color='fund'
      text='Donor-Advised Fund'
      subtext='Give from an existing fund'>
      <StarIcon width={32} color='currentColor' />
    </ActionButton>
  );
  const selectCreditActionButton = (
    <ActionButton
      onClick={() => {
        trackEvent({
          event: 'dw_start_donation_pledge',
          data: {
            pledge_type: 'cash',
          },
        });
        setMode('credit-donation');
        onStepChange('subform');
      }}
      color='org'
      text='Cash or Fiat'
      subtext='Bank accounts, credit cards, payment apps or debit cards'>
      <BankIcon width={32} color='currentColor' />
    </ActionButton>
  );
  const selectBrokerageActionButton = (
    <ActionButton
      onClick={() => {
        trackEvent({
          event: 'dw_start_donation_pledge',
          data: {
            pledge_type: 'stock',
          },
        });
        setMode('brokerage-donation');
        onStepChange('subform');
      }}
      color='green'
      text='Stocks or Securities'
      subtext='Publicly traded stocks and other brokerage assets'>
      <StockIcon color='currentColor' width={38} />
    </ActionButton>
  );
  // ERC button is hidden for social users and a sign-in button for those not signed in
  const selectERCActionButton = match(authType)
    .returnType<ReactNode>()
    .with('wallet', () => (
      <ActionButton
        onClick={() => {
          trackEvent({
            event: 'dw_start_wallet_donation',
          });
          setMode('erc-donation');
          onStepChange('subform');
        }}
        color='purple'
        text='Browser connected EVM wallets'
        subtext={`Best for ${nativeToken.symbol} or ERC-20`}>
        <WalletIcon width={32} color='currentColor' />
      </ActionButton>
    ))
    .with('social', () => <></>)
    .with(undefined, () => (
      <ActionButton
        onClick={() => {
          void showWallet();
        }}
        color='purple'
        text='Browser connected EVM wallets'
        subtext={`Best for ${nativeToken.symbol} or ERC-20`}>
        <WalletIcon width={32} color='currentColor' />
      </ActionButton>
    ))
    .exhaustive();
  const selectOTCActionButton = (
    <ActionButton
      onClick={() => {
        trackEvent({
          event: 'dw_start_donation_pledge',
          data: {
            pledge_type: 'otc_crypto',
          },
        });
        setMode('otc-donation');
        onStepChange('subform');
      }}
      color='org'
      text='Central Exchanges or Cold Storage'
      subtext='Bitcoin, Solana, NFTs and more'>
      <BitcoinIcon width={28} color='currentColor' />
    </ActionButton>
  );
  const selectNFTActionButton = (
    <ActionButton
      onClick={() => {
        onClose();
        openNFTDonationForm();
      }}
      color='violet'
      text='NFT'
      subtext='Get a tax-deduction for an NFT'>
      <NFTIcon width={32} color='currentColor' />
    </ActionButton>
  );
  const selectComplexDonationActionButton = (
    <ActionButton
      onClick={() => {
        onClose();
        openComplexDonationForm();
      }}
      color='violet'
      text='Privately Held Assets'
      subtext='Private equities, interests, properties or other complex gifts'>
      <ComplexIcon width={36} color='currentColor' />
    </ActionButton>
  );

  useEffect(() => {
    if (step === 'destination' && !!state.recipient) {
      onStepChange(state.mode ? 'subform' : 'type');
    }
  }, [onStepChange, state.mode, state.recipient, step]);
  const steps = match({ step, ...state })
    .with({ step: 'destination' }, ({ mode, recipient }) => {
      if (recipient) {
        return (
          <StepModal.Step key='destination' ref={ref} onClose={onClose} header='New Donation or Grant'>
            <Loader size='l' />
          </StepModal.Step>
        );
      }
      return (
        <StepModal.Step key='destination' ref={ref} onClose={onClose} header='New Donation or Grant' hideBottomSpace>
          <h2 className={styles['step-header']}>Where would you like to {mode === 'grant' ? 'grant to' : 'donate'}?</h2>
          <DestinationSearch
            flow={mode === 'grant' ? 'grant' : 'donation'}
            defaultIsOrgMode={defaultIsOrgMode}
            onSubmit={recipient => {
              setRecipient(recipient);
              onStepChange(mode ? 'subform' : 'type');
            }}
          />
        </StepModal.Step>
      );
    })
    .with(P.union({ step: 'type' }, { step: 'subform', mode: P.nullish }), ({ recipient }) => {
      const handleBack = () => {
        onStepChange('destination');
        setRecipient(undefined);
      };
      if (typeof isFundCollaborator === 'undefined')
        return (
          <StepModal.Step key='type' ref={ref} onBack={handleBack} onClose={onClose} header='New Donation or Grant'>
            <Loader size='l' />
          </StepModal.Step>
        );
      if (isFundCollaborator) {
        return (
          <StepModal.Step
            key='type'
            ref={ref}
            onBack={handleBack}
            onClose={onClose}
            header='New Donation or Grant'
            hideBottomSpace>
            <EntityCardWithLabel label='Donating to' entity={recipient} onRemove={handleBack} />
            <hr />
            <h2 className={styles['step-header']}>What will you be giving?</h2>
            <VStack mt='0' gap='1rem' align='stretch'>
              {selectBrokerageActionButton}
              {selectComplexDonationActionButton}
              {selectERCActionButton}
              {selectOTCActionButton}
              {selectGrantActionButton}
            </VStack>
          </StepModal.Step>
        );
      }
      return (
        <StepModal.Step
          key='type'
          ref={ref}
          onBack={handleBack}
          onClose={onClose}
          header='New Donation or Grant'
          hideBottomSpace>
          <EntityCardWithLabel label='Donating to' entity={recipient} onRemove={handleBack} />
          <hr />
          <h2 className={styles['step-header']}>What will you be giving?</h2>
          <VStack mt='0' gap='1rem' align='stretch'>
            {selectCreditActionButton}
            {selectBrokerageActionButton}
            {selectComplexDonationActionButton}
            {selectCryptoTypeActionButton}
            {selectGrantActionButton}
          </VStack>
        </StepModal.Step>
      );
    })
    .with({ step: 'crypto-type' }, ({ step, recipient }) => {
      if (!recipient) throw new Error(`Recipient is missing from Donation Wizard ${step} step`);
      return (
        <StepModal.Step
          key='crypto-type'
          ref={ref}
          onBack={() => {
            onStepChange('type');
          }}
          onClose={onClose}
          header='New Donation or Grant'>
          <EntityCardWithLabel
            label='Donating to'
            entity={recipient}
            onRemove={() => {
              onStepChange('destination');
              setRecipient(undefined);
            }}
          />
          <hr />
          <h2 className={styles['step-header']}>What will you be giving?</h2>
          <VStack mt='0' gap='1rem' align='stretch'>
            {selectERCActionButton}
            {selectOTCActionButton}
            {selectNFTActionButton}
          </VStack>
        </StepModal.Step>
      );
    })
    .with({ step: 'subform', mode: 'erc-donation' }, ({ step, recipient }) => {
      if (!recipient) throw new Error(`Recipient is missing from Donation Wizard ${step} step`);
      return (
        <ErcDonationFlow ref={ref} onClose={onClose} onReset={onReset} rawState={state} rawStateSetters={setters} />
      );
    })
    .with({ step: 'subform', mode: 'otc-donation' }, ({ step, recipient }) => {
      if (!recipient) throw new Error(`Recipient is missing from Donation Wizard ${step} step`);
      return (
        <OtcDonationFlow ref={ref} onClose={onClose} onReset={onReset} rawState={state} rawStateSetters={setters} />
      );
    })
    .with({ step: 'subform', mode: 'grant' }, ({ step, recipient }) => {
      if (!recipient) throw new Error(`Recipient is missing from Donation Wizard ${step} step`);
      return <GrantFlow ref={ref} onClose={onClose} onReset={onReset} rawState={state} rawStateSetters={setters} />;
    })
    .with({ step: 'subform', mode: 'credit-donation' }, ({ step, recipient }) => {
      if (!recipient) throw new Error(`Recipient is missing from Donation Wizard ${step} step`);
      return (
        <CreditDonationFlow ref={ref} onClose={onClose} onReset={onReset} rawState={state} rawStateSetters={setters} />
      );
    })
    .with({ step: 'subform', mode: 'brokerage-donation' }, ({ step, recipient }) => {
      if (!recipient) throw new Error(`Recipient is missing from Donation Wizard ${step} step`);
      return (
        <BrokerageDonationFlow
          ref={ref}
          onClose={onClose}
          onReset={onReset}
          rawState={state}
          rawStateSetters={setters}
        />
      );
    })
    .exhaustive();
  return steps;
};
const DonationWizardSteps = forwardRef(DonationWizardStepsWithRef);

export const DonationWizard = () => {
  const { isSignedIn } = useAuth();
  const { showWallet, isWalletModalOpen } = useWalletModal();
  const chainId = useChainId();
  const wagmiConfig = useConfig();

  const { isDonationWizardOpen, resetDonationWizard, state, setters } = useDonationWizardState();
  const [step, setStep] = useReducer((_prev: WizardStep, next: WizardStep) => {
    if (isDonationWizardOpen) {
      trackEvent({ event: 'dw_wizard_progress', data: { dw_wizard_step: next, dw_wizard_mode: state.mode } });
    }
    return next;
  }, 'destination');

  const isOpen = isDonationWizardOpen && !isWalletModalOpen;
  const shouldShowWallet = state.mode === 'erc-donation' && !isSignedIn && !isWalletModalOpen;

  const handleClose = () => {
    setStep('destination');
    resetDonationWizard();
  };
  const handleReset = () => {
    setStep('type');
    resetDonationWizard(true);
  };

  const hasAlreadyReroutedToWallet = useRef(false);
  useAsync(async () => {
    // Guard against rendering if the modal is not open
    if (!isDonationWizardOpen) return;

    // If the user has already been rerouted to the wallet, close the modal
    if (shouldShowWallet && hasAlreadyReroutedToWallet.current) {
      handleClose();
      hasAlreadyReroutedToWallet.current = false;
      return;
    }

    // Guard against rendering if the user is not signed in
    if (shouldShowWallet) {
      await showWallet();
      hasAlreadyReroutedToWallet.current = true;
      return;
    }

    if (state.mode === 'erc-donation') {
      try {
        if (state.recipient && state.recipient.type === 'fund') {
          // If the initial recipient is a fund, ensure the user is connected to the fund's chain
          const fund = await GetFund.fetchFromDefaultClient([state.recipient.id]);
          await ensureUserChain(wagmiConfig, fund.chainId);
        } else if (!isSupportedChain(chainId)) {
          // Ask user to swap to a supported chain if they are not on one
          await ensureUserChain(wagmiConfig, defaults.network.defaultChainId);
        }
      } catch {
        handleClose();
        return;
      }
    }

    match({ step, ...state })
      .with({ mode: P.nonNullable, recipient: P.nonNullable }, () => {
        setStep('subform');
      })
      .with({ step: 'crypto-type' }, () => {
        // Do not change the step if we're coming back to the wizard from the wallet
        return;
      })
      .with({ recipient: P.nonNullable }, () => {
        setStep('type');
      })
      .otherwise(() => {
        setStep('destination');
      });
  }, [isSignedIn, isDonationWizardOpen]);

  return (
    <StepModal isOpen={isOpen} onClose={handleClose}>
      <DonationWizardSteps
        onClose={handleClose}
        onReset={handleReset}
        step={step}
        onStepChange={setStep}
        state={state}
        setters={setters}
      />
    </StepModal>
  );
};
