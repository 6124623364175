import Image from 'next/image';
import Link from 'next/link';
import { P, match } from 'ts-pattern';
import { formatUnits } from 'viem';

import { GetSubproject } from '@endaoment-frontend/api';
import { getAddressLink, useEnsNameWithFallback } from '@endaoment-frontend/multichain';
import { routes } from '@endaoment-frontend/routes';
import { addressSchema, type Address, type EntityLabel, type GenericToken, type UUID } from '@endaoment-frontend/types';
import { AllocationIcon, CircleIcon, StarIcon } from '@endaoment-frontend/ui/icons';
import { Pill } from '@endaoment-frontend/ui/shared';
import { formatShortAddress } from '@endaoment-frontend/utils';

import styles from './ActivityPills.module.scss';

export const OrgPill = ({ org }: { org: EntityLabel }) => {
  return (
    <Pill
      as={Link}
      href={routes.app.org({ einOrId: org.id })}
      size='tiny'
      variation='blue'
      outline
      rectangle
      data-testid='activity-org-pill'>
      <CircleIcon />
      {org.name}
    </Pill>
  );
};

const SubprojectPill = ({ subproject }: { subproject: EntityLabel }) => {
  const { data: subprojectDetails } = GetSubproject.useQuery([subproject.id]);

  const extraPropsForLinking = subprojectDetails
    ? {
        as: Link,
        href: routes.app.subproject({
          id: subproject.id,
          sponsorEinOrId: subprojectDetails.sponsorOrgEin ?? subprojectDetails.sponsorOrgId,
        }),
      }
    : {};

  return (
    <Pill
      size='tiny'
      variation='blue'
      outline
      rectangle
      data-testid='activity-subproject-pill'
      {...extraPropsForLinking}>
      <CircleIcon />
      {subproject.name}
    </Pill>
  );
};

export const PortfolioPill = ({ portfolio }: { portfolio: { name: string; id: UUID; ticker?: string | null } }) => {
  return (
    <Pill
      as={Link}
      href={routes.app.portfolio({ id: portfolio.id })}
      size='tiny'
      variation='violet'
      outline
      rectangle
      data-testid='activity-portfolio-pill'>
      <AllocationIcon />
      {portfolio.ticker ?? portfolio.name}
    </Pill>
  );
};

export const FundPill = ({ id, name }: { id?: UUID; name: string }) => {
  const extraPropsForLinking = id ? { as: Link, href: routes.app.fund({ id: id }) } : {};

  return (
    <Pill size='tiny' variation='orange' outline rectangle data-testid='activity-fund-pill' {...extraPropsForLinking}>
      <StarIcon />
      {name}
    </Pill>
  );
};

export const AddressPill = ({ transactor, chainId }: { transactor: Address; chainId: number }) => {
  const { data: transactorEnsName } = useEnsNameWithFallback({ address: transactor });

  return (
    <Pill
      as={Link}
      href={getAddressLink(transactor, chainId)}
      target='_blank'
      size='tiny'
      variation='purple'
      outline
      data-testid='activity-address-pill'>
      {transactorEnsName ?? formatShortAddress(transactor, 4)}
    </Pill>
  );
};

export const EntityLabelPill = ({ entityLabel }: { entityLabel: EntityLabel }) =>
  match(entityLabel)
    .with({ type: 'org' }, l => <OrgPill org={l} />)
    .with({ type: 'subproject' }, l => <SubprojectPill subproject={l} />)
    .with({ type: 'fund', name: P.when(v => addressSchema.safeParse(v).success) }, () => (
      <FundPill name='Private Fund' />
    ))
    .with({ type: 'fund' }, l => <FundPill id={l.id} name={l.name} />)
    .exhaustive();

export const MoneyPill = ({ amount }: { amount: string }) => {
  return <b className={styles['money-pill']}>{amount}</b>;
};

export const TokenAmountPill = ({
  amount,
  token,
}: {
  amount: bigint;
  token: Pick<GenericToken, 'decimals' | 'logoUrl' | 'symbol'>;
}) => {
  const amountStr = `${formatUnits(amount, token.decimals)} ${token.symbol}`;
  return (
    <span>
      {amountStr}
      <Image src={token.logoUrl} width={16} height={16} alt='' />
    </span>
  );
};
