import type { MutationStatus } from '@tanstack/react-query';
import { getBalance } from '@wagmi/core';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import { P, match } from 'ts-pattern';
import { formatUnits } from 'viem';
import { useConfig } from 'wagmi';
import { z } from 'zod';

import { GetSwapInfo } from '@endaoment-frontend/api';
import { useAuth } from '@endaoment-frontend/authentication';
import { PRICE_IMPACT_WARNING_THRESHOLD, TIME_ONE_MINUTE_IN_SECONDS } from '@endaoment-frontend/constants';
import { isFetchError } from '@endaoment-frontend/data-fetching';
import { useIsMobile } from '@endaoment-frontend/hooks';
import { getNativeTokenForChain, getStablecoinForChain } from '@endaoment-frontend/multichain';
import { routes } from '@endaoment-frontend/routes';
import type { DonationRecipient, EVMToken } from '@endaoment-frontend/types';
import { BigNumberInput, ProceedButton } from '@endaoment-frontend/ui/forms';
import { DownCaretIcon } from '@endaoment-frontend/ui/icons';
import { Loader, Pill } from '@endaoment-frontend/ui/shared';
import { TokenList } from '@endaoment-frontend/ui/smart';
import { formatCurrency, formatPriceImpactToPercent, formatUsdc } from '@endaoment-frontend/utils';

import styles from '../DonationWizard.module.scss';
import type { TokenAmountInput } from '../DonationWizard.types';
import { RecommendButton } from '../common/RecommendButton';
import { useIsFundCollaborator } from '../useIsFundCollaborator';

export const DonationTokenInput = ({
  recipient,
  chainId,
  token,
  tokenAmount,
  onTokenChange,
  onTokenAmountChange,
  onSubmit,
  onRecommend,
  recommendStatus,
}: {
  recipient: DonationRecipient;
  chainId: number;
  tokenAmount: bigint;
  token?: EVMToken;
  onTokenChange: (token: EVMToken) => void;
  onTokenAmountChange: (tokenAmount: bigint) => void;
  onSubmit: (tokenInput: TokenAmountInput) => void;
  onRecommend: () => void;
  recommendStatus: MutationStatus;
}) => {
  const tokenWithFallback = token ?? getNativeTokenForChain(chainId);
  const [tokenHeld, setTokenHeld] = useState<bigint>(0n);
  const [showTokenOptions, setShowTokenOptions] = useState(false);

  const { isMobile } = useIsMobile({ defaultState: true });

  const { authAddress } = useAuth();
  const wagmiConfig = useConfig();
  const isUserFundCollaborator = useIsFundCollaborator(recipient.type === 'fund' ? recipient.id : undefined);

  // If token is not set, set it to the native token for the chain
  useEffect(() => {
    if (!!token && token.chainId === chainId) return;
    onTokenChange(getNativeTokenForChain(chainId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, chainId]);

  // Populate initial token balance with balance of Native Token
  useAsync(async () => {
    if (!authAddress) {
      setTokenHeld(0n);
      return;
    }

    const tokenBalanceWithFallback = await getBalance(wagmiConfig, {
      address: authAddress,
      chainId: tokenWithFallback.chainId,
    });
    if (!token) {
      onTokenChange(tokenWithFallback);
    }
    setTokenHeld(tokenBalanceWithFallback.value);
  }, [authAddress, chainId]);

  // Fetch swap info and store, retry when selected destination, token or amount change
  const swapInfo = GetSwapInfo.useQuery([tokenAmount, tokenWithFallback, recipient, chainId], {
    staleTime: 2 * TIME_ONE_MINUTE_IN_SECONDS * 1000,
    refetchInterval: 30 * 1000,
    enabled: !isUserFundCollaborator && !!recipient && tokenAmount > 0n,
  });

  const [balanceTouched, setBalanceTouched] = useState(false);
  const balanceParse = createBalanceSchema({ swapInfo, isUserFundCollaborator, tokenHeld }).safeParse(tokenAmount);

  const preciseBalance = formatUnits(tokenHeld, tokenWithFallback.decimals);
  const formattedBalance = preciseBalance.length > 10 ? preciseBalance.slice(0, 10) + '...' : preciseBalance;

  return (
    <>
      <div className={styles['donation-token-input']}>
        <div className={styles['donation-token-input__top']}>
          <div className={styles['donation-token-input__input']}>
            <BigNumberInput
              name='tokenAmount'
              value={tokenAmount}
              units={tokenWithFallback.decimals}
              onChange={t => {
                onTokenAmountChange(t);
                setBalanceTouched(true);
              }}
              autoFocus={!isMobile}
              rightElements={
                <>
                  <Pill
                    size='tiny'
                    variation='blue'
                    onClick={() => onTokenAmountChange(tokenHeld ?? 0n)}
                    className={styles['max-button']}>
                    MAX
                  </Pill>
                  <button
                    type='button'
                    className={clsx(styles['select-token'], showTokenOptions && styles['select-token--active'])}
                    onClick={() => {
                      setShowTokenOptions(v => !v);
                    }}>
                    {!!tokenWithFallback.logoUrl && (
                      <Image src={tokenWithFallback.logoUrl} alt='' width={20} height={20} />
                    )}
                    {tokenWithFallback.symbol}
                    <DownCaretIcon />
                  </button>
                  {!!authAddress && (
                    <TokenList
                      type='EvmToken'
                      isOpen={showTokenOptions}
                      chainId={chainId}
                      walletAddress={authAddress}
                      onSelect={(token, balance) => {
                        onTokenChange(token);
                        setTokenHeld(balance);
                        setBalanceTouched(true);
                        setShowTokenOptions(false);
                      }}
                      onClose={() => setShowTokenOptions(false)}
                      hideBalances={isUserFundCollaborator}
                    />
                  )}
                </>
              }
            />
          </div>
        </div>
        <div className={clsx(styles['donation-token-input__details'])}>
          {tokenWithFallback.id !== getStablecoinForChain(chainId).id && (
            <div>
              {match([balanceParse, balanceTouched, isUserFundCollaborator])
                .returnType<ReactNode>()
                .with(
                  [
                    {
                      success: false,
                      error: { errors: [{ message: 'Loading Swap' }] },
                    },
                    P.any,
                    false,
                  ],
                  () => <Loader size='s' />,
                )
                .with([{ success: false, error: { errors: [P.any, ...P.array()] } }, true, P.any], ([{ error }]) => (
                  <div className={styles['error-container']}>
                    <span>{error.errors[0].message}</span>
                  </div>
                ))
                .with([{ success: true }, P.any, false], () => {
                  if (!swapInfo.data) throw new Error('Swap info should not be undefined if balance is valid');
                  return (
                    <p>
                      <em>
                        {formatUnits(tokenAmount, tokenWithFallback.decimals)}
                        &nbsp;{tokenWithFallback.symbol}
                      </em>
                      &nbsp;=&nbsp;<em>{formatCurrency(formatUsdc(swapInfo.data.quote.expectedUsdc))}</em>
                      &nbsp;•&nbsp;
                      <em>{formatPriceImpactToPercent(swapInfo.data.quote.priceImpact)}</em>&nbsp;Price Impact
                    </p>
                  );
                })
                .otherwise(() => (
                  <></>
                ))}
            </div>
          )}
          {!isUserFundCollaborator && (
            <span className={styles['user-balance']}>
              {formattedBalance}
              &nbsp;
              {tokenWithFallback.symbol}
              &nbsp;Balance
            </span>
          )}
        </div>
      </div>
      {isUserFundCollaborator ? (
        <RecommendButton
          onRecommend={() => {
            if (!balanceParse.success || !token) return;
            onRecommend();
          }}
          recommendStatus={recommendStatus}
        />
      ) : (
        <>
          {!!swapInfo.data && swapInfo.data.quote.priceImpact > PRICE_IMPACT_WARNING_THRESHOLD && (
            <div className={styles['price-impact-warning']}>
              This transaction requires a trade with high price impact, consider&nbsp;
              <Link href={routes.marketing.otc()} target='_blank'>
                donating Over-the-Counter
              </Link>
              &nbsp;instead.
            </div>
          )}
          <ProceedButton
            onClick={() => {
              if (!balanceParse.success || !token) return;
              onSubmit({
                tokenAmount,
                token,
              });
            }}
            disabled={!balanceParse.success}
          />
        </>
      )}
    </>
  );
};

const createBalanceSchema = ({
  swapInfo,
  isUserFundCollaborator,
  tokenHeld,
}: {
  isUserFundCollaborator: boolean | undefined;
  tokenHeld: bigint;
  swapInfo: ReturnType<(typeof GetSwapInfo)['useQuery']>;
}) => {
  const baseSchema = z.bigint().gt(0n, { message: 'Amount must be greater than 0' });

  // If user is a fund collaborator, we don't need to check balance or swap info
  if (isUserFundCollaborator) {
    return baseSchema;
  }

  return (
    baseSchema
      .refine(
        // Check balance against tokenHeld
        v => v <= tokenHeld,
        () => ({ message: 'Insufficient Balance' }),
      )
      .refine(
        // Display error if swapInfo fetch failed
        () => swapInfo.status !== 'error',
        () => {
          if (isFetchError(swapInfo.error)) {
            const messageParse = z.object({ message: z.string() }).safeParse(swapInfo.error.data);
            if (messageParse.success) {
              if (messageParse.data.message.match(/Input token .* not found$/))
                return { message: 'Selected token is invalid' };
              return { message: messageParse.data.message };
            }
          }
          if (swapInfo.error instanceof Error) {
            if (swapInfo.error.message.includes('Timed out')) return { message: 'Timed out while assembling swap' };
            return { message: swapInfo.error.message };
          }
          return { message: 'Unknown Error' };
        },
      )
      // Check if swapInfo is loaded
      .refine(() => !swapInfo.isPending, { message: 'Loading Swap' })
  );
};
