import { addBreadcrumb } from '@sentry/nextjs';
import {
  simulateContract,
  type Config as WagmiConfig,
  writeContract,
  type WriteContractParameters,
  type WriteContractReturnType,
} from '@wagmi/core';
import type { Account } from 'viem';
import { estimateContractGas } from 'viem/actions';

export const writeContractWithIncreasedGas = async <
  SpecificWCP extends WriteContractParameters /**
   * We need to ensure that the account is passed in as a parameter, if it is not provided the gas estimation will not provide a `from` field on the RPC request
   */ & { account: Account },
>(
  wagmiConfig: WagmiConfig,
  contractConfig: SpecificWCP,
  /**
   * How to increase the gas limit
   *
   * Defaults to 120% of the estimated gas
   */
  increase: {
    amount: bigint;
    /**
     * Multiplier to apply to the estimated gas
     *
     * A bigint where 100n is 100%
     */
    multiplier: bigint;
  } = {
    amount: 0n,
    multiplier: 120n,
  },
  clientSettings?: Parameters<WagmiConfig['getClient']>[0],
): Promise<WriteContractReturnType> => {
  // Initialize the gas limit to the value provided in the contract configuration
  let adjustedGas: bigint | undefined = contractConfig.gas;
  try {
    // Attempt to estimate the gas usage for the transaction
    const estimatedGas = await estimateContractGas(wagmiConfig.getClient(clientSettings), contractConfig);
    // Increase the gas limit by a static amount over what the wallet estimates
    const amountAdjustedGas = estimatedGas + increase.amount;
    // Increase the gas limit by a set percent
    adjustedGas = (amountAdjustedGas * increase.multiplier) / 100n;
  } catch (error) {
    console.warn('Failed to estimate gas for transaction', error);
    // Log to Sentry if the gas estimation fails
    addBreadcrumb({
      level: 'warning',
      message: 'Failed to estimate gas for transaction',
      data: {
        error,
        contractConfig,
      },
    });
  }

  const adjustedConfig = {
    ...contractConfig,
    // Use the adjusted gas limit to execute the transaction
    gas: adjustedGas,
  };

  try {
    const { request } = await simulateContract(wagmiConfig, adjustedConfig);
    return writeContract(wagmiConfig, request);
  } catch (error) {
    console.warn('Failed to simulate contract', error);
    // Log to Sentry if the contract simulation fails
    addBreadcrumb({
      level: 'warning',
      message: 'Failed to simulate contract',
      data: {
        error,
        adjustedConfig,
      },
    });

    // Attempt to write to the contract anyway
    return writeContract(wagmiConfig, adjustedConfig);
  }
};
