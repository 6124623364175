import { formatCurrency, formatUsdc } from '@endaoment-frontend/utils';

import { EntityLabelPill, MoneyPill, PortfolioPill } from '../common/ActivityPills';
import type { ActivitySubject, AllocationActivity } from '../types';

import { ActivityDescription } from './ActivityDescription';

export const PortfolioAllocationActivityDescription = ({
  activity,
  subject,
}: {
  activity: AllocationActivity;
  subject?: ActivitySubject;
}) => {
  const isBuy = activity.tradeType === 'Buy';
  const action = isBuy ? `allocat${activity.outcome === 'InTransit' ? 'ing' : 'ed'}` : 'sold';

  return (
    <ActivityDescription>
      {subject !== 'fund' ? (
        <>
          <EntityLabelPill entityLabel={activity.fund} />
          <em>{action}</em>
        </>
      ) : (
        <em>{action.charAt(0).toUpperCase() + action.slice(1)}</em>
      )}
      <MoneyPill amount={formatCurrency(formatUsdc(activity.usdcAmount))} />
      &nbsp;{isBuy ? 'to' : 'from'}
      <PortfolioPill portfolio={activity.portfolio} />
    </ActivityDescription>
  );
};
