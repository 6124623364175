import { Popover, PopoverAnchor, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';

import type { EVMToken, OTCToken } from '@endaoment-frontend/types';
import { ScanIcon } from '@endaoment-frontend/ui/icons';

import styles from './OtcQRCodeModal.module.scss';

export const OtcQRCodeModal = ({
  token,
  tokenSendAddress,
}: {
  token: EVMToken | OTCToken;
  tokenSendAddress: string;
}) => {
  return (
    <Popover autoFocus={false} placement='top' trigger='hover'>
      <PopoverAnchor>
        <PopoverTrigger>
          <span className={styles['scan-icon']}>
            <ScanIcon width={28} color='currentColor' />
          </span>
        </PopoverTrigger>
      </PopoverAnchor>
      <PopoverContent className={styles['popover-content']}>
        <PopoverBody>
          <QRCodeSVG value={tokenSendAddress} size={200} data-testid='otc-qrcode' />
          <p>
            Only send <b>{token.symbol}</b> on the <b>{token.type === 'EvmToken' ? 'Ethereum' : token.name}</b> network
            to this address
          </p>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
