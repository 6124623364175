import { formatCurrency, formatUsdc } from '@endaoment-frontend/utils';

import { AddressPill, EntityLabelPill, MoneyPill, TokenAmountPill } from '../common/ActivityPills';
import type { ActivitySubject, DonationActivity } from '../types';

import { ActivityDescription } from './ActivityDescription';

export const DirectDonationActivityDescription = ({
  activity,
  subject,
}: {
  activity: DonationActivity;
  subject?: ActivitySubject;
}) => {
  const { token, transactor, to, chainId, amount, usdcAmount } = activity;

  return (
    <ActivityDescription>
      {subject !== 'user' ? (
        <>
          <AddressPill transactor={transactor} chainId={chainId} />
          <em>donated</em>
        </>
      ) : (
        <em>Donated</em>
      )}
      {!!token && <TokenAmountPill token={token} amount={amount} />}
      <MoneyPill amount={formatCurrency(formatUsdc(usdcAmount))} />
      {(subject === 'featured' || subject === 'user') && (
        <>
          <span>to</span>
          <EntityLabelPill entityLabel={to} />
        </>
      )}
    </ActivityDescription>
  );
};
