import { getWalletClient } from '@wagmi/core';

import { orgFundFactoryAbi } from '@endaoment-frontend/abis';
import { config, defaults } from '@endaoment-frontend/config';
import { getContractAddressForChain } from '@endaoment-frontend/multichain';
import type { Address } from '@endaoment-frontend/types';

import type { ReactionHookOptions } from './generateReactionHook';
import { useGenerateReactionHook } from './generateReactionHook';
import { writeContractWithIncreasedGas } from './writeContractWithIncreasedGas';

type DeployFundArgs = {
  manager: Address;
  salt: Address;
};

export const createFundTransaction: ReactionHookOptions<DeployFundArgs>['createTransaction'] = async ({
  args: { manager, salt },
  wagmiConfig,
}) => {
  const walletClient = await getWalletClient(wagmiConfig);
  return writeContractWithIncreasedGas(wagmiConfig, {
    abi: orgFundFactoryAbi,
    address: getContractAddressForChain(config.socialLoginChainId, 'orgFundFactory'),
    functionName: 'deployFund',
    args: [manager, salt],
    account: walletClient.account,
  });
};

export const useDeployFund = () =>
  useGenerateReactionHook<DeployFundArgs>({
    actionName: 'DEPLOY_FUND',
    createTransaction: createFundTransaction,
    createDescription: () => 'Creating Fund',
    // createExtra: ({ args }) => args.input,
    confirmations: defaults.confirmations.transfer,
  });
