import { routes } from '@endaoment-frontend/routes';
import type { Address } from '@endaoment-frontend/types';

import { getChainForChainId } from './getDefaults';

export const getTransactionLink = (txHash: Address, chainId: number) => {
  const chain = getChainForChainId(chainId);

  if (!chain.blockExplorers) {
    return routes.app.transaction({ txHash, chainId });
  }

  return `${chain.blockExplorers.default.url}/tx/${txHash}`;
};

export const getAddressLink = (address: Address, chainId: number) => {
  const chain = getChainForChainId(chainId);

  if (!chain.blockExplorers) {
    return routes.app.address({ address, chainId });
  }

  return `${chain.blockExplorers.default.url}/address/${address}`;
};
