import { config } from '@endaoment-frontend/config';
import { addressSchema, type Address, type AdminAccountName } from '@endaoment-frontend/types';
import { capitalize, equalAddress } from '@endaoment-frontend/utils';

export const isAdministrativeAccount = (address?: string, accountName?: AdminAccountName): boolean => {
  if (!address) return false;
  const addressParse = addressSchema.safeParse(address);
  if (!addressParse.success) return false;

  if (!accountName)
    return [
      config.endaoment.accounts.accountant,
      config.endaoment.accounts.maintainer,
      config.endaoment.accounts.reviewer,
      config.endaoment.accounts.keeper,
    ]
      .flat()
      .filter(Boolean)
      .some(acc => equalAddress(acc, addressParse.data));

  return config.endaoment.accounts[accountName].some(acc => equalAddress(acc, addressParse.data));
};

export const getEndaomentAccountName = (address?: Address | '' | null) => {
  if (!address) return '';

  for (const [name, accountArr] of Object.entries(config.endaoment.accounts)) {
    for (const acc of accountArr) {
      if (equalAddress(acc, address)) return capitalize(name) as Capitalize<AdminAccountName>;
    }
  }

  return '';
};
