import type { As } from '@chakra-ui/react';
import clsx from 'clsx';
import type { ComponentProps, ReactElement } from 'react';

import { GetPortfolio } from '@endaoment-frontend/api';
import type { UUID } from '@endaoment-frontend/types';
import { CloseIcon } from '@endaoment-frontend/ui/icons';
import { Button } from '@endaoment-frontend/ui/shared';
import { MiniFundDetailsWithQuery, MiniLoadingDetails, MiniPortfolioDetails } from '@endaoment-frontend/ui/smart';

import styles from './RemovableDetails.module.scss';

type RemovablePortfolioDetailsProps = {
  portfolioId: UUID;
  onRemove?: () => void;
  children?: Array<ReactElement> | ReactElement;
};

export const RemovablePortfolioDetails = ({ portfolioId, onRemove, children }: RemovablePortfolioDetailsProps) => {
  const { data } = GetPortfolio.useQuery([portfolioId]);

  if (!data) return <MiniLoadingDetails />;

  return (
    <MiniPortfolioDetails portfolio={data}>
      {!!onRemove && (
        <RemoveButton
          onClick={onRemove}
          title='Remove Selected Portfolio'
          className={styles['remove-button--portfolio']}
        />
      )}
      {children}
    </MiniPortfolioDetails>
  );
};

type RemovableFundDetailsProps = {
  fundId: UUID;
  onRemove?: () => void;
  children?: Array<ReactElement> | ReactElement;
};

export const RemovableFundDetails = ({ fundId, onRemove, children }: RemovableFundDetailsProps) => {
  return (
    <MiniFundDetailsWithQuery fundId={fundId}>
      {!!onRemove && (
        <RemoveButton onClick={onRemove} title='Remove Selected Fund' className={styles['remove-button--fund']} />
      )}
      {children}
    </MiniFundDetailsWithQuery>
  );
};

const RemoveButton = <Tag extends As>({
  className,
  ...props
}: Omit<ComponentProps<typeof Button<Tag>>, 'children'>) => {
  return (
    <Button
      size='small'
      filled
      minimal
      variation='faded'
      float={false}
      className={clsx(styles['remove-button'], className)}
      {...props}>
      <CloseIcon width={12} strokeWidth={0.75} />
    </Button>
  );
};
