import { Checkbox } from '@endaoment-frontend/ui/forms';
import { QuestionIcon } from '@endaoment-frontend/ui/icons';
import { Tooltip } from '@endaoment-frontend/ui/shared';

type RequestLiquidationCheckboxProps = {
  requestScheduledLiquidation: boolean;
  onChangeRequestScheduledLiquidation: (newVal: boolean) => void;
};

export const RequestLiquidationCheckbox = ({
  requestScheduledLiquidation,
  onChangeRequestScheduledLiquidation,
}: RequestLiquidationCheckboxProps) => {
  return (
    <Checkbox
      checked={requestScheduledLiquidation}
      onChange={onChangeRequestScheduledLiquidation}
      label={
        <Tooltip content='Checking this box means to request a liquidation'>
          I would like to request a scheduled liquidation of this donation{' '}
          <QuestionIcon color='currentColor' width={14} height={14} />
        </Tooltip>
      }
    />
  );
};
