import { useState } from 'react';
import { normalize } from 'viem/ens';
import type { UseEnsAvatarReturnType } from 'wagmi';
import { useChainId, useEnsAvatar, useEnsName } from 'wagmi';

import { defaults } from '@endaoment-frontend/config';
import type { Address } from '@endaoment-frontend/types';

export const useEnsAvatarWithFallback = (args: { address: Address; chainId?: number }): UseEnsAvatarReturnType => {
  const currentChainId = useChainId();
  const [usedChainId, setUsedChainId] = useState(args?.chainId ?? currentChainId);

  const ensNameResponse = useEnsName({
    address: args.address,
    query: {
      staleTime: Infinity,
    },
    chainId: usedChainId,
  });
  const ensAvatarResponse = useEnsAvatar({
    name: ensNameResponse.data ? normalize(ensNameResponse.data) : undefined,
    query: {
      staleTime: Infinity,
      enabled: !!ensNameResponse.data,
    },
    chainId: usedChainId,
  });

  const isFound = ensNameResponse.data !== null && ensAvatarResponse.data !== null;
  const isError = ensNameResponse.error || ensAvatarResponse.error;
  if (isError && !isFound && usedChainId !== defaults.network.defaultChainId) {
    setUsedChainId(defaults.network.defaultChainId);
  }

  return ensAvatarResponse;
};
