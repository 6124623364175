import { useMutation } from '@tanstack/react-query';
import { stringToHex } from 'viem';

import { CreateFund } from '@endaoment-frontend/api';
import { useAuth, useAuthType } from '@endaoment-frontend/authentication';
import { config } from '@endaoment-frontend/config';
import {
  addressSchema,
  type Address,
  type CreateFundAdvisorInput,
  type CreateFundDetailsInput,
  type FundListing,
} from '@endaoment-frontend/types';

import { useDeployFund } from './useDeployFund';

type DeployAndCreateArgs = {
  fundDetails: CreateFundDetailsInput;
  advisor: CreateFundAdvisorInput;
};

const createFundSalt = () => {
  const rawSalt = stringToHex(crypto.randomUUID().replaceAll('-', ''), { size: 32 });
  return addressSchema.parse(rawSalt);
};

export const useDeployAndCreateFund = ({
  onSuccess,
}: {
  onSuccess: (data: FundListing, variables: DeployAndCreateArgs) => void;
}) => {
  const { authAddress } = useAuth();
  const { isSocialAuth } = useAuthType();

  const { execute: deployFund } = useDeployFund();

  const { mutateAsync, status, data, reset } = useMutation({
    mutationKey: CreateFund.prefixKeys,
    mutationFn: async ({ fundDetails, advisor }: DeployAndCreateArgs) => {
      if (!authAddress) throw new Error('User must be authenticated to create a fund');

      // For social users, we predeploy their funds
      let deploymentTransactionHash: Address | undefined;
      let chainId = fundDetails.chainId;
      let fundSalt: Address | undefined;

      if (isSocialAuth) {
        chainId = config.socialLoginChainId;
        fundSalt = createFundSalt();

        // Perform the transaction and wait for the transaction hash to be available
        const deploymentExecution = await deployFund(
          { manager: authAddress, salt: fundSalt },
          { resolveOnPending: true },
        );
        if (deploymentExecution.status === 'error') throw deploymentExecution.error;
        deploymentTransactionHash = deploymentExecution.transactionHash;
      }

      return CreateFund.execute({
        fundInput: { ...fundDetails, chainId },
        advisor,
        deploymentTransactionHash,
        fundSalt,
      });
    },
    onSuccess,
    retryDelay: 5000,
  });

  return {
    createFund: mutateAsync,
    status,
    createdFundId: data?.id,
    reset,
  } as const;
};
