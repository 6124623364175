import { LogoIcon } from '@endaoment-frontend/ui/icons';

import styles from './UnderMaintenance.module.scss';

export const UnderMaintenance = () => {
  return (
    <>
      <main className={styles.maintenance}>
        <LogoIcon />
        <h1>Something new is coming...</h1>
        <p>
          Endaoment is currently down for maintenance, we will be back ASAP.
          <br />
          Please contact&nbsp;
          <a href='mailto:admin@endaoment.org'>our team</a>&nbsp;with questions.
        </p>
      </main>
    </>
  );
};
