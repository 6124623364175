import { useAsync } from 'react-use';

import { GetUserIdentity, WhoAmI } from '@endaoment-frontend/api';
import { useAuth } from '@endaoment-frontend/authentication';
import { INTERCOM_APP_ID } from '@endaoment-frontend/constants';
import { IntercomLoader } from '@endaoment-frontend/ui/smart';

export const IntercomLoaderWithAuth = () => {
  const { isSignedIn } = useAuth();
  useAsync(async () => {
    if (typeof window === 'undefined' || !window.Intercom) return;

    if (!isSignedIn) {
      // Clear user identifiers from Intercom when user signs out
      window.Intercom('update', {
        app_id: INTERCOM_APP_ID,
        name: undefined,
        email: undefined,
        phone: undefined,
        user_id: undefined,
        user_hash: undefined,
      });
      return;
    }

    // When a user signs in, update their info in Intercom
    const user = await WhoAmI.fetchFromDefaultClient([]);
    if (!user) throw new Error("User is signed in but doesn't have a cookie");
    const userIdentity = await GetUserIdentity.fetchFromDefaultClient([]);
    window.Intercom('update', {
      app_id: INTERCOM_APP_ID,
      user_id: user.userId,
      name: `${userIdentity.firstName} ${userIdentity.lastName}`,
      email: userIdentity.email,
    });
  }, [isSignedIn]);

  return <IntercomLoader />;
};
