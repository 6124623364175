import { Form, Formik } from 'formik';
import { parseUnits } from 'viem';
import { z } from 'zod';

import { STABLECOIN_DECIMALS } from '@endaoment-frontend/constants';
import { useIsMobile } from '@endaoment-frontend/hooks';
import { TargetAllocationRebalanceCheckbox } from '@endaoment-frontend/target-allocations';
import type { DonationRecipient } from '@endaoment-frontend/types';
import { BigNumberInput, ProceedButton, validateWithZod } from '@endaoment-frontend/ui/forms';
import { formatCurrency } from '@endaoment-frontend/utils';

import wizardStyles from '../DonationWizard.module.scss';
import { TaxReceiptButton } from '../common/TaxReceiptButton';

import styles from './CreditAmountStep.module.scss';

const STRIPE_MINIMUM_AMOUNT = 50;
const STRIPE_MAXIMUM_AMOUNT = 999_999_99;

const creditAmountFormSchema = z.object({
  pledgedAmountCents: z
    .number()
    .int()
    .gte(STRIPE_MINIMUM_AMOUNT, {
      message: `Amount must be equal or greater than ${formatCurrency(STRIPE_MINIMUM_AMOUNT / 1e2)}`,
    })
    .lte(STRIPE_MAXIMUM_AMOUNT, { message: `Amount must be less than ${formatCurrency(STRIPE_MAXIMUM_AMOUNT / 1e2)}` }),
});
type CreditAmountStepFormValues = z.infer<typeof creditAmountFormSchema>;

type CreditAmountStepProps = {
  onSubmit: (v: CreditAmountStepFormValues) => void;
  initialValues?: Partial<CreditAmountStepFormValues>;
  isRebalanceRequested: boolean;
  onChangeRebalanceRequested: (newVal: boolean) => void;
  recipient: DonationRecipient;
  includeTaxReceipt: boolean;
  receiptEmail: string | undefined;
  onGoToTaxStep: (v?: Partial<CreditAmountStepFormValues>) => void;
  onNoTaxReceipt: () => void;
};

export const CreditAmountStep = ({
  onSubmit,
  initialValues,
  isRebalanceRequested,
  onChangeRebalanceRequested,
  recipient,
  includeTaxReceipt,
  receiptEmail,
  onGoToTaxStep,
  onNoTaxReceipt,
}: CreditAmountStepProps) => {
  const { isMobile } = useIsMobile({ defaultState: true });

  return (
    <Formik
      initialValues={{
        pledgedAmountCents: initialValues?.pledgedAmountCents ?? 0,
      }}
      onSubmit={onSubmit}
      validate={validateWithZod(creditAmountFormSchema)}>
      {({ values, setFieldValue, errors, isValid }) => (
        <Form className={wizardStyles['step-form']}>
          <h4 className={wizardStyles['step-header']}>How much would you like to donate?</h4>
          <BigNumberInput
            name='amount'
            leftElements='$'
            placeholder='0.00'
            value={BigInt(values.pledgedAmountCents)}
            onChange={v => {
              setFieldValue('pledgedAmountCents', Number(v));
            }}
            units={2}
            error={errors.pledgedAmountCents}
            className={styles.input}
            autoFocus={!isMobile}
          />
          {/* DO NOT use with Formik as it will somehow cause an infinite loop */}
          <TargetAllocationRebalanceCheckbox
            isRebalanceRequested={isRebalanceRequested}
            onChange={onChangeRebalanceRequested}
            recipient={recipient}
            additionalBalance={
              // Convert cent value to stablecoin value
              parseUnits(`${values.pledgedAmountCents / 100}`, STABLECOIN_DECIMALS)
            }
          />
          <TaxReceiptButton
            includeTaxReceipt={includeTaxReceipt}
            receiptEmail={receiptEmail}
            onClick={newState => {
              if (!newState) {
                onNoTaxReceipt();
                return;
              }
              if (!isValid) {
                onGoToTaxStep(initialValues);
                return;
              }
              onGoToTaxStep(values);
            }}
          />
          <ProceedButton type='submit' />
        </Form>
      )}
    </Formik>
  );
};
