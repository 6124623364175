import { Box, Flex } from '@chakra-ui/react';
import clsx from 'clsx';
import Image from 'next/image';
import { match } from 'ts-pattern';
import { formatUnits } from 'viem';

import { GetToken } from '@endaoment-frontend/api';
import { defaults } from '@endaoment-frontend/config';
import { routes } from '@endaoment-frontend/routes';
import type { DonationRecipient, PledgeOutcome, UUID } from '@endaoment-frontend/types';
import { AwaitingIcon, CheckmarkIcon, CloseIcon, QuestionIcon } from '@endaoment-frontend/ui/icons';
import { Button, Pill, QuestionPopover } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';
import { formatBasisPointsToPercent, formatCurrency, formatDate } from '@endaoment-frontend/utils';

import wizardStyles from '../DonationWizard.module.scss';

export const OtcDonationView = ({
  recipient,
  tokenId,
  tokenAmount,
  donationPledgeId,
  outcome,
  netDonation,
  protocolFees,
  occurredAtUtc,
  onClose,
}: {
  recipient: DonationRecipient;
  tokenId: number;
  tokenAmount: bigint;
  donationPledgeId?: UUID;
  netDonation?: number;
  protocolFees?: number;
  outcome?: PledgeOutcome;
  occurredAtUtc?: number;
  onClose: () => void;
}) => {
  const { data: token } = GetToken.useQuery([tokenId]);

  return (
    <>
      <EntityCardWithLabel label='Donating to' entity={recipient} />

      <div className={wizardStyles['donation-info']}>
        <div>
          <h4>{outcome !== 'Success' && 'Expected'} Donation</h4>
          <h4>
            {!!token && (
              <div>
                {!!token.logoUrl && (
                  <Image src={token.logoUrl} alt={`${token.name} token icon`} width={20} height={20} />
                )}
                {`${formatUnits(tokenAmount, token.decimals)} ${token.symbol}`}
              </div>
            )}
          </h4>
        </div>
        {!!netDonation && (
          <div>
            <h4>Donation Value</h4>
            <h4>{formatCurrency(netDonation)}</h4>
          </div>
        )}
        {!!protocolFees && (
          <>
            <hr />
            <div>
              <QuestionPopover
                content={
                  <>
                    {`A maximum fee of ${formatBasisPointsToPercent(
                      defaults.fees.donationBps,
                    )} is being applied. Larger donations may be eligible for a lower fee. `}
                    <a href={routes.docs.feeSchedule()} target='_blank'>
                      Read More
                    </a>
                  </>
                }>
                <h6 className={wizardStyles['question-tooltip']}>
                  Endaoment Fee
                  <QuestionIcon color='currentColor' width={15} height={16} />
                </h6>
              </QuestionPopover>
              <h6>{formatCurrency(protocolFees)}</h6>
            </div>
          </>
        )}
      </div>

      <hr />
      {match(outcome)
        .with('Success', () => (
          <div className={wizardStyles.info}>
            {!!occurredAtUtc && (
              <div>
                Donation completed:&nbsp;
                <span>{formatDate(occurredAtUtc, { includeTime: true, dateStyle: 'short' })}</span>
              </div>
            )}
            <Pill variation='green' size='tiny'>
              <CheckmarkIcon strokeWidth={5} />
              Completed
            </Pill>
          </div>
        ))
        .with('Failure', () => (
          <div className={wizardStyles.info}>
            <Pill variation='red' size='tiny'>
              <CloseIcon />
              Transfer Failed
            </Pill>
            <Box mt='1rem'>
              Please{' '}
              <a href='mailto:help@endaoment.org' className={wizardStyles['mailto']}>
                contact our team
              </a>{' '}
              for more information.
            </Box>
          </div>
        ))
        .otherwise(() => (
          <>
            <Pill
              className={clsx(
                wizardStyles.disclaimer,
                wizardStyles.routing__disclaimer,
                wizardStyles['disclaimer--otc'],
              )}>
              No action required. We're liquidating your donation and migrating the proceeds onchain. You will receive
              an email confirmation when complete, usually within one business day.
            </Pill>
            <div className={wizardStyles['awaiting']}>
              <AwaitingIcon />
              Awaiting Donation...
            </div>
            <hr />
            <div className={wizardStyles.info}>
              You can safely close this modal while the operation occurs. Check your email inbox for more details, or{' '}
              <a href='mailto:help@endaoment.org' className={wizardStyles['mailto']}>
                contact our team
              </a>
              .
            </div>
            <Flex flexDirection='row' className={wizardStyles['modal-actions']}>
              <Button onClick={onClose} size='medium' float={false}>
                Close
              </Button>
              <Button
                as='a'
                href={`mailto:admin@endaoment.org?subject=Endaoment: Crypto Donation Cancel Request - ID ${donationPledgeId}`}
                variation='red'
                size='medium'
                float={false}>
                Cancel Donation
              </Button>
            </Flex>
          </>
        ))}
    </>
  );
};
