import { useAtomValue } from 'jotai';
import { useCallback } from 'react';

import type { Address, BlockchainTransactionStatus } from '@endaoment-frontend/types';

import type { TransactionActionKey } from '../transactions';

import type { StoredList } from './transactionListAtoms';
import { pendingTransactionsAtom, transactionListAtom } from './transactionListAtoms';

export type StoredTransaction<Extra = unknown> = {
  status: BlockchainTransactionStatus;
  hash: Address;
  wallet: Address;
  type: TransactionActionKey;
  description: string;
  createdAt: number;
  onSelect?: () => void;
  extra: Extra;
  failCount: number;
  chainId: number;
};

/**
 * Use localStorage to keep track of transactions from our app
 * @requires WagmiProvider
 */
export const useTransactionList = (): {
  allTransactions: StoredList;
  pendingTransactions: Array<StoredTransaction>;
  getStoredTransaction: (hash?: Address) => StoredTransaction | undefined;
} => {
  const allTransactions = useAtomValue(transactionListAtom);
  const pendingTransactions = useAtomValue(pendingTransactionsAtom);
  const getStoredTransaction = useCallback(
    (hash?: Address) => (hash ? allTransactions.get(hash) : undefined),
    [allTransactions],
  );

  return {
    allTransactions,
    pendingTransactions,
    getStoredTransaction,
  };
};
