import type { Config } from '@wagmi/core';
import { getChainId, switchChain } from '@wagmi/core';
import type { Chain } from 'viem';

import { getChainForChainId } from './getDefaults';

type EnsureUserChainOptions = {
  force?: boolean;
};

export const ensureUserChain = async (
  wagmiConfig: Config,
  chainId: number,
  options?: EnsureUserChainOptions,
): Promise<Chain> => {
  const currentChainId = getChainId(wagmiConfig);

  if (currentChainId === chainId) return getChainForChainId(chainId);

  try {
    return await switchChain(wagmiConfig, { chainId });
  } catch (e) {
    if (e instanceof Error && e.message.includes(`Request of type 'wallet_switchEthereumChain' already pending`)) {
      return getChainForChainId(chainId);
    }
    if (e instanceof Error && e.message.includes(`User rejected the request.`) && options?.force) {
      return ensureUserChain(wagmiConfig, chainId, options);
    }

    throw e;
  }
};
