import { z } from 'zod';

import { defaults } from '@endaoment-frontend/config';
import { RequestHandler } from '@endaoment-frontend/data-fetching';
import type { DonationRecipient, EVMToken, EntityType, OTCToken, SwapInfo, UUID } from '@endaoment-frontend/types';
import {
  arraySchemaInvalidsFiltered,
  evmTokenSchema,
  isUuid,
  otcTokenSchema,
  swapInfoSchema,
} from '@endaoment-frontend/types';
import { equalAddress } from '@endaoment-frontend/utils';

import { GetOrg } from './orgs';

export const GetAllTokens = new RequestHandler(
  'GetAllTokens',
  fetch => async (): Promise<Array<EVMToken | OTCToken>> => {
    const res = await fetch('/v2/tokens', {
      params: { tokenType: 'All' },
    });
    return z.object({ tokens: arraySchemaInvalidsFiltered(z.union([otcTokenSchema, evmTokenSchema])) }).parse(res)
      .tokens;
  },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v2/tokens?tokenType=All` }),
  },
);

export const GetEvmTokens = new RequestHandler(
  'GetEvmTokens',
  fetch =>
    async (chainId: number): Promise<Array<EVMToken>> => {
      const res = await fetch('/v2/tokens', {
        params: {
          tokenType: 'EvmToken',
          chainId,
        },
      });
      return z.object({ tokens: arraySchemaInvalidsFiltered(evmTokenSchema) }).parse(res).tokens;
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v2/tokens?tokenType=EvmToken&chainId=:chainId` }),
  },
);

export const GetToken = new RequestHandler('GetToken', () => async (tokenId: number): Promise<EVMToken | OTCToken> => {
  const list = await GetAllTokens.fetchFromDefaultClient([]);
  const token = list.find(t => t.id === tokenId);
  if (!token) throw new Error(`Token with id ${tokenId} not found`);
  return token;
});

type SwapInfoInput = {
  inputTokenId: number;
  /** A USDC value */
  amountIn: string;
  recipientEntityId: UUID;
  recipientEntityType: EntityType;
  chainId: number;
};
/**
 * Fetch swap information in order to assemble a donation transaction
 */
export const GetSwapInfo = new RequestHandler(
  'GetSwapInfo',
  fetch =>
    async (
      amount: bigint,
      token: Pick<EVMToken, 'chainId' | 'contractAddress' | 'id'>,
      recipient: DonationRecipient,
      chainId: number,
    ): Promise<SwapInfo> => {
      // TODO: Figure out if we can use `multichain` lib instead
      const currentNetworkSettings = defaults.network.supportedNetworkSettings.find(n => n.chainId === chainId);
      if (!currentNetworkSettings) throw new Error(`No network settings found for chainId ${chainId}`);
      if (token.chainId !== chainId)
        throw new Error(`Token chainId ${token.chainId} does not match chainId ${chainId}`);

      // In the case of a stablecoin, we don't need to swap
      if (equalAddress(token.contractAddress, currentNetworkSettings.baseToken.contractAddress)) {
        return {
          amountIn: amount,
          swapWrapper: '0x',
          callData: '',
          quote: {
            expectedUsdc: amount,
            minimumTolerableUsdc: amount,
            priceImpact: 0,
            // wallet donation to fund has a 0.5% fee. wallet donation to org has a 1.5% fee.
            endaomentFee: (amount * (recipient.type === 'org' ? 15n : 5n)) / 1000n,
          },
          tokenIn: token.contractAddress,
          chainId,
        };
      }

      // Fetch swap info for orgs
      if (recipient.type === 'org') {
        const orgId = isUuid(recipient.einOrId)
          ? recipient.einOrId
          : (await GetOrg.fetchFromDefaultClient([recipient.einOrId])).id;
        const res = await fetch('/v1/tokens/swap-info', {
          params: {
            amountIn: amount.toString(),
            inputTokenId: token.id,
            recipientEntityId: orgId,
            recipientEntityType: 'org',
            chainId,
          } satisfies SwapInfoInput,
        });
        return swapInfoSchema.parse(res);
      }

      // Fetch swap info for funds
      const res = await fetch('/v1/tokens/swap-info', {
        params: {
          amountIn: amount.toString(),
          inputTokenId: token.id,
          recipientEntityId: recipient.id,
          recipientEntityType: 'fund',
          chainId,
        } satisfies SwapInfoInput,
      });
      return swapInfoSchema.parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/tokens/swap-info`,
    }),
    augmentArgs: ([amount, token, recipient, chainId]) => [
      amount.toString(),
      token.id,
      recipient.type === 'fund' ? `Fund - ${recipient.id}` : `Org - ${recipient.einOrId}`,
      chainId,
    ],
  },
);

export const GetTokenPrice = new RequestHandler(
  'GetTokenPrice',
  fetch =>
    async (tokenId: number): Promise<number> => {
      const res = await fetch('/v1/tokens/price', {
        params: {
          id: tokenId,
        },
      });
      return z.number({ coerce: true }).parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/tokens/price`,
    }),
  },
);
