import { getAccessToken } from '@privy-io/react-auth';
import { signMessage } from '@wagmi/core';
import { lazy, useCallback, useEffect, useState } from 'react';
import { useConfig } from 'wagmi';

import { useAuth, useWalletModal } from '@endaoment-frontend/authentication';
import type { LoginDTO } from '@endaoment-frontend/types';
import { makeValidLoginSignature } from '@endaoment-frontend/utils';

declare global {
  interface Window {
    admin: {
      sign: () => void;
      toggleDevtools: () => void;
    };
  }
}

export const AdminFunctions = () => {
  const { isSignedIn, authAddress } = useAuth();
  const { showWallet } = useWalletModal();
  const config = useConfig();
  const [showDevtools, setShowDevtools] = useState(false);

  const sign = useCallback(async () => {
    if (!isSignedIn) {
      await showWallet();
      return;
    }

    if (!authAddress) throw new Error('No authenticated address found');

    const loginInfo = makeValidLoginSignature(authAddress);
    const signedMessage = await signMessage(config, { message: loginInfo.message });

    const privyToken = await getAccessToken();
    if (!privyToken) throw new Error('No privy token found');

    const dto: LoginDTO = {
      type: 'admin',
      activeWalletAddress: authAddress,
      privyToken,
      signature: signedMessage,
      signatureDateUtc: loginInfo.date,
    };
    console.info(JSON.stringify(dto, undefined, 2));
  }, [authAddress, config, isSignedIn, showWallet]);
  const toggleDevtools = useCallback(() => setShowDevtools(prev => !prev), []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.admin = {
        sign,
        toggleDevtools,
      };
    }
  }, [sign, toggleDevtools]);

  return <>{!!showDevtools && <ReactQueryDevtoolsProduction />}</>;
};

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(d => ({
    default: d.ReactQueryDevtools,
  })),
);
