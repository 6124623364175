import { useField } from 'formik';

export const useUsefulField = <T>(name: string) => {
  const [field, meta, helpers] = useField<T>(name);
  const { value, onChange, onBlur } = field;
  const { touched, error } = meta;
  const { setValue, setTouched, setError } = helpers;

  return {
    value,
    onChange,
    onBlur,
    touched,
    error,
    setValue,
    setTouched,
    setError,
  };
};
