import clsx from 'clsx';
import { useState } from 'react';
import { match } from 'ts-pattern';
import { useDebounce } from 'use-debounce';
import type { Chain } from 'viem';
import { useAccount, useSwitchChain } from 'wagmi';

import { useAuth, useAuthType, useSignOut } from '@endaoment-frontend/authentication';
import { config } from '@endaoment-frontend/config';
import { ChainIcon } from '@endaoment-frontend/ui/icons';
import { Button, Modal, Pill } from '@endaoment-frontend/ui/shared';

import styles from './ChainSwitchModal.module.scss';

export const ChainSwitchModal = () => {
  const { chainId: currentChainId } = useAccount();
  const { isSignedIn } = useAuth();
  const { isWalletAuth } = useAuthType();
  const signOut = useSignOut();

  const [isOnSupportedChain] = useDebounce(
    !currentChainId || config.chains.map(v => v.id).includes(currentChainId),
    2000,
  );
  const isOpen = isSignedIn && isWalletAuth && !isOnSupportedChain;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        return;
      }}
      size='2xl'
      isCentered
      className={styles.modal}
      contentClassName={styles['modal-content']}
      showCloseButton={false}>
      <h2 className={styles.title}>Please Switch Network</h2>
      <p className={styles['unsupported-blurb']}>
        You are connected to an unsupported network. Please select a network from the list below or disconnect.
      </p>
      <div className={styles['chain-list']}>
        {config.chains.map(chain => (
          <SwitchToNetworkButton key={chain.id} chain={chain} />
        ))}
      </div>
      <Button size='medium' variation='purple' filled onClick={signOut}>
        Disconnect
      </Button>
    </Modal>
  );
};

const SwitchToNetworkButton = ({ chain }: { chain: Chain }) => {
  const { switchChain, isPending } = useSwitchChain();
  const [isHovered, setIsHovered] = useState(false);

  const switchText = match({ isHovered, isPending })
    .with({ isPending: true }, () => 'Switching...')
    .with({ isHovered: true }, () => 'Switch')
    .otherwise(() => undefined);

  return (
    <Button
      className={clsx(styles['chain'], styles[`chain--${chain.id}`])}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => switchChain({ chainId: chain.id })}>
      <div className={styles['chain-icon']}>
        <ChainIcon chainId={chain.id} />
      </div>
      {chain.name}
      {!!switchText && (
        <Pill size='tiny' className={styles['switch']}>
          {switchText}
        </Pill>
      )}
    </Button>
  );
};
