import Image from 'next/image';
import Link from 'next/link';
import type { ReactNode } from 'react';

import { routes } from '@endaoment-frontend/routes';
import { Button, notfoundImage } from '@endaoment-frontend/ui/shared';

import styles from './ErrorPage.module.scss';

export const ErrorPage = ({ message, children }: { message: string; children?: Array<ReactNode> | ReactNode }) => {
  return (
    <div className={styles['container']}>
      <Image src={notfoundImage} width={400} alt='' priority />
      <h1 className={styles['not-found']}>{message}</h1>
      <Button as={Link} href={routes.app.dashboard()} filled variation='purple'>
        Go Home
      </Button>
      {children}
    </div>
  );
};
