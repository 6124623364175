import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { match, P } from 'ts-pattern';
import { useChainId } from 'wagmi';

import { useAuthType } from '@endaoment-frontend/authentication';
import { config } from '@endaoment-frontend/config';
import { useIsMobile } from '@endaoment-frontend/hooks';
import { getChainNameForChainId } from '@endaoment-frontend/multichain';
import { routes } from '@endaoment-frontend/routes';
import type { PortfolioCategory, PortfolioFinancial } from '@endaoment-frontend/types';
import { ChainIcon } from '@endaoment-frontend/ui/icons';
import { Button, cardClassNames, Pill, Tooltip } from '@endaoment-frontend/ui/shared';
import {
  calculateExpenseRatio,
  formatBasisPointsToPercent,
  formatCurrency,
  formatNumber,
  formatUsdc,
  getDateStringForPortfolioPerformance,
} from '@endaoment-frontend/utils';

import styles from './PortfolioGroupCard.module.scss';

const MotionLink = motion(Link);

export const PortfolioGroupCard = ({
  portfolios: portfoliosProp,
  className,
  onAllocate,
}: {
  portfolios: Array<PortfolioFinancial> | PortfolioFinancial;
  className?: string;
  onAllocate?: (portfolio: PortfolioFinancial) => void;
}) => {
  const portfolios = Array.isArray(portfoliosProp) ? portfoliosProp : [portfoliosProp];
  const currentChainId = useChainId();

  // Grouped display assumes that all basic portfolio data is the same,
  // while hiding any information (e.g. performance, allocated $, etc.) that will probably differ
  const isGrouped = portfolios.length > 1;
  // Portfolio selection order is:
  // 1. Portfolio on same chain as user
  // 2. Social login chain portfolio
  // 3. First portfolio in array
  const portfolio =
    portfolios.find(p => p.chainId === currentChainId) ||
    portfolios.find(p => p.chainId === config.socialLoginChainId) ||
    portfolios[0];

  const {
    name,
    type,
    logoUrl,
    id,
    chainId,
    categories,
    description,
    aipPerSecondFeesWad,
    depositFeeBps,
    totalInvestedInPortfolio,
    cap,
    providerFeeBps,
  } = portfolio;

  const { isMobile } = useIsMobile();
  const { isSocialAuth } = useAuthType();
  const [isExpanded, setIsExpanded] = useState(false);
  const isPositive = portfolio.quarterlyPerformanceBps && portfolio.quarterlyPerformanceBps > 0;

  const dateString = getDateStringForPortfolioPerformance(portfolio);

  const renderCurrentPerformance = match(portfolio)
    .with({ quarterlyPerformanceBps: P.not(P.nullish) }, ({ quarterlyPerformanceBps }) => (
      <div>
        <span>
          <em className={isPositive ? styles['positive'] : styles['negative']}>
            {!!isPositive && '+'}
            {formatBasisPointsToPercent(quarterlyPerformanceBps)}
          </em>
        </span>
        <span>{dateString}</span>
      </div>
    ))
    .with({ currentApyBps: P.not(P.nullish) }, ({ currentApyBps }) => (
      <div>
        <span>
          <em>{formatBasisPointsToPercent(currentApyBps)}</em> APY
        </span>
        <span>{dateString}</span>
      </div>
    ))
    .otherwise(() => <div />);

  // Show performance if the portfolio is a TPlusN type (all T+Ns will have same, offchain, performance) or if is a single/ungrouped portfolio
  // All crypto/chain-specific portfolios can have differing performance, so we hide
  const showPerformance = !isGrouped || portfolio.type === 'TPlusN' || portfolio.type === 'PrivateWealth';

  return (
    <MotionLink
      href={routes.app.portfolio({ id })}
      className={clsx(
        cardClassNames.base,
        cardClassNames.removePadding,
        styles['container'],
        isExpanded && styles['container--expanded'],
        className,
      )}
      onHoverStart={() => !isMobile && setIsExpanded(true)}
      onHoverEnd={() => setIsExpanded(false)}>
      <div className={styles['icon']}>
        <Image src={logoUrl} alt='' width={100} height={100} priority />
        {!isSocialAuth && !isGrouped && (
          <Tooltip
            content={`This portfolio is deployed on ${getChainNameForChainId(chainId)}`}
            placement='top'
            className={styles['chain-icon']}>
            <ChainIcon chainId={chainId} filled light />
          </Tooltip>
        )}
      </div>
      <div className={styles['title']}>
        <h6>
          <b>{name}</b>
        </h6>
      </div>
      <div className={styles['categories']}>
        {categories.map(category => (
          <PortfolioCategoryPill key={category.id} category={category} />
        ))}
      </div>
      {!!showPerformance && <div className={styles['performance']}>{renderCurrentPerformance}</div>}
      <AnimatePresence>
        {!!isExpanded && (
          <motion.div
            className={styles['details']}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            style={{ overflow: 'hidden' }}>
            <p>{description}</p>
            <div className={styles['info-table']}>
              {(!!aipPerSecondFeesWad || !!providerFeeBps) && (
                <span>
                  Expense Ratio:{' '}
                  <em>
                    {formatNumber(
                      calculateExpenseRatio({
                        aipPerSecondFeesWad,
                        providerFeeBps,
                      }),
                      { digits: 5, fractionDigits: 4, stripZeros: true },
                    )}
                    %
                  </em>
                </span>
              )}
              <span>
                Entry Fee: <em>{formatBasisPointsToPercent(depositFeeBps)}</em>
              </span>
              <span>
                Settlement Time: <em>{type === 'TPlusN' || type === 'PrivateWealth' ? '24 - 48 hours' : 'Instant'}</em>
              </span>
            </div>
            <div className={styles['info-footer']}>
              <span>
                {match({ isGrouped, isSocialAuth })
                  .with({ isGrouped: false }, () => (
                    <>
                      <em>{formatCurrency(formatUsdc(totalInvestedInPortfolio))} allocated</em>
                      {cap ? <em>{formatCurrency(formatUsdc(cap))} cap</em> : <p>No deposit cap</p>}
                    </>
                  ))
                  .with({ isSocialAuth: false }, () => (
                    <Pill size='tiny' variation='purple'>
                      Multichain Portfolio: {portfolios.length} deployments
                    </Pill>
                  ))
                  .otherwise(() => (
                    <></>
                  ))}
              </span>
              <Button
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onAllocate?.(portfolio);
                }}
                filled
                variation='portfolio'
                size='small'
                float={false}>
                New Position
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </MotionLink>
  );
};

export const PortfolioCategoryPill = ({ category }: { category: PortfolioCategory }) => {
  const style = {
    '--card-color': category.colorRgb,
    '--card-color-half': `${category.colorRgb}7F`,
    '--card-color-quarter': `${category.colorRgb}3F`,
    '--card-color-tenth': `${category.colorRgb}19`,
    '--card-color-eight': `${category.colorRgb}23`,
    '--shadow-color': `0px 18px 26px 0px var(--card-color-tenth)`,
  };
  return (
    <Pill size='tiny' className={clsx(styles['category-pill'])} style={style}>
      <Tooltip content={category.description} placement='top'>
        {category.name}
      </Tooltip>
    </Pill>
  );
};
