import { trackEvent } from '@phntms/next-gtm';
import { useQueryClient } from '@tanstack/react-query';
import type { Ref } from 'react';
import { forwardRef, useReducer, useState } from 'react';
import { P, match } from 'ts-pattern';

import { GetFundActivity, GetOrgActivity, GetUserActivity } from '@endaoment-frontend/activities';
import {
  CreateGrantRecommendation,
  GetFund,
  GetFundGrants,
  GetOrg,
  GetRecommendationById,
  GetRecommendationsForFund,
  GetRecommendationsMadeByMe,
  GetRecommendationsMadeForMe,
  GetSubproject,
  GetUserCollaboratedFunds,
  GetUserFunds,
} from '@endaoment-frontend/api';
import { defaults } from '@endaoment-frontend/config';
import { useIdempotencyKey } from '@endaoment-frontend/hooks';
import { Loader, StepModal } from '@endaoment-frontend/ui/shared';
import { useShowFireworks } from '@endaoment-frontend/ui/smart';
import { convertUsdcToNumber } from '@endaoment-frontend/utils';

import type { SubformProps } from '../DonationWizard.types';
import { complexRoundUp } from '../helpers';

import { GrantAmountStep } from './GrantAmount';
import { GrantConfirmationStep } from './GrantConfirmation';
import { GrantInstructionsStep } from './GrantInstructions';
import { GrantOriginatingStep } from './GrantOrigination';
import { ViewGrant } from './ViewGrant';
import { InitiateAsyncGrant } from './grantRequests';

const grantSteps = ['amount', 'confirmation', 'instructions', 'originating', 'view'] as const;
const grantPages = grantSteps.length + 1;
type GrantStep = (typeof grantSteps)[number];
const transferFeePercent = BigInt(1000 - defaults.fees.transferBps / 10);

const useGrantFlowState = ({ rawState, rawStateSetters }: Pick<SubformProps, 'rawState' | 'rawStateSetters'>) => {
  const [step, setStep] = useReducer((_prev: GrantStep, next: GrantStep) => {
    trackEvent({ event: 'dw_wizard_progress', data: { dw_wizard_step: next, dw_wizard_mode: rawState.mode } });
    return next;
  }, 'originating');
  const [offsetAmount, setOffsetAmount] = useState<bigint>();

  return {
    wizard: {
      step,
      originFundId: rawState.originId,
      recipient: rawState.recipient,
      amount: rawState.amount,
      offsetAmount,
      instructions: rawState.grantInstructions,
      recommendationId: rawState.recommendationId,
    },
    setStep,
    setOriginFundId: rawStateSetters.setGrantOriginId,
    setRecipient: rawStateSetters.setRecipient,
    setAmount: rawStateSetters.setAmount,
    setOffsetAmount,
    setInstructions: rawStateSetters.setGrantInstructions,
  } as const;
};

const GrantFlowWithRef = ({ onClose, onReset, rawState, rawStateSetters }: SubformProps, ref?: Ref<HTMLDivElement>) => {
  const queryClient = useQueryClient();
  const flowState = useGrantFlowState({ rawState, rawStateSetters });
  const showFireworks = useShowFireworks();

  const {
    mutateAsync: initiateGrant,
    data: grantResult,
    status: grantStatus,
  } = InitiateAsyncGrant.useMutation({
    onSuccess: (data, [input]) => {
      // Clear dataLayer before sending ecommerce values
      trackEvent({ data: { ecommerce: null } });
      trackEvent({
        event: 'dw_grant',
        data: {
          grant_origin_id: input.originFundId,
          destination_id: match({ dataOrg: data.destinationOrg, inputRecipient: input.recipient })
            .with({ dataOrg: P.nonNullable }, ({ dataOrg }) => dataOrg.ein ?? dataOrg.id)
            .with(
              {
                inputRecipient: {
                  type: 'org',
                  einOrId: P.nonNullable,
                },
              },
              ({ inputRecipient }) => inputRecipient.einOrId,
            )
            .otherwise(() => undefined),
          ecommerce: {
            currency: 'USD',
            value: convertUsdcToNumber(data.netAmount, true),
            transaction_id: data.transactionHash,
          },
        },
      });

      // Move to success view
      flowState.setStep('view');
      showFireworks();

      // Shotgun refetch important data
      void Promise.all([
        GetFund.executeAndSave([input.originFundId]),
        GetFundGrants.executeAndSave([input.originFundId]),
        GetUserFunds.executeAndSave([]),
        GetFundActivity.executeAndSave([input.originFundId]),
        GetUserActivity.executeAndSave([]),
      ]);
      GetUserCollaboratedFunds.invalidateDefaultClientQuery();
      GetOrgActivity.invalidateDefaultClientQuery();

      if (data.destinationSubproject?.id) {
        GetSubproject.invalidateDefaultClientQuery([data.destinationSubproject.id]);
      }

      if (input.recommendationId) {
        GetRecommendationById.invalidateQuery(queryClient, [input.recommendationId]);
        GetRecommendationsMadeForMe.invalidateQuery(queryClient);
        GetRecommendationsForFund.invalidateQuery(queryClient);
      }
    },
  });
  // Key used to prevent duplicate grants/recommendations, generate once per flow
  const idempotencyKey = useIdempotencyKey();
  const { mutateAsync: createRecommendation, status: recommendStatus } = CreateGrantRecommendation.useMutation({
    onSuccess: (_d, [input]) => {
      GetRecommendationsForFund.invalidateQuery(queryClient, [input.collaboratingFundId]);
      GetRecommendationsMadeByMe.invalidateQuery(queryClient, []);

      trackEvent({
        event: 'dw_grant_recommendation',
        data: {
          grant_origin_id: input.collaboratingFundId,
          destination_id: input.orgId,
        },
      });

      setTimeout(() => onClose(), 2 * 1000);
    },
  });

  const calculatedOffsetFeeAmount = complexRoundUp(((flowState.wizard.amount ?? 0n) / transferFeePercent) * 1000n, {
    forceRoundUp: true,
  });

  const handleOffsetFees = () => {
    if (!flowState.wizard.offsetAmount && calculatedOffsetFeeAmount) {
      flowState.setOffsetAmount(calculatedOffsetFeeAmount);
    } else flowState.setOffsetAmount(undefined);
  };

  const steps = match(flowState.wizard)
    .with({ step: 'originating' }, ({ originFundId }) => {
      if (originFundId) {
        flowState.setStep('amount');

        return (
          <StepModal.Step
            key='originating'
            ref={ref}
            onClose={onClose}
            onBack={onReset}
            progress={{ current: 3, pages: grantPages }}
            header='New Grant'>
            <Loader size='l' />
          </StepModal.Step>
        );
      }

      return (
        <StepModal.Step
          key='originating'
          ref={ref}
          onClose={onClose}
          onBack={onReset}
          progress={{ current: 3, pages: grantPages }}
          header='New Grant'>
          <GrantOriginatingStep
            onClose={onClose}
            onSubmit={fund => {
              flowState.setOriginFundId(fund.id);
              flowState.setStep('amount');
            }}
          />
        </StepModal.Step>
      );
    })
    .with(
      { step: 'amount', originFundId: P.nonNullable, recipient: P.nonNullable },
      ({ originFundId, recipient, amount }) => {
        const handleBack = () => {
          flowState.setOriginFundId(undefined);
          flowState.setStep('originating');
        };

        return (
          <StepModal.Step
            key='amount'
            ref={ref}
            onClose={onClose}
            header='New Grant'
            progress={{ current: 4, pages: grantPages }}
            onBack={handleBack}>
            <GrantAmountStep
              onSubmit={a => {
                flowState.setAmount(a);
                flowState.setStep('instructions');
              }}
              originFundId={originFundId}
              destination={recipient}
              initialValues={{ amount: amount ?? 0n }}
              onRemoveOrigin={handleBack}
              // TODO: `onReset` does not do the proper job of resetting to pick another destination, given it uses DonationWizard's `onReset`, which takes to the `type` step
              onRemoveDestination={onReset}
            />
          </StepModal.Step>
        );
      },
    )
    .with({ step: 'instructions', originFundId: P.nonNullable }, ({ instructions, originFundId }) => (
      <StepModal.Step
        key='instructions'
        ref={ref}
        onClose={onClose}
        progress={{ current: 5, pages: grantPages }}
        header='New Grant'
        onBack={() => {
          flowState.setStep('amount');
        }}>
        <GrantInstructionsStep
          initialValues={instructions}
          onSubmit={i => {
            flowState.setInstructions(i);
            flowState.setOffsetAmount(undefined);
            flowState.setStep('confirmation');
          }}
          originFundId={originFundId}
        />
      </StepModal.Step>
    ))
    .with(
      {
        step: 'confirmation',
        originFundId: P.nonNullable,
        recipient: P.nonNullable,
        instructions: P.nonNullable,
      },
      ({ originFundId, recipient, instructions, amount, offsetAmount }) => (
        <StepModal.Step
          key='confirmation'
          ref={ref}
          onClose={onClose}
          onBack={() => flowState.setStep('instructions')}
          header='New Grant'
          progress={{ current: 6, pages: grantPages }}>
          <GrantConfirmationStep
            originFundId={originFundId}
            destination={recipient}
            grantAmount={offsetAmount ?? amount}
            grantInstructions={instructions}
            feeIsOffset={!!offsetAmount}
            calculatedOffsetFeeAmount={calculatedOffsetFeeAmount}
            onOffsetFees={handleOffsetFees}
            grantStatus={grantStatus}
            onSubmit={() =>
              initiateGrant([{ originFundId, recipient, amount, offsetAmount, instructions, idempotencyKey }])
            }
            onRemoveDestination={onReset}
            onRemoveOrigin={() => flowState.setStep('originating')}
            onRecommend={async () => {
              if (!originFundId || !recipient || recipient.type === 'fund') return;

              // TODO: See if we can change the API type to accept einOrId
              const org = await GetOrg.fetchFromDefaultClient([recipient.einOrId]);

              await createRecommendation([
                {
                  collaboratingFundId: originFundId,
                  amountUsdc: amount,
                  orgId: org.id,
                  subprojectId: recipient.subprojectId,
                  purpose: instructions.purpose,
                  recommender: instructions.recommender,
                  shareMyEmail: instructions.shareMyEmail,
                  specialInstructions: instructions.specialInstructions,
                  uuid: idempotencyKey,
                  // TODO: Confirm extraneous fields
                  offsetFee: false,
                },
              ]);
            }}
            recommendStatus={recommendStatus}
          />
        </StepModal.Step>
      ),
    )
    .with(
      { step: 'view', originFundId: P.nonNullable, recipient: P.nonNullable, instructions: P.nonNullable },
      ({ originFundId, recipient, instructions, amount, offsetAmount }) => (
        <StepModal.Step key='view' ref={ref} onClose={onClose} header='Grant'>
          {grantResult ? (
            <ViewGrant
              originFundId={originFundId}
              destination={recipient}
              grantAmount={offsetAmount ?? amount}
              grantInstructions={instructions}
              grantResult={grantResult}
            />
          ) : (
            <Loader size='l' />
          )}
        </StepModal.Step>
      ),
    )
    .run();
  return steps;
};
export const GrantFlow = forwardRef(GrantFlowWithRef);
