import { captureException } from '@sentry/nextjs';
import { usePlacesWidget } from 'react-google-autocomplete';

import { config } from '@endaoment-frontend/config';
import { COUNTRY_GEODATA, COUNTRY_ISO3166_ALPHA3 } from '@endaoment-frontend/constants';
import type { CountryCode, PhysicalAddress } from '@endaoment-frontend/types';
import { physicalAddressSchema } from '@endaoment-frontend/types';

const adrFieldMap: Record<string, keyof PhysicalAddress> = {
  'street-address': 'line1',
  'extended-address': 'line2',
  'locality': 'city',
  'region': 'state',
  'postal-code': 'zip',
  'country-name': 'country',
};

// A list of countries to reverse lookup the alpha3 code for
const googleCountryNameToAlpha3 = new Map<string, CountryCode>([
  ['United States', COUNTRY_ISO3166_ALPHA3.enum.USA],
  ['US', COUNTRY_ISO3166_ALPHA3.enum.USA],
  ...COUNTRY_ISO3166_ALPHA3.options.map<[string, CountryCode]>(v => [v, v]),
  ...COUNTRY_ISO3166_ALPHA3.options.map<[string, CountryCode]>(v => [COUNTRY_GEODATA[v].name, v]),
]);

const makeAddressFromAdrString = (adrString: string): PhysicalAddress => {
  const parts = adrString
    .replace(/<span class="/g, '')
    .split(/<\/span>,?/)
    .map(part => part.split(/">/).map(s => s.trim()))
    .filter(p => p.length === 2);

  const address = parts.reduce<Record<string, string>>((acc, part) => {
    const field = adrFieldMap[part[0]];

    if (field === 'country') {
      const code = googleCountryNameToAlpha3.get(part[1]);

      if (!code) {
        // Set to USA if not found
        captureException(new Error(`Could not find ${part[1]} in country list`));
        acc[field] = COUNTRY_ISO3166_ALPHA3.enum.USA;
        return acc;
      }

      acc[field] = code;
      return acc;
    }

    acc[field] = part[1];
    return acc;
  }, {});

  return physicalAddressSchema.parse(address);
};

export const useGoogleLocation = (onPlaceSelected: (address: PhysicalAddress) => void) => {
  const { ref, autocompleteRef } = usePlacesWidget<HTMLInputElement>({
    apiKey: config.googleMapsApiKey,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    onPlaceSelected: place => onPlaceSelected(makeAddressFromAdrString(place['adr_address'])),
    options: {
      fields: ['adr_address'],
      types: ['address'],
    },
  });

  return { ref, autocompleteRef };
};
