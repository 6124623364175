import type { Span } from '@sentry/nextjs';
import { addBreadcrumb, captureException, startSpan } from '@sentry/nextjs';
import type { Config, WriteContractReturnType } from '@wagmi/core';
import { getWalletClient, waitForTransactionReceipt } from '@wagmi/core';
import { useCallback, useMemo, useState } from 'react';
import { P, match } from 'ts-pattern';
import { TransactionNotFoundError, TransactionReceiptNotFoundError, type TransactionReceipt } from 'viem';
import { useConfig } from 'wagmi';

import { useAuth, useWalletModal } from '@endaoment-frontend/authentication';
import { addressSchema, type Address, type TransactionStatus } from '@endaoment-frontend/types';

import type { TransactionActionKey } from './transactions';
import { TransactionListUpdatedEvent, useManageTransactionList } from './useTransactionList';

type BasicArgs = Record<string, unknown>;
type ExecuteOptions = { resolveOnPending?: boolean };
type ExecuteReturnType =
  | { status: 'error'; error: unknown }
  | { status: 'pending'; transactionHash: Address; transactionChainId: number }
  | { status: 'success'; transactionHash: Address; transactionChainId: number; receipt: TransactionReceipt };

type PreTransactionCallback<T, Args extends BasicArgs> = (params: {
  args: Args;
  /** Address that executed the transaction */
  address: Address;
  wagmiConfig: Config;
  span: Span | undefined;
}) => Promise<T>;
type PostTransactionCallback<T, Args extends BasicArgs> = (params: {
  args: Args;
  /** Address that executed the transaction */
  address: Address;
  transaction: WriteContractReturnType;
  chainId: number;
  span: Span | undefined;
}) => T;

export type ReactionHookOptions<Args extends BasicArgs> = {
  actionName: TransactionActionKey;
  createTransaction: PreTransactionCallback<WriteContractReturnType, Args>;
  createDescription: PostTransactionCallback<string, Args>;
  createExtra?: PostTransactionCallback<unknown, Args>;
  handleError?: (error: unknown) => void;
  confirmations?: number;
};

type ReactionHookResult<Args extends BasicArgs> = {
  status: TransactionStatus;
  execute: (args: Args, options?: ExecuteOptions) => Promise<ExecuteReturnType>;
  reset: () => void;
  transactionHash?: Address;
  transactionChainId?: number;
};
type ReactionHookDisplayInfo = { transactionHash: Address; transactionChainId: number };

type CreateExecuteParams = {
  wagmiConfig: Config;
  showWallet: () => void;
  status: TransactionStatus;
  onStatusChange: (status: TransactionStatus, info?: ReactionHookDisplayInfo) => void;
  authAddress?: Address;
  addTransaction: ReturnType<typeof useManageTransactionList>['addTransaction'];
};
const isRejectedError = (e: unknown): boolean => {
  return match(e)
    .with(
      {
        code: P.union(
          -3200, // WalletConnect: user rejected
          4001, // MetaMask: user rejected
          'ACTION_REJECTED', // MetaMask: user rejected
        ),
      },
      () => true,
    )
    .with({ message: P.string.includes('User denied transaction signature') }, () => true)
    .otherwise(() => false);
};

const getWalletClientOrUndefined = async (wagmiConfig: Config) => {
  try {
    return await getWalletClient(wagmiConfig);
  } catch {
    return undefined;
  }
};

/**
 * Create a function that executes a transaction and handles all the necessary state changes
 * Only exported for testing, do not use directly
 **/
export const createExecute =
  <Args extends BasicArgs>({
    wagmiConfig,
    actionName,
    createDescription,
    createTransaction,
    createExtra,
    confirmations,
    handleError,
    status,
    onStatusChange,
    showWallet,
    authAddress,
    addTransaction,
  }: CreateExecuteParams & ReactionHookOptions<Args>) =>
  async (args: Args, options?: ExecuteOptions): Promise<ExecuteReturnType> => {
    if (status === 'waiting' || status === 'pending')
      return {
        status: 'error',
        error: new Error('Transaction already in progress'),
      } as const;

    const walletClient = await getWalletClientOrUndefined(wagmiConfig);
    if (!authAddress || !walletClient) {
      showWallet();
      onStatusChange('rejected');
      return { status: 'error', error: new Error('User must be authenticated') } as const;
    }

    addBreadcrumb({
      message: `Starting execution cycle for ${actionName.toLowerCase().replace(/ |_/g, '-')}`,
      level: 'info',
      data: {
        address: authAddress,
      },
    });

    onStatusChange('waiting');

    let transaction: Address;
    let chainId: number;
    try {
      addBreadcrumb({
        message: 'Waiting for transaction to be created',
        data: {
          address: authAddress,
        },
      });
      const transactionPromise = startSpan(
        {
          name: 'create-transaction',
        },
        span =>
          createTransaction({
            args,
            address: authAddress,
            wagmiConfig,
            span,
          }),
      );
      addBreadcrumb({
        level: 'log',
        type: 'transaction',
        category: 'transaction.init',
        message: `Initiated transaction for ${actionName}`,
        data: {
          address: authAddress,
        },
      });
      transaction = await transactionPromise;

      // There seems to be a bug in viem that causes transaction hashes with a leading 0 to omit that 0 when returning the transaction
      // This attempts to fix that by adding the 0 back in if it's missing
      if (transaction.length === 65) {
        const replacementHash = addressSchema.parse(transaction.replace('0x', '0x0'));
        captureException('WAGMI gave us a bad transaction hash', {
          tags: {
            transactions: actionName.toLowerCase().replace(/ |_/, '-'),
            address: authAddress,
            badHash: transaction,
            hash: replacementHash,
          },
          level: 'error',
        });
        transaction = replacementHash;
      }

      // Create variable for chainId after transaction creation since the process may have forced a chain change
      chainId = await walletClient.getChainId();
      onStatusChange('waiting', { transactionHash: transaction, transactionChainId: chainId });
      console.info(`Transaction ${transaction} created on chain ${chainId}`);
      addBreadcrumb({
        message: 'Transaction sent to blockchain',
        category: 'transaction.sent',
        type: 'transaction',
        level: 'log',
        data: { transaction, address: authAddress, chainId },
      });

      addBreadcrumb({
        message: 'Storing transaction in browser storage',
        data: { transaction, address: authAddress, chainId },
      });

      const description = startSpan(
        {
          name: 'create-description',
        },
        span =>
          createDescription({
            args,
            address: authAddress,
            transaction,
            chainId,
            span,
          }),
      );
      const extra = startSpan(
        {
          name: 'create-extra',
        },
        span =>
          createExtra?.({
            args,
            address: authAddress,
            transaction,
            chainId,
            span,
          }),
      );
      addTransaction(actionName, transaction, description, chainId, extra);

      onStatusChange('pending', { transactionHash: transaction, transactionChainId: chainId });
      addBreadcrumb({
        message: 'Transaction successfully sent to blockchain and stored in browser storage',
        data: { transaction, address: authAddress, chainId },
      });
    } catch (e) {
      console.warn(e);

      // Log errors that aren't actual rejections
      if (isRejectedError(e)) {
        addBreadcrumb({ message: 'Transaction was rejected by user', level: 'info', data: { address: authAddress } });
        onStatusChange('rejected');
      } else {
        onStatusChange('error');
        // Send unknown error details to Sentry
        captureException(e, {
          tags: {
            transactions: actionName.toLowerCase().replace(/ |_/, '-'),
            address: authAddress,
          },
          level: 'error',
        });
      }
      handleError?.({ error: e });
      return { status: 'error', error: e } as const;
    }

    if (!transaction || !chainId) {
      onStatusChange('error');
      return { status: 'error', error: new Error('Transaction hash or chainId is undefined') } as const;
    }

    // Initiate waiting for transaction completion regardless of whether the user wants to wait for it
    // This is to ensure that the transaction status is properly tracked in the hook regardless of the setting on execute
    const completionPromise = completeWaitingTransactionExecution({
      wagmiConfig,
      onStatusChange,
      handleError,
      actionName,
      confirmations,
      transaction,
      chainId,
    });
    if (options?.resolveOnPending) {
      return { status: 'pending', transactionHash: transaction, transactionChainId: chainId } as const;
    }
    return await completionPromise;
  };
const completeWaitingTransactionExecution = async <Args extends BasicArgs>({
  wagmiConfig,
  onStatusChange,
  handleError,
  actionName,
  confirmations,
  transaction,
  chainId,
}: Pick<CreateExecuteParams, 'onStatusChange' | 'wagmiConfig'> &
  Pick<ReactionHookOptions<Args>, 'actionName' | 'confirmations' | 'handleError'> & {
    transaction: Address;
    chainId: number;
  }): Promise<ExecuteReturnType> => {
  try {
    const receipt = await waitForTransactionReceipt(wagmiConfig, {
      hash: transaction,
      confirmations,
      chainId,
      timeout: (confirmations || 2) * 60_000,
    });
    onStatusChange('success');
    return { status: 'success', receipt, transactionHash: transaction, transactionChainId: chainId } as const;
  } catch (e) {
    if (e instanceof TransactionNotFoundError || e instanceof TransactionReceiptNotFoundError) {
      addBreadcrumb({
        message: `Transaction ${transaction} not found`,
        level: 'warning',
        data: {
          transactions: actionName.toLowerCase().replace(/ |_/, '-'),
        },
      });
    } else {
      // Send unknown error details to Sentry
      captureException(e, {
        tags: {
          transactions: actionName.toLowerCase().replace(/ |_/, '-'),
        },
        level: 'error',
      });
    }
    onStatusChange('error');
    handleError?.({ error: e });
    return { status: 'error', error: e } as const;
  }
};

export const useGenerateReactionHook = <Args extends BasicArgs>({
  actionName,
  createTransaction,
  createDescription,
  createExtra,
  handleError,
  confirmations = 2,
}: ReactionHookOptions<Args>): ReactionHookResult<Args> => {
  const { authAddress } = useAuth();
  const { showWallet } = useWalletModal();
  const wagmiConfig = useConfig();

  const [status, setStatus] = useState<TransactionStatus>('none');
  const [displayInfo, setDisplayInfo] = useState<ReactionHookDisplayInfo>();
  const onStatusChange = useCallback((status: TransactionStatus, info?: ReactionHookDisplayInfo) => {
    setStatus(status);
    // Only update display info if it's not already set, we don't want to overwrite an existing transaction with a undefined one
    if (info) setDisplayInfo(info);
  }, []);
  const reset = useCallback(() => {
    setStatus('none');
    setDisplayInfo(undefined);
  }, []);

  const { addTransaction } = useManageTransactionList();
  TransactionListUpdatedEvent.useEventListener(({ detail }) => {
    if (
      detail.transaction.type !== actionName ||
      !displayInfo ||
      detail.transaction.hash !== displayInfo.transactionHash
    )
      return;
    setStatus(detail.transaction.status);
  });

  const execute = useMemo(
    () =>
      createExecute({
        wagmiConfig,
        actionName,
        createDescription,
        createTransaction,
        createExtra,
        confirmations,
        handleError,
        showWallet,
        authAddress,
        addTransaction,
        status,
        onStatusChange,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authAddress, actionName],
  );

  return {
    ...displayInfo,
    status,
    execute,
    reset,
  } as const;
};
