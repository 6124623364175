import type { As } from '@chakra-ui/react';
import { Box, Skeleton, SkeletonCircle } from '@chakra-ui/react';
import clsx from 'clsx';
import type { MouseEventHandler } from 'react';
import { P, match } from 'ts-pattern';

import { GetSubproject, GetUserIdentity } from '@endaoment-frontend/api';
import { UserAvatar, useEnsNameWithFallback } from '@endaoment-frontend/multichain';
import type { Address, PolymorphicProps, UUID } from '@endaoment-frontend/types';
import { formatPhysicalAddress, formatShortAddress } from '@endaoment-frontend/utils';

import styles from './MiniEntityDetails.module.scss';
import type { MiniOrgDetailsProps } from './MiniOrgDetails';
import { MiniOrgDetails } from './MiniOrgDetails';

export const componentMatch = (props: { as?: As; onClick?: MouseEventHandler }) =>
  match(props)
    .returnType<As | undefined>()
    .with({ as: P.not(P.nullish) }, ({ as }) => as)
    .with({ onClick: P.not(P.nullish) }, () => 'button')
    .otherwise(() => undefined);

// Subprojects render the same component as Orgs, but need to fetch the Subproject data instead
export const MiniSubprojectDetailsWithQuery = <Tag extends As>({
  subprojectId,
  ...props
}: Omit<MiniOrgDetailsProps<Tag>, 'org'> & { subprojectId: UUID }) => {
  const { data: subproject } = GetSubproject.useQuery([subprojectId]);

  if (!subproject) return <MiniLoadingDetails />;

  return <MiniOrgDetails org={subproject} {...props} />;
};

export const MiniLoadingDetails = <Tag extends As>({
  className,
  padding = true,
  ...props
}: PolymorphicProps<'div', Tag, { padding?: boolean }>) => {
  return (
    <Box
      className={clsx(styles['entity-details'], !padding && styles['entity-details--nopad'], className)}
      as={componentMatch(props)}
      {...props}>
      <SkeletonCircle width='3rem' height='3rem' mr={1} />
      <h6>
        <Skeleton width={100} height={5} mb={2} borderRadius={3} />
        <Skeleton width={30} height={2} borderRadius={10} />
      </h6>
    </Box>
  );
};

export const MiniWalletDetails = <Tag extends As>({
  address,
  balance,
  className,
  ...props
}: PolymorphicProps<'div', Tag, { address: Address; balance?: string }>) => {
  const { data: ensName } = useEnsNameWithFallback({ address });
  const { data: userInfo } = GetUserIdentity.useQuery([], { enabled: !!address });

  return (
    <Box
      className={clsx(styles['entity-details'], styles['entity-details--wallet'], className)}
      as={componentMatch(props)}
      {...props}>
      <UserAvatar address={address} />
      <h6>
        {ensName ?? formatShortAddress(address)}
        <span>{balance || (ensName && formatShortAddress(address))}</span>
      </h6>
      {!!userInfo && (
        <p className={styles['user-pii']}>
          {userInfo.email}
          <span>{formatPhysicalAddress(userInfo.address, true)}</span>
        </p>
      )}
    </Box>
  );
};
