import type { Chain } from 'viem';

import { config, defaults } from '@endaoment-frontend/config';
import type { Address } from '@endaoment-frontend/types';

type NetworkSettings = (typeof defaults)['network']['supportedNetworkSettings'][number];

const getCurrentNetworkSettings = (chainId: number): NetworkSettings => {
  const currentNetworkSettings = defaults.network.supportedNetworkSettings.find(n => n.chainId === chainId);
  if (!currentNetworkSettings) throw new Error(`No network settings found for chainId ${chainId}`);

  return currentNetworkSettings;
};

export const isSupportedChain = (chainId: number): boolean => {
  try {
    getCurrentNetworkSettings(chainId);
    return true;
  } catch {
    return false;
  }
};

export const getChainForChainId = (chainId: number): Chain => {
  const currentChain = config.chains.find(c => c.id === chainId);
  if (!currentChain) throw new Error(`No chain found for chainId ${chainId}`);

  return currentChain;
};

export const getContractAddressForChain = (
  chainId: number,
  contractName: keyof NetworkSettings['contracts'],
): Address => getCurrentNetworkSettings(chainId).contracts[contractName];

export const getNativeTokenForChain = (chainId: number): NetworkSettings['nativeToken'] =>
  getCurrentNetworkSettings(chainId).nativeToken;

export const getStablecoinForChain = (chainId: number): NetworkSettings['baseToken'] =>
  getCurrentNetworkSettings(chainId).baseToken;

const STATIC_CHAIN_NAMES: Record<number, string> = {
  1: 'Ethereum',
  5: 'Goerli',
  10: 'Optimism',
  137: 'Polygon',
  8453: 'Base',
  31337: 'Foundry',
  31338: 'Foundry 2',
  84531: 'Base Goerli',
  84532: 'Base Sepolia',
  11_155_111: 'Sepolia',
};
export const getChainNameForChainId = (chainId: number): string => {
  try {
    return getChainForChainId(chainId).name;
  } catch {
    return STATIC_CHAIN_NAMES[chainId] ?? 'Unknown Chain';
  }
};
