import { trackEvent } from '@phntms/next-gtm';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { Stripe, StripeElements } from '@stripe/stripe-js';
import { useMutation } from '@tanstack/react-query';
import { useState, type ComponentPropsWithoutRef, type FormEvent } from 'react';
import { P, match } from 'ts-pattern';

import { routes } from '@endaoment-frontend/routes';
import type { StripePaymentType, UUID } from '@endaoment-frontend/types';
import { ErrorDisplay } from '@endaoment-frontend/ui/forms';
import { Button, Loader } from '@endaoment-frontend/ui/shared';
import { useShowFireworks } from '@endaoment-frontend/ui/smart';

import wizardStyles from '../DonationWizard.module.scss';

import { ViewCreditDonation } from './ViewCreditDonation';

const submitStripePayment = async ({
  event,
  elements,
  stripe,
  pledgeId,
}: {
  event: FormEvent<HTMLFormElement>;
  stripe: Stripe | null;
  elements: StripeElements | null;
  pledgeId: UUID;
}) => {
  // We don't want to let default form submission happen here,
  // which would refresh the page.
  event.preventDefault();

  if (!stripe || !elements) {
    // Stripe.js hasn't yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    throw new Error('Stripe has not loaded yet.');
  }

  // Trigger form validation and wallet collection
  const { error: submitError } = await elements.submit();
  if (submitError) {
    // eslint-disable-next-line @typescript-eslint/only-throw-error
    throw submitError;
  }

  const returnUrl = new URL(window.location.href);

  // Set the return URL to the current page, with the donation wizard closed and the pledge ID in the query string
  returnUrl.searchParams.set('isDonationWizardOpen', 'false');
  returnUrl.searchParams.set('pledgeId', pledgeId);
  const { error } = await stripe.confirmPayment({
    //`Elements` instance that was used to create the Payment Element
    elements,
    confirmParams: {
      return_url: returnUrl.toString(),
    },
  });
  if (error) {
    // eslint-disable-next-line @typescript-eslint/only-throw-error
    throw error;
  }

  // Happy path of successful form validation
  return;
};
const useSubmitCreditDonation = () => {
  const showFireworks = useShowFireworks();
  return useMutation({
    mutationKey: ['StripeSubmitPayment'],
    mutationFn: submitStripePayment,
    onSuccess: () => {
      showFireworks();
    },
    retry: false,
  });
};

const convertStripeRawTypeToPaymentType = (rawType: string): StripePaymentType =>
  match<string, StripePaymentType>(rawType)
    .with(P.union('card'), () => 'Card')
    .with(
      P.union(
        'us_bank_account',
        'acss_debit',
        'au_becs_debit',
        'bacs_debit',
        'bancontact',
        'eps',
        'fpx',
        'giropay',
        'ideal',
        'p24',
        'pix',
        'sepa_debit',
        'sofort',
      ),
      () => 'Bank Transfer',
    )
    .with('cashapp', () => 'Cash App')
    .with('paypal', () => 'PayPal')
    .with('apple_pay', () => 'Apple Pay')
    .otherwise(() => 'Other');

export const CreditDonationStripeElement = ({
  pledgeId,
  destination,
  pledgedAmountCents,
  onClose,
}: ComponentPropsWithoutRef<typeof ViewCreditDonation> & {
  pledgeId: UUID;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { mutateAsync: submitCreditDonation, error, status } = useSubmitCreditDonation();
  const [paymentType, setPaymentType] = useState<StripePaymentType | undefined>();

  // We don't use Formik here because all of the form state is handled by Stripe
  return (
    <form
      onSubmit={async event => {
        await submitCreditDonation({
          event,
          stripe,
          elements,
          pledgeId,
        });
        // Clear dataLayer before sending ecommerce values
        trackEvent({ data: { ecommerce: null } });
        trackEvent({
          event: 'dw_donation_pledge',
          data: {
            pledge_type: 'cash',
            destination_type: destination.type,
            destination_id: destination.type === 'fund' ? destination.id : destination.einOrId,
            cash_method: paymentType,
            ecommerce: {
              currency: 'USD',
              value: pledgedAmountCents / 100.0,
              transaction_id: pledgeId,
            },
          },
        });
      }}>
      <ViewCreditDonation
        destination={destination}
        paymentType={paymentType}
        pledgedAmountCents={pledgedAmountCents}
        onClose={onClose}>
        <PaymentElement
          options={{ layout: 'accordion' }}
          onChange={e => setPaymentType(convertStripeRawTypeToPaymentType(e.value.type))}
        />
        {!!error && error instanceof Error && (
          <div>
            <ErrorDisplay error={error.message} />
          </div>
        )}
        <br />
      </ViewCreditDonation>
      <div className={wizardStyles['send-transaction']}>
        {match(status)
          .with('pending', () => <Loader size='s' />)
          .with('idle', () => (
            <Button
              variation='purple'
              type='submit'
              filled
              size='medium'
              float={false}
              disabled={!stripe || !elements || !paymentType}>
              Donate
            </Button>
          ))
          .with('error', () => (
            <Button
              variation='purple'
              type='submit'
              filled
              size='medium'
              float={false}
              disabled={!stripe || !elements || !paymentType}>
              Try Again
            </Button>
          ))
          .otherwise(() => (
            <></>
          ))}
        <p>
          Agree to Endaoment's <a href={routes.docs.privacyPolicy()}>privacy policy</a>
          {' and '}
          <a href={routes.docs.termsAndConditions()}>terms and conditions</a>
        </p>
      </div>
    </form>
  );
};
