import { z } from 'zod';

import { RequestHandler } from '@endaoment-frontend/data-fetching';
import type { Address, EntityType, UUID } from '@endaoment-frontend/types';
import { arraySchemaInvalidsFiltered } from '@endaoment-frontend/types';

import { sortAllocations } from './computeValues';
import type { TargetAllocation, TargetAllocationRebalanceOperation } from './types';
import {
  targetAllocationRebalanceOperationSchema,
  targetAllocationSchema,
  tradeRegistrationResponseSchema,
} from './types';

export const GetTargetAllocations = new RequestHandler(
  'GetTargetAllocations',
  fetch =>
    async (entityType: EntityType, id: UUID): Promise<Array<TargetAllocation>> => {
      const res = await fetch(`/v1/target-allocations/${entityType}/${id}`);
      return sortAllocations(arraySchemaInvalidsFiltered(targetAllocationSchema).parse(res));
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/target-allocations/:entityType/:id` }),
  },
);

type EntityAllocationUpdate = {
  portfolioId: UUID;
  percentage: number;
};
export const UpdateTargetAllocations = new RequestHandler(
  'UpdateTargetAllocations',
  fetch =>
    async (
      entityType: EntityType,
      id: UUID,
      rawTargetAllocations: Array<EntityAllocationUpdate>,
    ): Promise<Array<TargetAllocation>> => {
      const targetAllocations: Array<EntityAllocationUpdate> = rawTargetAllocations.map(ta => ({
        ...ta,
        percentage: Number(ta.percentage.toFixed(5)),
      }));
      const res = await fetch(`/v1/target-allocations/${entityType}/${id}`, {
        method: 'POST',
        body: {
          targetAllocations,
        },
      });
      const updatedAllocations = z
        .object({ configuredAllocations: arraySchemaInvalidsFiltered(targetAllocationSchema) })
        .parse(res).configuredAllocations;
      return sortAllocations(updatedAllocations);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/target-allocations/:entityType/:id` }),
  },
);

export const CalculateEntityRebalance = new RequestHandler(
  'CalculateEntityRebalance',
  fetch =>
    async (
      entityType: EntityType,
      id: UUID,
      computeCalldata: boolean,
      additionalBalance: bigint = 0n,
    ): Promise<Array<TargetAllocationRebalanceOperation>> => {
      const res = await fetch(`/v1/target-allocations/${entityType}/${id}/rebalance`, {
        params: {
          additionalBalance: additionalBalance.toString(),
          computeCalldata,
        },
      });
      const { operations } = z.object({ operations: z.array(targetAllocationRebalanceOperationSchema) }).parse(res);

      return operations;
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/target-allocations/:entityType/:id/rebalance` }),
  },
);

type RegisterTradeBody = {
  tradeTransactionHash: Address;
  chainId: number;
  isUserRebalance?: boolean;
  isAdminRebalance?: boolean;
  recommendationIds?: Array<UUID>;
};
const rebalanceTradeRegistrationResponseSchema = z.object({
  registeredTrades: z.array(tradeRegistrationResponseSchema),
  pendingTrades: z.array(tradeRegistrationResponseSchema),
});
export const RegisterRebalance = new RequestHandler(
  'RegisterRebalance',
  fetch =>
    async (
      tradeTransactionHash: Address,
      chainId: number,
      isUserRebalance: boolean = false,
    ): Promise<z.infer<typeof rebalanceTradeRegistrationResponseSchema>> => {
      const res = await fetch('/v1/trade/register', {
        method: 'POST',
        body: {
          tradeTransactionHash,
          chainId,
          isUserRebalance,
        } satisfies RegisterTradeBody,
      });

      return rebalanceTradeRegistrationResponseSchema.parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/trade/register` }),
  },
);
