import { Center, Flex } from '@chakra-ui/react';
import Link from 'next/link';

import { featureFlags } from '@endaoment-frontend/config';
import { routes } from '@endaoment-frontend/routes';
import type { UUID } from '@endaoment-frontend/types';
import { AllocationIcon, CardIcon, CheckmarkIcon } from '@endaoment-frontend/ui/icons';
import { ActionButton, Pill } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel } from '@endaoment-frontend/ui/smart';

import styles from './CreateFund.module.scss';

export const CreateFundComplete = ({ createdFundId }: { createdFundId?: UUID }) => {
  return (
    <>
      <EntityCardWithLabel
        label='New Fund Created'
        entity={createdFundId ? { type: 'fund', id: createdFundId } : undefined}
        link
      />
      <Flex justifyContent='stretch' gap='1rem' flexDir='column'>
        {createdFundId ? (
          <>
            <div className={styles['success-pill__container']}>
              <Pill color='green' size='tiny' className={styles['success-pill']}>
                <CheckmarkIcon color='currentColor' /> Fund Created
              </Pill>
            </div>
            {!featureFlags.disableTargetAllocation && (
              <ActionButton
                color='violet'
                as={Link}
                href={routes.app.fund({
                  id: createdFundId,
                  wizardParams: {
                    isFundWizardOpen: false,
                    isTargetAllocationModalOpen: true,
                    targetAllocationFundId: createdFundId,
                  },
                })}
                text='Set a Target Allocation'
                subtext='Set a preferred portfolio mix for this fund'>
                <AllocationIcon color='currentColor' />
              </ActionButton>
            )}
            <ActionButton
              color='fund'
              as={Link}
              href={routes.app.fund({
                id: createdFundId,
                wizardParams: {
                  isDonationWizardOpen: true,
                  isFundWizardOpen: false,
                  dwRecipient: {
                    type: 'fund',
                    id: createdFundId,
                  },
                },
              })}
              text='Add Assets'
              subtext='Donate crypto, stock or cash to this fund'>
              <CardIcon color='currentColor' />
            </ActionButton>
          </>
        ) : (
          <Center className={styles['please-wait']}>Please wait. Awaiting onchain confirmation of new fund...</Center>
        )}
      </Flex>
    </>
  );
};
