import type { NextRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

export const normalizePathName = (path: string) => {
  const cleanPath = path.match(/^\/([^?#]*)(\?.*)?(#.*)?$/)?.[1] || '';
  const choppedFront = cleanPath.charAt(0) === '/' ? cleanPath.slice(1) : cleanPath;
  const choppedEnd = choppedFront.charAt(choppedFront.length - 1) === '/' ? choppedFront.slice(0, -1) : choppedFront;
  return choppedEnd;
};

export const useIsRouting = (router: NextRouter) => {
  const [isRouting, setIsRouting] = useState(false);

  const normalizedPath = normalizePathName(router.pathname);
  const pathRef = useRef(normalizePathName(router.pathname));

  useEffect(() => {
    const handleChangeStart = () => {
      // Prevent setting state if the path hasn't changed
      if (normalizedPath === pathRef.current) return;

      pathRef.current = normalizedPath;
      setIsRouting(true);
    };
    const handleChangeComplete = () => {
      setIsRouting(false);
    };

    router.events.on('routeChangeStart', handleChangeStart);
    router.events.on('routeChangeComplete', handleChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleChangeStart);
      router.events.off('routeChangeComplete', handleChangeComplete);
    };
  }, [router, normalizedPath]);

  return isRouting;
};
