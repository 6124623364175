import { TrackingHeadScript } from '@phntms/next-gtm';
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as JotaiProvider } from 'jotai';
import { Suspense, type ReactNode } from 'react';

import { TransactionListProvider } from '@endaoment-frontend/blockchain-interactions';
import { featureFlags } from '@endaoment-frontend/config';
import { convertDehydratedStringToState, defaultQueryClient } from '@endaoment-frontend/data-fetching';
import { useIsMounted } from '@endaoment-frontend/hooks';
import { StylesProvider } from '@endaoment-frontend/ui/shared';
import { TypeformModalProvider } from '@endaoment-frontend/ui/smart';

import { AdminFunctions } from './AdminFunctions';
import { NdaoPrivyProvider } from './NdaoPrivyProvider';
import { Web3ReactionProvider } from './Web3ReactionProvider';

/**
 * This component is used to wrap all providers the app
 *
 * Do not use this in testing as you will be using adding a lot of potential bugs and unnecessary bloat
 **/
export const AppProvider = ({
  dehydratedState,
  children,
}: {
  dehydratedState?: string;
  children: Array<ReactNode> | ReactNode;
}) => {
  return (
    <>
      <TrackingHeadScript id={featureFlags.gaMeasurementId} isGTM />
      <StylesProvider>
        {/* QueryClientProvider MUST be above WagmiProvider */}
        <QueryClientProvider client={defaultQueryClient}>
          <NdaoPrivyProvider>
            <JotaiProvider>
              <HydrationBoundary state={convertDehydratedStringToState(dehydratedState)} />
              {children}
              <TypeformModalProvider />
              <TransactionListProvider />
              <Web3ReactionProvider />
              <Suspense fallback={null}>
                <AdminFunctions />
                <ClientOnlyQueryDevTools />
              </Suspense>
            </JotaiProvider>
          </NdaoPrivyProvider>
        </QueryClientProvider>
      </StylesProvider>
    </>
  );
};

const ClientOnlyQueryDevTools = () => {
  const isMounted = useIsMounted();

  if (!isMounted) return <></>;
  return <ReactQueryDevtools initialIsOpen={false} />;
};
