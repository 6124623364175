import { skipToken } from '@tanstack/react-query';
import { useEffect, type ComponentProps } from 'react';

import { featureFlags } from '@endaoment-frontend/config';
import type { DonationRecipient } from '@endaoment-frontend/types';
import { Checkbox } from '@endaoment-frontend/ui/forms';
import { QuestionIcon } from '@endaoment-frontend/ui/icons';
import { Tooltip } from '@endaoment-frontend/ui/shared';

import { GetTargetAllocations } from './requests';
import styles from './TargetAllocationSection.module.scss';

type TargetAllocationRebalanceCheckboxProps = Omit<ComponentProps<typeof Checkbox>, 'checked' | 'onChange'> & {
  recipient: DonationRecipient;
  isRebalanceRequested: boolean;
  onChange: (newVal: boolean) => void;
  additionalBalance: bigint;
};

const MINIMUM_REBALANCE_USDC = 50_000_000n;

export const TargetAllocationRebalanceCheckbox = ({
  recipient,
  isRebalanceRequested,
  onChange,
  additionalBalance,
  disabled,
  ...props
}: TargetAllocationRebalanceCheckboxProps) => {
  const isRecipientFund = recipient.type === 'fund';

  const { data: targetAllocations } = GetTargetAllocations.useQuery(
    isRecipientFund ? ['fund', recipient.id] : skipToken,
    {
      enabled: isRecipientFund,
    },
  );

  // This is a cheap version of the checks that occur in the backend
  const canRebalance =
    isRecipientFund &&
    !!targetAllocations &&
    targetAllocations.length > 0 &&
    additionalBalance >= MINIMUM_REBALANCE_USDC;

  useEffect(() => {
    if (!canRebalance) {
      onChange(false);
    }
  }, [canRebalance, onChange]);

  if (!canRebalance || featureFlags.disableTargetAllocation) {
    return <></>;
  }

  return (
    <Checkbox
      checked={isRebalanceRequested}
      onChange={onChange}
      label={
        <Tooltip content='Checking this box allows you to rebalance this fund to its target allocation once the donation is complete'>
          Allocate to Target & Rebalance Fund <QuestionIcon color='currentColor' width={14} height={14} />
        </Tooltip>
      }
      disabled={disabled}
      className={styles['rebalance-checkbox']}
      {...props}
    />
  );
};
