import { addBreadcrumb } from '@sentry/nextjs';
import { useAtom } from 'jotai';
import { atomWithReducer } from 'jotai/utils';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import type { BlockchainTransactionStatus, Address } from '@endaoment-frontend/types';

export type TransactionToast = {
  type: BlockchainTransactionStatus;
  title?: string;
  display?: ReactNode;
  link?: {
    label: string;
    href: string;
  };
  blurbOverride?: string;
  transactionHash?: Address;
  transactionChainId?: number;
};

type ToastAction = { type: 'add'; toast: TransactionToast } | { type: 'remove' };

const transactionToastAtom = atomWithReducer<TransactionToast | undefined, ToastAction>(undefined, (prev, action) => {
  if (!action) return prev;

  if (action.type === 'add') {
    addBreadcrumb({ level: 'info', category: 'toast.display', data: action.toast });
    return action.toast;
  }
  if (action.type === 'remove') {
    return undefined;
  }

  return prev;
});

export const useTransactionToast = () => {
  const [toast, dispatch] = useAtom(transactionToastAtom);

  const addToast = useMemo(() => (toast: TransactionToast) => dispatch({ type: 'add', toast }), [dispatch]);

  return {
    toast,
    addToast,
    removeToast: () => dispatch({ type: 'remove' }),
  };
};
