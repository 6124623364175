import { Popover, PopoverContent, PopoverTrigger, Portal, useModalContext } from '@chakra-ui/react';
import clsx from 'clsx';
import { useState } from 'react';

import { Button } from '@endaoment-frontend/ui/shared';

import styles from './TargetAllocationSection.module.scss';

export const PercentIncrementerInput = ({ value, onChange }: { value: number; onChange: (v: number) => void }) => {
  const modalContext = useModalContext();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      trigger='hover'
      placement='left-start'
      isOpen={isPopoverOpen}
      onOpen={() => setIsPopoverOpen(true)}
      onClose={() => setIsPopoverOpen(false)}
      openDelay={0}
      closeDelay={0}>
      <PopoverTrigger>
        <button
          className={clsx(styles['percent-increment'], isPopoverOpen && styles['percent-increment--active'])}
          data-testid='percent-incrementer'
          onMouseOver={() => setIsPopoverOpen(true)}>
          <b>{(value * 100).toFixed(0)}</b>%
        </button>
      </PopoverTrigger>
      <Portal containerRef={modalContext.dialogRef}>
        <PopoverContent className={clsx(styles['percent-increment__controls'])}>
          <div className={styles['percent-increment__controls--left']}>
            <Button size='tiny' float={false} onClick={() => onChange(value - 0.25)}>
              -25
            </Button>
            <Button size='tiny' float={false} onClick={() => onChange(value - 0.05)}>
              -5
            </Button>
          </div>
          <div className={styles['percent-increment__controls--right']}>
            <Button size='tiny' float={false} onClick={() => onChange(value + 0.05)}>
              +5
            </Button>
            <Button size='tiny' float={false} onClick={() => onChange(value + 0.25)}>
              +25
            </Button>
          </div>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
