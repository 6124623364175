import { type ReactNode } from 'react';
import { useMount } from 'react-use';

import { useAuth, useWalletModal } from '@endaoment-frontend/authentication';
import { Button } from '@endaoment-frontend/ui/shared';

import styles from './LoginGate.module.scss';

export const LoginGate = ({
  children,
  overridePrompt,
}: {
  children?: Array<ReactNode> | ReactNode;
  overridePrompt?: Array<ReactNode> | ReactNode;
}) => {
  const { isSignedIn } = useAuth();
  const { showWallet, isWalletModalOpen } = useWalletModal();

  useMount(() => {
    if (!isSignedIn && !isWalletModalOpen) {
      void showWallet();
    }
  });

  if (!isSignedIn || isWalletModalOpen) {
    if (overridePrompt) return overridePrompt;
    // TODO: Style this
    return (
      <div className={styles['login-gate']}>
        <h6>Please sign in to view</h6>
        <Button onClick={() => showWallet()}>Sign In</Button>
      </div>
    );
  }

  return children;
};
