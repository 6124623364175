import type { PrivyEvents } from '@privy-io/react-auth';
import { getAccessToken } from '@privy-io/react-auth';
import type { Config } from '@wagmi/core';

import { SignIn, SignOut, WhoAmI } from '@endaoment-frontend/api';
import { getLoginDtoFromPrivyLogin, GetPrivyWalletAddressForUser } from '@endaoment-frontend/authentication';
import { equalAddress } from '@endaoment-frontend/utils';

type MakeAuthCycleHandlerParams = {
  wagmiConfig: Config;
  signOut: () => Promise<void>;
};
type AuthCycleHandler = (...args: Parameters<NonNullable<PrivyEvents['login']['onComplete']>>) => Promise<void>;

export const makeAuthCycleHandler =
  ({ wagmiConfig, signOut }: MakeAuthCycleHandlerParams): AuthCycleHandler =>
  async (user, _isNewUser, wasAlreadyAuthenticated, loginMethod) => {
    if (!user.wallet || !user.wallet.address) {
      console.error('Logged a user in without a wallet');
      return;
    }

    // This can be either a Smart Account or an EOA depending on the authentication method
    const walletAddressToUse = await GetPrivyWalletAddressForUser.executeAndSave([user]);
    if (!walletAddressToUse) throw new Error('No wallet address found');

    // Check if we can early exit if the user is already signed in
    const ndaoToken = await WhoAmI.executeAndSave([]);
    if (wasAlreadyAuthenticated) {
      console.info('User was already authenticated');
      // Early exit when Privy and NDAO are in sync
      if (!!ndaoToken && equalAddress(walletAddressToUse, ndaoToken.wallet)) return;

      console.error('User was already authenticated on Privy but NDAO authentication failed');
      await signOut();
      throw new Error('User was already authenticated on Privy but NDAO authentication failed');
    }

    if (ndaoToken) {
      await SignOut.execute();
    }

    // Get token to send to the BE
    const privyToken = await getAccessToken();
    if (!privyToken) throw new Error('No privy token found');

    try {
      const loginDto = await getLoginDtoFromPrivyLogin({ wagmiConfig, user, loginMethod });
      const isSuccess = await SignIn.execute(loginDto);
      if (!isSuccess) throw new Error(`Failed to sign in (${loginDto.type})`);
      await WhoAmI.executeAndSave([]);
      return;
    } catch (e) {
      // We want to forcefully sign out the user if there is an error
      await signOut();
      console.error(e);
      throw e;
    }
  };
