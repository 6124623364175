import type { ComponentProps } from 'react';

import { GetFund, GetFundPositions } from '@endaoment-frontend/api';
import type { EntityPosition, UUID } from '@endaoment-frontend/types';
import { MultiProgressBar } from '@endaoment-frontend/ui/shared';

import { sortAllocations } from './computeValues';
import { GetTargetAllocations } from './requests';
import type { TargetAllocation } from './types';

export const convertTargetAllocationsToProgressBar = (
  targetAllocations: Array<TargetAllocation>,
): ComponentProps<typeof MultiProgressBar>['parts'] => {
  const totalPercentage = targetAllocations.reduce((acc, v) => acc + v.percentage, 0);
  return [
    ...sortAllocations(
      targetAllocations.map(v => ({ progress: v.percentage, color: 'currentColor', portfolioId: v.portfolioId })),
    ),
    {
      progress: 1 - totalPercentage,
      color: '#EA6B0E80',
      secondColor: '#EA6B0E50',
    },
  ];
};
export const convertPositionsToProgressBar = (
  positions: Array<EntityPosition>,
  targetAllocations: Array<TargetAllocation>,
  grantable: bigint,
): ComponentProps<typeof MultiProgressBar>['parts'] => {
  const { targetAllocationProgressParts, sumOfOtherPositions } = positions.reduce(
    ({ targetAllocationProgressParts, sumOfOtherPositions }, position) => {
      const allocationForPosition = targetAllocations.find(t => t.portfolioId === position.portfolio.id);
      if (!allocationForPosition)
        return {
          targetAllocationProgressParts,
          sumOfOtherPositions:
            sumOfOtherPositions +
            (position.currentMarketValue + position.inTransitBuyUsdcAmount + position.inTransitSellUsdcAmount),
        };
      return {
        targetAllocationProgressParts: [
          ...targetAllocationProgressParts,
          {
            progress: position.currentMarketValue + position.inTransitBuyUsdcAmount + position.inTransitSellUsdcAmount,
            color: 'currentColor',
            portfolioId: allocationForPosition.portfolioId,
          },
        ],
        sumOfOtherPositions,
      };
    },
    {
      targetAllocationProgressParts: [] as Array<
        ComponentProps<typeof MultiProgressBar>['parts'][number] & { portfolioId: UUID }
      >,
      sumOfOtherPositions: 0n,
    },
  );

  return [
    ...sortAllocations(targetAllocationProgressParts),
    { progress: grantable, color: '#EA6B0E80', secondColor: '#EA6B0E50' },
    { progress: sumOfOtherPositions, color: '#696F8C50' },
  ];
};

export const TargetAllocationOverview = ({ fundId }: { fundId: UUID }) => {
  const { data: targetAllocations } = GetTargetAllocations.useQuery(['fund', fundId], { enabled: !!fundId });
  const { data: fundPositions } = GetFundPositions.useQuery([fundId, 'fast'], { enabled: !!fundId });
  const { data: fund } = GetFund.useQuery([fundId], { enabled: !!fundId });

  return (
    <>
      <h6>Target Breakdown</h6>
      <MultiProgressBar
        parts={targetAllocations ? convertTargetAllocationsToProgressBar(targetAllocations) : []}
        breakdown
      />
      <h6>Current Breakdown</h6>
      <MultiProgressBar
        parts={
          targetAllocations && fundPositions
            ? convertPositionsToProgressBar(fundPositions.positions, targetAllocations, fund ? fund.usdcBalance : 0n)
            : []
        }
        breakdown
      />
    </>
  );
};
