import type { WriteContractReturnType } from '@wagmi/core';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';

import { useAuth } from '@endaoment-frontend/authentication';

import type { TransactionActionKey } from '../transactions';

import { addToListAtom, incrementFailureAtom, removeFromPendingAtom } from './transactionListAtoms';

/**
 * This is meant EXCLUSIVELY for handling external effects and should not be used without deep consideration
 */
export const useManageTransactionList = () => {
  const { isSignedIn, authAddress } = useAuth();

  const addToList = useSetAtom(addToListAtom);
  const removeTransaction = useSetAtom(removeFromPendingAtom);
  const incrementFailure = useSetAtom(incrementFailureAtom);

  const addTransaction = useCallback(
    (
      type: TransactionActionKey,
      hash: WriteContractReturnType,
      description: string,
      chainId: number,
      extra?: unknown,
    ) => {
      if (!isSignedIn || !authAddress)
        throw new Error('Attempted to add a transaction without a connected wallet address');
      addToList({
        hash,
        description,
        extra,
        type,
        wallet: authAddress,
        failCount: 0,
        chainId,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSignedIn, authAddress],
  );

  return { addTransaction, removeTransaction, incrementFailure } as const;
};
