import { NDAOEventManager } from '@endaoment-frontend/data-fetching';
import type { Address } from '@endaoment-frontend/types';

import type { TransactionActionKey } from '../transactions';

import type { StoredTransaction } from './useTransactionList';

export type TransactionListChangeEventDetails = {
  transaction: StoredTransaction;
};

export type PendingListChangeEventDetails = {
  hash: Address;
  type: TransactionActionKey;
};

export const TransactionListAddedEvent = new NDAOEventManager<TransactionListChangeEventDetails>(
  'transactionlistadded',
);
export const TransactionListUpdatedEvent = new NDAOEventManager<TransactionListChangeEventDetails>(
  'transactionlistupdated',
);

export const PendingListAddedEvent = new NDAOEventManager<PendingListChangeEventDetails>('pendinglistadded');
export const PendingListRemovedEvent = new NDAOEventManager<PendingListChangeEventDetails>('pendinglistremoved');
