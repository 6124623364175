import { type User } from '@privy-io/react-auth';
import { useQuery } from '@tanstack/react-query';
import type { Config } from '@wagmi/core';
import { getEnsName } from '@wagmi/core';
import { isAddress } from 'viem';
import { useAccount, useConfig } from 'wagmi';

import { GetUserIdentity } from '@endaoment-frontend/api';
import { defaults } from '@endaoment-frontend/config';
import { getChainForChainId } from '@endaoment-frontend/multichain';
import type { Address } from '@endaoment-frontend/types';
import { addressSchema } from '@endaoment-frontend/types';
import { formatShortAddress, formatStringSize } from '@endaoment-frontend/utils';

import { getEndaomentAccountName, isAdministrativeAccount } from './admin';
import { useAuth } from './useAuth';
import { extractIsSocialFromUser } from './useAuthType';

const getDisplayNameForWallet = async (
  wagmiConfig: Config,
  walletAddress: Address,
  chainId: number,
): Promise<string> => {
  // If the user is an admin, simply return the role
  if (isAdministrativeAccount(walletAddress)) return `${getEndaomentAccountName(walletAddress)} Wallet`;

  // Try to get the ENS name
  const ensName = await getEnsName(wagmiConfig, {
    address: addressSchema.parse(walletAddress),
    chainId,
  });
  if (ensName) return ensName;

  // If we couldn't find an ENS name on the current chain, try the default chain
  const fallbackUniversalResolverAddress = getChainForChainId(defaults.network.defaultChainId).contracts
    ?.ensUniversalResolver?.address;
  if (fallbackUniversalResolverAddress) {
    const fallbackEnsName = await getEnsName(wagmiConfig, {
      address: addressSchema.parse(walletAddress),
      chainId: defaults.network.defaultChainId,
      universalResolverAddress: fallbackUniversalResolverAddress,
    });
    if (fallbackEnsName) return fallbackEnsName;
  }

  // If we still couldn't find an ENS name, return the wallet address
  return walletAddress;
};

const getDisplayNameForSocial = (user: User | undefined, backup: string): string => {
  if (!user) return backup;
  const possibleNames =
    user.email?.address ??
    user.phone?.number ??
    user.google?.name ??
    user.google?.email ??
    user.apple?.email ??
    user.discord?.username ??
    user.farcaster?.displayName ??
    user.github?.username ??
    user.github?.name ??
    user.github?.email ??
    user.linkedin?.name ??
    user.linkedin?.email ??
    user.spotify?.name ??
    user.spotify?.email ??
    user.tiktok?.username ??
    user.tiktok?.name ??
    user.twitter?.username ??
    user.twitter?.name ??
    undefined;
  if (!possibleNames) return user.wallet?.address ?? backup;
  return possibleNames;
};

const formatShortAccountName = (name: string): string => {
  if (name === '') return '';
  if (isAddress(name)) return formatShortAddress(name);
  return formatStringSize(name, 32);
};

type AccountDisplayNameReturn = {
  accountName: string;
  shortAccountName: string;
};

export const useAccountDisplayName = (): AccountDisplayNameReturn => {
  const wagmiConfig = useConfig();
  const { user, authAddress } = useAuth();
  const { chainId } = useAccount();

  const authAddressOrEmpty = authAddress ?? '';
  const isSocial = extractIsSocialFromUser(user);

  const { data: walletName = authAddressOrEmpty, isPending: isPendingWalletName } = useQuery({
    queryKey: ['DisplayNameForWallet', authAddress, chainId],
    queryFn: async () => {
      if (!authAddress || !chainId) return authAddressOrEmpty;
      return getDisplayNameForWallet(wagmiConfig, authAddress, chainId);
    },
    enabled: !!authAddress && !!chainId && !isSocial,
  });
  const { data: userIdentity } = GetUserIdentity.useQuery([], { enabled: !!authAddress });

  if (userIdentity) {
    let accountName = userIdentity.email;
    if (userIdentity.firstName && userIdentity.lastName) {
      accountName = `${userIdentity.firstName} ${userIdentity.lastName}`;
    }

    if (accountName) {
      return {
        accountName,
        shortAccountName: formatShortAccountName(accountName),
      };
    }
  }

  // Social path, since getting the account name is synchronous in this case we can return it immediately
  if (isSocial) {
    const accountName = getDisplayNameForSocial(user, authAddressOrEmpty);
    return {
      accountName,
      shortAccountName: formatShortAccountName(accountName),
    };
  }

  // Wallet path, since resolving the ENS name is asynchronous we need to wait for the query to finish
  // we will return the wallet address in the meantime
  const accountName = isPendingWalletName ? authAddressOrEmpty : walletName;
  return {
    accountName,
    shortAccountName: formatShortAccountName(accountName),
  };
};
