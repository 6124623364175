import type { FundListing } from '@endaoment-frontend/types';
import { roundDown } from '@endaoment-frontend/utils';

type MinimalFundForGrantableBalance = Pick<
  FundListing,
  'inTransitBuyUsdcAmount' | 'inTransitSellUsdcAmount' | 'investedUsdc' | 'processingTransfersTotalUsdc' | 'usdcBalance'
>;
export const calculateGrantableBalance = (fund: MinimalFundForGrantableBalance): bigint => {
  const positiveBalance =
    fund.usdcBalance + fund.investedUsdc + fund.inTransitBuyUsdcAmount + fund.inTransitSellUsdcAmount;
  const negativeBalance = fund.processingTransfersTotalUsdc;

  const grantableBalance = positiveBalance - negativeBalance;
  // Cannot have a negative grantable balance
  if (grantableBalance < 0) return 0n;
  return grantableBalance;
};

type MinimalFundForImpactCalculation = Pick<
  FundListing,
  'inTransitBuyUsdcAmount' | 'inTransitSellUsdcAmount' | 'investedUsdc' | 'totalGrantedUsdc' | 'usdcBalance'
>;
export const calculateFundImpact = (fund: MinimalFundForImpactCalculation): bigint => {
  return (
    fund.totalGrantedUsdc +
    fund.usdcBalance +
    fund.investedUsdc +
    fund.inTransitBuyUsdcAmount +
    fund.inTransitSellUsdcAmount
  );
};

/**
 * @returns A number between 0 and 100 representing the percentage of the value in relation to the fund's impact
 */
const convertToPercentOfFundImpact = (value: bigint | number, fund: MinimalFundForImpactCalculation): number => {
  const fundImpactFloored = Math.max(Number(calculateFundImpact(fund)), 1);
  const cleanedValue = Number(value);
  return Number((roundDown(cleanedValue / fundImpactFloored, 4) * 100).toFixed(2));
};

/**
 * @returns A number between 0 and 100 representing the percentage of the grantable balance in relation to the fund's impact
 */
export const calculateGrantablePercent = (
  fund: MinimalFundForGrantableBalance & MinimalFundForImpactCalculation,
): number => convertToPercentOfFundImpact(calculateGrantableBalance(fund), fund);

/**
 * @returns A number between 0 and 100 representing the percentage of the invested balance in relation to the fund's impact
 */
export const calculateInvestedPercent = (fund: MinimalFundForImpactCalculation): number =>
  convertToPercentOfFundImpact(fund.investedUsdc, fund);

/**
 * @returns A number between 0 and 100 representing the percentage of the completed grants in relation to the fund's impact
 */
export const calculateCompletedGrantPercent = (fund: MinimalFundForImpactCalculation): number =>
  convertToPercentOfFundImpact(fund.totalGrantedUsdc, fund);

/**
 * @returns A number between 0 and 100 representing the percentage of the in transit balance in relation to the fund's impact
 */
export const calculateInTransitPercent = (fund: MinimalFundForImpactCalculation): number =>
  convertToPercentOfFundImpact(fund.inTransitBuyUsdcAmount + fund.inTransitSellUsdcAmount, fund);

/**
 * Combines the input funds values into a single object with the aggregated values
 * and performs calculations to determine the percentage of each value in relation to the fund's impact
 */
export const aggregateFundTotals = (
  f:
    | Array<MinimalFundForGrantableBalance & MinimalFundForImpactCalculation>
    | (MinimalFundForGrantableBalance & MinimalFundForImpactCalculation),
): MinimalFundForGrantableBalance &
  MinimalFundForImpactCalculation & {
    impact: bigint;
    grantableBalance: bigint;
    grantablePercent: number;
    investedPercent: number;
    inTransitPercent: number;
    completedGrantPercent: number;
  } => {
  const funds = Array.isArray(f) ? f : [f];

  const aggregated = funds.reduce(
    (accum: MinimalFundForGrantableBalance & MinimalFundForImpactCalculation, fund) => ({
      usdcBalance: accum.usdcBalance + fund.usdcBalance,
      inTransitBuyUsdcAmount: accum.inTransitBuyUsdcAmount + fund.inTransitBuyUsdcAmount,
      inTransitSellUsdcAmount: accum.inTransitSellUsdcAmount + fund.inTransitSellUsdcAmount,
      processingTransfersTotalUsdc: accum.processingTransfersTotalUsdc + fund.processingTransfersTotalUsdc,
      investedUsdc: accum.investedUsdc + fund.investedUsdc,
      totalGrantedUsdc: accum.totalGrantedUsdc + fund.totalGrantedUsdc,
    }),
    {
      inTransitBuyUsdcAmount: 0n,
      inTransitSellUsdcAmount: 0n,
      investedUsdc: 0n,
      totalGrantedUsdc: 0n,
      usdcBalance: 0n,
      processingTransfersTotalUsdc: 0n,
    },
  );

  const aggregatedImpact = calculateFundImpact(aggregated);

  return {
    ...aggregated,
    impact: aggregatedImpact,
    grantableBalance: calculateGrantableBalance(aggregated),
    grantablePercent: calculateGrantablePercent(aggregated),
    investedPercent: calculateInvestedPercent(aggregated),
    inTransitPercent: calculateInTransitPercent(aggregated),
    completedGrantPercent: calculateCompletedGrantPercent(aggregated),
  };
};
