import clsx from 'clsx';
import { AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';

import { useIsMobile, useIsMounted, useScroll } from '@endaoment-frontend/hooks';
import { routes } from '@endaoment-frontend/routes';
import { SimpleSearch } from '@endaoment-frontend/search';
import { HamburgerIcon, LogoIcon } from '@endaoment-frontend/ui/icons';
import { Button } from '@endaoment-frontend/ui/shared';

import { ClientNavItems } from './ClientNavItems';
import { MobileMenu } from './MobileMenu';
import styles from './NavBar.module.scss';
import type { NavBarProps } from './NavBar.types';
import { UserProfileModal } from './UserProfileModal';

const NAVBAR_HEIGHT = 96;

export const NavBar = ({ className }: NavBarProps) => {
  // Get which href is currently active
  const { asPath } = useRouter();

  const isMounted = useIsMounted();

  const [animated, setAnimated] = useState(false);
  const headerRef = useRef(null);

  const { isMobile } = useIsMobile();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);

  const { y } = useScroll(32);
  const navbarIsScrolled = y >= NAVBAR_HEIGHT;

  // Check if the current path is the explore page
  const isExplorePage = asPath.includes(routes.app.explore());

  return (
    <>
      <header className={clsx(styles.navbar, navbarIsScrolled && styles.scrolled, className)} ref={headerRef}>
        <div className={styles['navbar-inner']}>
          <Link
            href={routes.app.dashboard()}
            title='Endaoment'
            className={animated ? styles['mouse-enter'] : ''}
            id='logo'
            onMouseEnter={() => setAnimated(true)}
            onAnimationEnd={() => setAnimated(false)}>
            <LogoIcon width={44} />
          </Link>

          <div className={styles['search']}>
            {/* Set key to current path to reset component on route change */}
            {!isExplorePage && <SimpleSearch key={asPath} className={styles['search-input']} compressed />}
          </div>

          {!!isMounted && (
            <nav className={styles['nav-menu']}>
              <ClientNavItems promptContainerRef={headerRef} onOpenUserProfile={() => setIsUserProfileOpen(true)} />
              {!!isMobile && (
                <Button
                  float={false}
                  onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                  className={styles['mobile-toggle']}>
                  <HamburgerIcon />
                </Button>
              )}
            </nav>
          )}
        </div>
      </header>
      <AnimatePresence>
        {!!isMobileMenuOpen && (
          <MobileMenu onClose={() => setMobileMenuOpen(false)} onOpenUserProfile={() => setIsUserProfileOpen(true)} />
        )}
      </AnimatePresence>

      <UserProfileModal isOpen={isUserProfileOpen} onClose={() => setIsUserProfileOpen(false)} />
    </>
  );
};
