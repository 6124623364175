import type { Chain, Transport } from 'viem';
import { fallback, http } from 'wagmi';

import { RPC_URLS } from '@endaoment-frontend/constants';

export const createHttpTransport = (providerUrl: string | undefined): Transport => {
  return http(providerUrl, {
    batch: true,
  });
};

export const createTransportsForChains = (chains: readonly [Chain, ...Array<Chain>]): Record<number, Transport> => {
  return chains.reduce((acc: Record<number, Transport>, chain) => {
    const chainProviders = RPC_URLS[chain.id];
    if (!chainProviders) throw new Error(`No RPC URLs were set up for chain ${chain.id}`);

    // `undefined` is used to add the default/public transport as well, albeit in the last priority position
    const transportsByPriority = [...chainProviders, undefined].map(createHttpTransport);

    return {
      ...acc,
      [chain.id]: fallback(transportsByPriority, {
        rank: false, // Turning off given viem was prioritizing public RPCs that took too long to converge on state - expected behavior is to use whatever order we defined in the rpc url array
        retryCount: 3,
      }),
    };
  }, {});
};
