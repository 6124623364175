import { match } from 'ts-pattern';
import { formatUnits } from 'viem';

import { GetSubproject } from '@endaoment-frontend/api';
import { STABLECOIN_DECIMALS } from '@endaoment-frontend/constants';
import { getStablecoinForChain } from '@endaoment-frontend/multichain';
import type { DonationRecipient, EVMToken, EntityLabel, SwapInfo } from '@endaoment-frontend/types';
import { convertUsdcToNumber, equalAddress, formatNumber } from '@endaoment-frontend/utils';

import type { TokenAmountInput } from './DonationWizard.types';

export const specialFormatTokenNumber = (num: bigint, token: EVMToken) =>
  equalAddress(token.contractAddress, getStablecoinForChain(token.chainId).contractAddress)
    ? formatNumber(formatUnits(num, token.decimals), {
        digits: undefined,
        compact: false,
        fractionDigits: 2,
      })
    : formatUnits(num, token.decimals);

export const estimateProceeds = (
  token: EVMToken,
  quote?: Pick<SwapInfo['quote'], 'endaomentFee' | 'expectedUsdc' | 'minimumTolerableUsdc'>,
): {
  estimatedUniswapFee: bigint;
  estimatedProceeds: bigint;
  estimatedMinProceeds: bigint;
} => {
  if (!quote) {
    return {
      estimatedUniswapFee: 0n,
      estimatedProceeds: 0n,
      estimatedMinProceeds: 0n,
    } as const;
  }

  // TODO: fee percentage must be changed depending on actual uni fee
  const estimatedUniswapFee = !equalAddress(token.contractAddress, getStablecoinForChain(token.chainId).contractAddress)
    ? (quote.expectedUsdc * 3n) / 1_000n
    : 0n;
  const estimatedProceeds = quote.expectedUsdc - quote.endaomentFee - estimatedUniswapFee;
  const estimatedMinProceeds = quote.minimumTolerableUsdc - quote.endaomentFee - estimatedUniswapFee;

  return {
    estimatedUniswapFee,
    estimatedProceeds,
    estimatedMinProceeds,
  } as const;
};

export const estimateTokenConversionRate = (tokenInput: Pick<TokenAmountInput, 'swapInfo' | 'token'>) =>
  formatNumber(
    tokenInput.swapInfo
      ? Number(formatUnits(tokenInput.swapInfo.amountIn, tokenInput.token.decimals)) /
          convertUsdcToNumber(tokenInput.swapInfo?.quote.expectedUsdc)
      : 0,
    { digits: 2 },
  );
export const complexRoundUp = (val: bigint, options?: { forceRoundUp?: boolean }) => {
  const STABLECOIN_MULTIPLIER = Math.pow(10, STABLECOIN_DECIMALS) / 100;
  let modifiedVal = Number(val) / STABLECOIN_MULTIPLIER;

  if (modifiedVal % 1 === 0 && options?.forceRoundUp) modifiedVal += 0.01;
  const rounded = Math.ceil(modifiedVal);
  return BigInt(rounded * STABLECOIN_MULTIPLIER);
};

export const convertEntityLabelToDonationRecipient = async (entityLabel: EntityLabel): Promise<DonationRecipient> => {
  return match(entityLabel)
    .with({ type: 'fund' }, entityLabel => ({ type: 'fund', id: entityLabel.id }) as const)
    .with({ type: 'org' }, entityLabel => ({ type: 'org', einOrId: entityLabel.id }) as const)
    .with({ type: 'subproject' }, async entityLabel => {
      const subproject = await GetSubproject.fetchFromDefaultClient([entityLabel.id]);
      return {
        type: 'org',
        einOrId: subproject.sponsorOrgEin ?? subproject.sponsorOrgId,
        subprojectId: entityLabel.id,
      } as const;
    })
    .exhaustive();
};
