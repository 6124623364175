import { z } from 'zod';

import type { UUID } from '@endaoment-frontend/types';
import { bigIntSchema, evmTokenSchema, swapInfoSchema, userIdentityInfoSchema } from '@endaoment-frontend/types';

import type { useDonationWizardState } from './useDonationWizardState';

export const tokenAmountInput = z.object({
  tokenAmount: bigIntSchema,
  token: evmTokenSchema,
  swapInfo: swapInfoSchema.optional(),
});
export type TokenAmountInput = z.infer<typeof tokenAmountInput>;

export const donationTaxInfoSchema = userIdentityInfoSchema.extend({
  updateProfile: z.boolean(),
  shareMyEmail: z.boolean(),
});
export type DonationTaxInfoFormData = z.infer<typeof donationTaxInfoSchema>;

export type SubformProps = {
  onClose: (donationId?: UUID) => void;
  onReset: () => void;
  rawState: ReturnType<typeof useDonationWizardState>['state'];
  rawStateSetters: ReturnType<typeof useDonationWizardState>['setters'];
};
