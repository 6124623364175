import clsx from 'clsx';
import { P, match } from 'ts-pattern';

import { AssembleTrade } from '@endaoment-frontend/api';
import { useAuthType } from '@endaoment-frontend/authentication';
import type { AssembledTradeResponse, TransactionStatus, UUID } from '@endaoment-frontend/types';
import { Button, Loader } from '@endaoment-frontend/ui/shared';

import wizardStyles from '../PortfolioWizard.module.scss';

import { ViewAllocation } from './ViewStep';

export const ConfirmStep = ({
  fundId,
  portfolioId,
  amount,
  isDeposit,
  confirmStatus,
  onConfirm,
  onDeselectPortfolio,
  onDeselectFund,
}: {
  fundId: UUID;
  portfolioId: UUID;
  amount: bigint;
  isDeposit: boolean;
  confirmStatus: TransactionStatus;
  onConfirm: (assembledTrade: AssembledTradeResponse) => void;
  onDeselectPortfolio: () => void;
  onDeselectFund: () => void;
}) => {
  const { isSocialAuth } = useAuthType();

  const tradeType = isDeposit ? 'Buy' : 'Sell';
  const { data: assembledTrade } = AssembleTrade.useQuery([
    {
      issuerEntityId: fundId,
      issuerEntityType: 'fund',
      portfolioId: portfolioId,
      amountUsdc: amount,
      tradeType,
    },
  ]);

  const handleConfirm = () => {
    if (!assembledTrade) return;
    onConfirm(assembledTrade);
  };

  return (
    <>
      <ViewAllocation
        outcome='Pending'
        allocationDetails={{
          entityId: assembledTrade?.entityId ?? fundId,
          portfolioId: assembledTrade?.portfolioId ?? portfolioId,
          tradeType: assembledTrade?.tradeData.tradeType ?? (isDeposit ? 'Buy' : 'Sell'),
          amount,
          previousPosition: assembledTrade?.estimations.previousPositionUsdc,
          newGrantableBalance: assembledTrade?.estimations.estimatedNewGrantableBalanceUsdc,
          minNewGrantableBalance: assembledTrade?.estimations.minimumNewGrantableBalanceUsdc ?? undefined,
          minNewEstimatedPosition: assembledTrade?.estimations.minimumNewEstimatedPositionUsdc ?? undefined,
          newPosition: assembledTrade?.estimations.newEstimatedPositionUsdc,
          endaomentFee: assembledTrade?.estimations.estimatedFee,
          priceImpact: assembledTrade?.tradeData.priceImpact ?? undefined,
        }}
        onDeselectFund={onDeselectFund}
        onDeselectPortfolio={onDeselectPortfolio}
      />
      {match(confirmStatus)
        .with(P.union('none', 'error', 'rejected'), () => (
          <Button
            filled
            variation='portfolio'
            onClick={handleConfirm}
            type='submit'
            className={clsx(wizardStyles['proceed'], wizardStyles['proceed--final'])}>
            Confirm Position
          </Button>
        ))
        .with('waiting', () => (
          <Button
            float={false}
            className={clsx(wizardStyles['wait-button'], wizardStyles['proceed'], wizardStyles['proceed--final'])}>
            <Loader size='s' />
            {isSocialAuth ? 'Processing request...' : 'Please sign transaction in wallet...'}
          </Button>
        ))
        .otherwise(() => (
          <Loader />
        ))}
    </>
  );
};
