import { type As } from '@chakra-ui/react';
import type { MutationStatus } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { match } from 'ts-pattern';

import { CollaborateIcon } from '@endaoment-frontend/ui/icons';
import type { ButtonProps } from '@endaoment-frontend/ui/shared';
import { Button, Tooltip } from '@endaoment-frontend/ui/shared';
import { useShowFireworks } from '@endaoment-frontend/ui/smart';

import styles from '../DonationWizard.module.scss';

type RecommendButtonProps<Tag extends As> = Omit<ButtonProps<Tag>, 'children' | 'onClick'> & {
  onRecommend: () => void;
  recommendStatus: MutationStatus;
};

export const RecommendButton = <Tag extends As>({
  onRecommend,
  recommendStatus,
  ...props
}: RecommendButtonProps<Tag>) => {
  const [clickable, setClickable] = useState(true);
  const handleRecommend = () => {
    // Prevent sending multiple requests
    if (!clickable || recommendStatus === 'pending' || recommendStatus === 'success') return;
    setClickable(false);
    onRecommend();
  };

  const showFireworks = useShowFireworks();
  useEffect(() => {
    // Reset the button to clickable if there was an error
    if (recommendStatus === 'error') setClickable(true);

    if (recommendStatus !== 'success') return;
    showFireworks();
  }, [recommendStatus, showFireworks]);

  const text = match(recommendStatus)
    .with('idle', () => 'Recommend')
    .with('pending', () => 'Recommending...')
    .with('success', () => 'Recommended!')
    .with('error', () => 'Recommending Failed! Try Again')
    .exhaustive();

  return (
    <Tooltip content='This will make a recommendation to the fund advisor which they can then approve!'>
      <Button onClick={handleRecommend} {...props} className={styles['recommend-button']}>
        {text}
        <CollaborateIcon width={28} />
      </Button>
    </Tooltip>
  );
};
