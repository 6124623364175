import { useRouter } from 'next/router';

import { useAccountDisplayName, useAuth, useAuthType, useSignOut } from '@endaoment-frontend/authentication';
import { UserAvatar } from '@endaoment-frontend/multichain';
import { routes } from '@endaoment-frontend/routes';
import { InfoIcon } from '@endaoment-frontend/ui/icons';
import { Button, Modal, Tooltip } from '@endaoment-frontend/ui/shared';
import { formatShortAddress } from '@endaoment-frontend/utils';

import styles from './UserProfileModal.module.scss';

export const UserProfileModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const router = useRouter();
  const { isWalletAuth } = useAuthType();
  const { authAddress, isSignedIn } = useAuth();
  const { accountName, shortAccountName } = useAccountDisplayName();
  const signOut = useSignOut();

  if (!isSignedIn || !authAddress) return <></>;

  const handleSignOut = async () => {
    await signOut();
    onClose();
  };

  const handleRouteToDashboard = () => {
    void router.push(routes.app.dashboard());
    onClose();
  };

  const shortAddress = formatShortAddress(authAddress);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='2xl'
      isCentered
      className={styles.modal}
      contentClassName={styles['modal-content']}
      showCloseButton>
      <div className={styles.header}>
        <UserAvatar address={authAddress} />
        <h3 className={styles.email}>{shortAccountName}</h3>
        {isWalletAuth ? (
          accountName !== authAddress && <span className={styles['address']}>{shortAddress}</span>
        ) : (
          <Tooltip
            content='This is an onchain identifier created for your connected social account.'
            className={styles['address']}>
            {formatShortAddress(authAddress)} <InfoIcon width={16} color='currentColor' />
          </Tooltip>
        )}
      </div>
      <hr />
      <div className={styles.buttons}>
        <Button onClick={handleRouteToDashboard} size='small' float={false} filled variation='purple'>
          Go to Dashboard
        </Button>
        <Button onClick={handleSignOut} size='small' float={false}>
          {isWalletAuth ? 'Disconnect' : 'Sign out'}
        </Button>
      </div>
    </Modal>
  );
};
