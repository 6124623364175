import { trackEvent } from '@phntms/next-gtm';
import { skipToken, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import type { ReactNode, Ref } from 'react';
import { forwardRef, useReducer, useState } from 'react';
import { useAsync } from 'react-use';
import { P, match } from 'ts-pattern';
import { useChainId, useConfig, useSwitchChain } from 'wagmi';

import {
  CreateErcRecommendation,
  GetFund,
  GetRecommendationsForFund,
  GetRecommendationsMadeByMe,
  GetSwapInfo,
  GetToken,
} from '@endaoment-frontend/api';
import { useCreateDonation } from '@endaoment-frontend/blockchain-interactions';
import { useIdempotencyKey } from '@endaoment-frontend/hooks';
import { NetworkSwitcher, ensureUserChain, getChainNameForChainId } from '@endaoment-frontend/multichain';
import { CalculateEntityRebalance, useRebalanceFund } from '@endaoment-frontend/target-allocations';
import type { CreateDonationInput } from '@endaoment-frontend/types';
import { Button, StepModal } from '@endaoment-frontend/ui/shared';
import { EntityCardWithLabel, useShowFireworks } from '@endaoment-frontend/ui/smart';
import { convertUsdcToNumber, isViewableBlockchainStatus } from '@endaoment-frontend/utils';

import { useInitializeTaxReceipt } from '../common/TaxReceiptButton';
import styles from '../DonationWizard.module.scss';
import type { DonationTaxInfoFormData, SubformProps } from '../DonationWizard.types';
import { estimateProceeds } from '../helpers';
import { useIsFundCollaborator } from '../useIsFundCollaborator';

import { DonationConfirm } from './DonationConfirm';
import { DonationTaxInfo } from './DonationTaxInfo';
import { DonationTokenInput } from './DonationTokenInput';
import { ViewDonation } from './ViewDonation';

const donationSteps = ['amount', 'tax-receipt', 'confirmation', 'view'] as const;
const ercDonationPages = donationSteps.length + 1;
type DonationStep = (typeof donationSteps)[number];

const useErcDonationFlowState = ({ rawState, rawStateSetters }: Pick<SubformProps, 'rawState' | 'rawStateSetters'>) => {
  const chainId = useChainId();
  const { recipient, token, amount: tokenAmount, includeTaxReceipt, isRebalanceRequested, recommendationId } = rawState;

  const [step, setStep] = useReducer((_prev: DonationStep, next: DonationStep) => {
    trackEvent({ event: 'dw_wizard_progress', data: { dw_wizard_step: next, dw_wizard_mode: rawState.mode } });
    return next;
  }, 'amount');
  const [taxInfo, setTaxInfo] = useState<DonationTaxInfoFormData>();

  // We need chain info in case the recipient is a fund
  const { data: recipientFund } = GetFund.useQuery(
    recipient && recipient.type === 'fund' ? [recipient.id] : skipToken,
    { enabled: recipient && recipient.type === 'fund' },
  );
  const recipientChainId = recipientFund?.chainId ?? chainId;

  const wizard = {
    step,
    recipient,
    recipientChainId,
    includeTaxReceipt,
    taxInfo,
    tokenAmount,
    token,
    isRebalanceRequested,
    recommendationId,
  } as const;

  return {
    wizard,
    setStep,
    setRecipient: rawStateSetters.setRecipient,
    setIncludeTaxReceipt: rawStateSetters.setIncludeTaxReceipt,
    setTaxInfo,
    setTokenAmount: rawStateSetters.setAmount,
    setToken: rawStateSetters.setErcToken,
    setIsRebalanceRequested: rawStateSetters.setIsRebalanceRequested,
  } as const;
};

const useCreateErcRecommendation = ({ onClose }: { onClose: () => void }) => {
  const queryClient = useQueryClient();
  return CreateErcRecommendation.useMutation({
    onSuccess: async (_data, [input]) => {
      GetRecommendationsForFund.invalidateQuery(queryClient, [input.collaboratingFundId]);
      GetRecommendationsMadeByMe.invalidateQuery(queryClient, []);

      const token = await GetToken.fetchFromDefaultClient([input.tokenId]);
      trackEvent({
        event: 'dw_wallet_recommendation',
        data: {
          token: token.symbol,
          amount: input.inputAmount.toString(),
          chainId: input.chainId,
          destination_type: 'fund',
          destination_id: input.collaboratingFundId,
        },
      });

      setTimeout(() => onClose(), 2 * 1000);
    },
  });
};

const ErcDonationFlowWithRef = (
  { onClose, onReset, rawState, rawStateSetters }: SubformProps,
  ref?: Ref<HTMLDivElement>,
) => {
  const queryClient = useQueryClient();
  const wagmiConfig = useConfig();
  const {
    execute: createDonation,
    status: transactionStatus,
    transactionHash,
    transactionChainId,
  } = useCreateDonation();
  const { execute: rebalanceFund, status: rebalanceTransactionStatus } = useRebalanceFund();
  const idempotencyKey = useIdempotencyKey();
  const { mutate: createRecommendation, status: createRecommendationStatus } = useCreateErcRecommendation({ onClose });

  const chainId = useChainId();
  const flowState = useErcDonationFlowState({
    rawState,
    rawStateSetters,
  });
  const isFundCollaborator = useIsFundCollaborator(
    flowState.wizard.recipient?.type === 'fund' ? flowState.wizard.recipient.id : undefined,
  );
  const showFireworks = useShowFireworks();
  useAsync(async () => {
    if (!flowState.wizard.recipient) return;
    if (flowState.wizard.recipient.type === 'fund' && flowState.wizard.isRebalanceRequested) {
      if (isViewableBlockchainStatus(rebalanceTransactionStatus)) {
        flowState.setStep('view');
        if (rebalanceTransactionStatus === 'success') {
          showFireworks();
        }
        return;
      }

      // When a donation completes, check if a rebalance is necessary
      if (transactionStatus === 'success') {
        const ops = await CalculateEntityRebalance.fetchFromDefaultClient([
          'fund',
          flowState.wizard.recipient.id,
          false,
        ]);
        if (ops.length > 0) return;

        // If no rebalance operations are necessary, move to the view step
        flowState.setIsRebalanceRequested(false);
        flowState.setStep('view');
        return;
      }
      return;
    }

    if (isViewableBlockchainStatus(transactionStatus)) {
      flowState.setStep('view');
      if (transactionStatus === 'success') {
        showFireworks();
      }
    }
  }, [transactionStatus, rebalanceTransactionStatus, flowState.wizard.isRebalanceRequested]);

  useInitializeTaxReceipt(
    userIdentity => {
      if (!userIdentity) {
        flowState.setIncludeTaxReceipt(false);
        flowState.setTaxInfo(undefined);
        return;
      }
      flowState.setIncludeTaxReceipt(true);
      flowState.setTaxInfo({
        ...userIdentity,
        shareMyEmail: true,
        updateProfile: false,
      });
    },
    { isCollaborator: !!isFundCollaborator },
  );

  const steps = match(flowState.wizard)
    .with({ step: 'amount', recipient: P.nonNullable }, ({ recipient, recipientChainId, tokenAmount, token }) => {
      return (
        <StepModal.Step
          key='amount'
          ref={ref}
          onClose={onClose}
          onBack={onReset}
          header='New Donation'
          progress={{ current: 3, pages: ercDonationPages }}>
          <EntityCardWithLabel label='Donating to' entity={recipient} onRemove={onReset} />
          <hr />
          <h2 className={clsx(styles['step-header'], styles['step-header__flex'])}>
            What will you be donating?{' '}
            {recipient.type === 'org' && (
              <span className={styles['network-label']}>
                Network: <NetworkSwitcher hideStatus />
              </span>
            )}
          </h2>
          {match({ isOnRecipientChain: chainId === recipientChainId, isFundCollaborator })
            .with({ isOnRecipientChain: false, isFundCollaborator: false }, () => (
              <DonationInvalidChainMessage chainId={recipientChainId}>
                This fund is deployed on {getChainNameForChainId(recipientChainId)}.
                <br />
                Please switch network before continuing your donation.
              </DonationInvalidChainMessage>
            ))
            .otherwise(() => (
              <DonationTokenInput
                recipient={recipient}
                chainId={recipientChainId}
                token={token}
                tokenAmount={tokenAmount}
                onTokenChange={flowState.setToken}
                onTokenAmountChange={flowState.setTokenAmount}
                onSubmit={() => {
                  flowState.setStep('confirmation');
                }}
                onRecommend={() => {
                  if (recipient.type !== 'fund' || !token) return;

                  createRecommendation([
                    {
                      collaboratingFundId: recipient.id,
                      chainId: recipientChainId,
                      inputAmount: tokenAmount,
                      tokenId: token.id,
                      uuid: idempotencyKey,
                      // TODO: Confirm extraneous fields
                      isRebalanceRequested: true,
                      offsetFee: false,
                      shareMyEmail: true,
                      taxReceipt: true,
                      // Highly unnecessary, but required for the body to be valid
                      donorIdentity: {
                        firstName: 'DUMMY',
                        lastName: 'DUMMY',
                        address: {
                          line1: 'DUMMY',
                          city: 'DUMMY',
                          country: 'USA',
                          state: 'DE',
                          zip: '012345',
                        },
                        email: 'DUMMY@DUMMY.com',
                      },
                    },
                  ]);
                }}
                recommendStatus={createRecommendationStatus}
              />
            ))}
        </StepModal.Step>
      );
    })
    .with({ step: 'tax-receipt', recipient: P.nonNullable }, ({ recipient, taxInfo }) => (
      <StepModal.Step key='tax-receipt' ref={ref} onClose={onClose} header='New Donation / Tax Receipt'>
        <DonationTaxInfo
          initialValues={taxInfo}
          donationDestinationType={recipient.type}
          onSkip={() => {
            flowState.setIncludeTaxReceipt(false);
            flowState.setStep('confirmation');
          }}
          onSubmit={t => {
            flowState.setIncludeTaxReceipt(true);
            flowState.setTaxInfo(t);
            flowState.setStep('confirmation');
          }}
        />
      </StepModal.Step>
    ))
    .with(
      {
        step: 'confirmation',
        token: P.nonNullable,
        tokenAmount: P.nonNullable,
        recipient: P.nonNullable,
      },
      ({
        includeTaxReceipt,
        taxInfo,
        token,
        tokenAmount,
        recipient,
        isRebalanceRequested,
        recipientChainId,
        recommendationId,
      }) => (
        <StepModal.Step
          key='confirmation'
          ref={ref}
          onClose={onClose}
          header='New Donation'
          onBack={() => flowState.setStep('amount')}
          progress={{ current: 5, pages: ercDonationPages }}>
          <EntityCardWithLabel label='From' entity={{ type: 'user' }} />
          <EntityCardWithLabel
            label='Donating to'
            entity={recipient}
            onRemove={transactionStatus !== 'waiting' ? () => onReset : undefined}
          />
          {match({ isOnTokenChain: chainId === token.chainId, isFundCollaborator })
            .with({ isOnTokenChain: false, isFundCollaborator: false }, () => (
              <DonationInvalidChainMessage chainId={token.chainId}>
                Please swap back to {getChainNameForChainId(token.chainId)}
              </DonationInvalidChainMessage>
            ))
            .otherwise(() => {
              const handleInitiateTransaction = async () => {
                const subprojectId = recipient.type === 'org' ? recipient.subprojectId : undefined;

                let donationData: CreateDonationInput;
                if (includeTaxReceipt) {
                  // Tax info must be provided if the user wants a tax receipt
                  if (!taxInfo) {
                    console.error('Tax info must be provided if the user wants a tax receipt');
                    return;
                  }

                  donationData = {
                    taxReceipt: true,
                    donorIdentity: taxInfo,
                    subprojectId,
                    shareMyEmail: taxInfo.shareMyEmail,
                    isRebalanceRequested: isRebalanceRequested,
                    recommendationId: recommendationId,
                  };
                } else {
                  donationData = {
                    taxReceipt: false,
                    subprojectId,
                    shareMyEmail: false,
                    isRebalanceRequested: isRebalanceRequested,
                    recommendationId: recommendationId,
                  };
                }

                try {
                  if (recipient.type === 'fund') {
                    const receivingFund = await GetFund.fetchFromDefaultClient([recipient.id]);
                    await ensureUserChain(wagmiConfig, receivingFund.chainId);
                  }

                  await createDonation(
                    {
                      destination: recipient,
                      token: token,
                      tokenAmount: tokenAmount,
                      donationData,
                    },
                    { resolveOnPending: true },
                  );

                  const swapInfo = await GetSwapInfo.fetchFromDefaultClient([
                    tokenAmount,
                    token,
                    recipient,
                    recipientChainId,
                  ]);
                  // Clear dataLayer before sending ecommerce values
                  trackEvent({ data: { ecommerce: null } });
                  trackEvent({
                    event: 'dw_wallet_donation',
                    data: {
                      token: token.symbol,
                      amount: tokenAmount.toString(),
                      chainId: token.chainId,
                      destination_type: recipient.type,
                      destination_id: recipient.type === 'fund' ? recipient.id : recipient.einOrId,
                      ecommerce: {
                        currency: 'USD',
                        value: convertUsdcToNumber(
                          estimateProceeds(token, swapInfo.quote).estimatedProceeds.toString(),
                          true,
                        ),
                        transaction_id: transactionHash,
                      },
                    },
                  });
                } catch (e) {
                  console.error(e);
                }
              };
              const handleInitiateRebalance = async () => {
                if (recipient.type !== 'fund' || !isRebalanceRequested || !token) {
                  console.error('Attempting to rebalance without the necessary data');
                  return;
                }

                // Wait for the donation to be completed before rebalancing the fund
                if (transactionStatus !== 'success') return;

                GetFund.invalidateQuery(queryClient, [recipient.id]);
                const fund = await GetFund.fetchFromDefaultClient([recipient.id]);
                await ensureUserChain(wagmiConfig, fund.chainId);

                try {
                  await rebalanceFund({ fundId: fund.id }, { resolveOnPending: true });

                  trackEvent({
                    event: 'dw_rebalance',
                    data: {
                      chainId: token.chainId,
                      destination_type: recipient.type,
                      destination_id: recipient.id,
                    },
                  });
                } catch (e) {
                  if (e instanceof Error && e.message === 'No valid rebalance operations to execute') {
                    flowState.setIsRebalanceRequested(false);
                  }
                  console.error(e);
                }
              };
              return (
                <DonationConfirm
                  token={token}
                  tokenAmount={tokenAmount}
                  chainId={recipientChainId}
                  onStartTransaction={handleInitiateTransaction}
                  onStartRebalance={handleInitiateRebalance}
                  transactionStatus={transactionStatus}
                  rebalanceTransactionStatus={rebalanceTransactionStatus}
                  donorIdentity={
                    includeTaxReceipt ? { taxReceipt: true, donorEmail: taxInfo?.email ?? '' } : { taxReceipt: false }
                  }
                  isRebalanceRequested={isRebalanceRequested}
                  onChangeRebalanceRequested={flowState.setIsRebalanceRequested}
                  recipient={recipient}
                  onGoToTaxStep={() => flowState.setStep('tax-receipt')}
                  onNoTaxReceipt={() => {
                    flowState.setIncludeTaxReceipt(false);
                    flowState.setStep('confirmation');
                  }}
                />
              );
            })}
        </StepModal.Step>
      ),
    )
    .with(
      {
        step: 'view',
        token: P.nonNullable,
        tokenAmount: P.nonNullable,
        recipient: P.nonNullable,
      },
      ({ token, tokenAmount, recipient }) => (
        <StepModal.Step key='view' ref={ref} onClose={onClose} header='Donation'>
          <ViewDonation
            token={token}
            tokenAmount={tokenAmount}
            donationStatus={transactionStatus}
            transactionHash={transactionHash}
            transactionChainId={transactionChainId}
            destination={recipient}
          />
        </StepModal.Step>
      ),
    )
    .run();
  return steps;
};
export const ErcDonationFlow = forwardRef(ErcDonationFlowWithRef);

const DonationInvalidChainMessage = ({ chainId, children }: { chainId: number; children: ReactNode }) => {
  const { switchChain } = useSwitchChain();
  return (
    <div style={{ textAlign: 'center' }}>
      <p style={{ margin: 20 }}>{children}</p>
      <Button onClick={() => switchChain({ chainId })}>Switch Network</Button>
    </div>
  );
};
