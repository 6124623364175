import clsx from 'clsx';
import type { ReactNode } from 'react';

import styles from './ActivityDescription.module.scss';

export const ActivityDescription = ({
  className,
  children,
}: {
  className?: string;
  children: Array<ReactNode> | ReactNode;
}) => {
  return <span className={clsx(styles['description'], className)}>{children}</span>;
};
