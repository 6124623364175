import type { FormikErrors } from 'formik';
import type { ZodSchema, ParseParams } from 'zod';

type RecursiveRecord = { [key: number | string]: RecursiveRecord | string };

/**
 * Allows you to easily use Zod schemas with the <Formik /> component `validate`
 * prop.
 *
 * Copied from https://github.com/glazy/formik-validator-zod
 *
 * ```js
 * <Formik {...} validate={withZodSchema(yourSchema)}>
 * ```
 */
export const validateWithZod =
  <T>(schema: ZodSchema<T>, params?: Partial<ParseParams>) =>
  (values: T): FormikErrors<T> => {
    const result = schema.safeParse(values, params);

    if (result.success) return {};

    const shapedErrors = result.error.issues.reduce((acc: RecursiveRecord, issue) => {
      if (typeof acc === 'string') throw new Error('Form error accumulator was badly set to a string');

      const newAcc = { ...acc };
      let pathNavigator = newAcc;
      for (let i = 0; i < issue.path.length; i++) {
        const currentPath = issue.path[i];
        if (!pathNavigator[currentPath]) {
          pathNavigator[currentPath] = {};
        }

        if (i + 1 === issue.path.length) {
          pathNavigator[currentPath] = issue.message;
        } else {
          const newNavigator = pathNavigator[currentPath];
          if (typeof newNavigator !== 'string') pathNavigator = newNavigator;
        }
      }

      return newAcc;
    }, {});
    return shapedErrors as FormikErrors<T>;
  };
